import { Reducer } from 'react'
import {
  DealActions,
  DealActionTypes,
  DealState }      from '../../../actions/kissan/deals/deal.actions'

const initialState: DealState = {
  dealsList: [],
  dealsListError: false,
  dealsListSuccess: false,
  dealDocumentsUploadError: false,
  dealDocumentsUploadSuccess: false,
  dropDealRequestError: false,
  dropDealRequestSuccess: false,

  // investorRelievingRequests: [],
  // investorRelievingRequestsError: false,
  // investorRelievingRequestsApproveSuccess: false,
  // investorRelievingRequestsApproveError: false,
  // farmerRelievingRequestsApproveSuccess: false,
  // farmerRelievingRequestsApproveError: false,
  // farmerRelievingRequests: [],
  // farmerRelievingRequestsError: false,
  // farmerRelievingRequestsSuccess: false,
  // dealDetail: null,
  // dealDetailFetchError: false,
  // dealDetailFetchSuccess: false,
  // dealStopActionAdminSuccess: false,
  // dealStopActionAdminError: false,
  // dealTimelineAddError: false,
  // dealTimelineAddSuccess: false
}

const dealReducer: Reducer<DealState, DealActions> = (state = initialState, action) => {
  switch (action.type) {
    case DealActionTypes.DEALS_LIST: {
      return {
        ...state,
        dealsList: action.payload
      }
    }
    case DealActionTypes.DEALS_LIST_ERROR: {
      return {
        ...state,
        dealsListError: action.payload
      }
    }
    case DealActionTypes.DEALS_LIST_SUCCESS: {
      return {
        ...state,
        dealsListSuccess: action.payload
      }
    }
    case DealActionTypes.DEAL_DOCUMENTS_UPLOAD_ERROR: {
      return {
        ...state,
        dealDocumentsUploadError: action.payload
      }
    }
    case DealActionTypes.DEAL_DOCUMENTS_UPLOAD_SUCCESS: {
      return {
        ...state,
        dealDocumentsUploadSuccess: action.payload
      }
    }
    case DealActionTypes.DROP_DEAL_REQUEST_ERROR: {
      return {
        ...state,
        dropDealRequestError: action.payload
      }
    }
    case DealActionTypes.DROP_DEAL_REQUEST_SUCCESS: {
      return {
        ...state,
        dropDealRequestSuccess: action.payload
      }
    }

    // case DealActionTypes.INVESTOR_RELIEVING_REQUESTS: {
    //   return {
    //     ...state,
    //     investorRelievingRequests: action.payload
    //   }
    // }
    // case DealActionTypes.INVESTOR_RELIEVING_REQUESTS_ERROR: {
    //   return {
    //     ...state,
    //     investorRelievingRequestsError: action.payload
    //   }
    // }
    // case DealActionTypes.INVESTOR_RELIEVING_REQUESTS_APPROVE_SUCCESS: {
    //   return {
    //     ...state,
    //     investorRelievingRequestsApproveSuccess: action.payload
    //   }
    // }
    // case DealActionTypes.INVESTOR_RELIEVING_REQUESTS_APPROVE_ERROR: {
    //   return {
    //     ...state,
    //     investorRelievingRequestsApproveError: action.payload
    //   }
    // }
    // case DealActionTypes.FARMER_RELIEVING_REQUESTS_APPROVE_SUCCESS: {
    //   return {
    //     ...state,
    //     farmerRelievingRequestsApproveSuccess: action.payload
    //   }
    // }
    // case DealActionTypes.FARMER_RELIEVING_REQUESTS_APPROVE_ERROR: {
    //   return {
    //     ...state,
    //     farmerRelievingRequestsApproveError: action.payload
    //   }
    // }
    // case DealActionTypes.FARMER_RELIEVING_REQUESTS: {
    //   return {
    //     ...state,
    //     farmerRelievingRequests: action.payload
    //   }
    // }
    // case DealActionTypes.FARMER_RELIEVING_REQUESTS_ERROR: {
    //   return {
    //     ...state,
    //     farmerRelievingRequestsError: action.payload
    //   }
    // }
    // case DealActionTypes.FARMER_RELIEVING_REQUESTS_SUCCESS: {
    //   return {
    //     ...state,
    //     farmerRelievingRequestsSuccess: action.payload
    //   }
    // }
    // case DealActionTypes.DETAILS_VIEW: {
    //   return {
    //     ...state,
    //     dealDetail: action.payload
    //   }
    // }
    // case DealActionTypes.DETAILS_VIEW_SUCCESS: {
    //   return {
    //     ...state,
    //     dealDetailFetchSuccess: action.payload
    //   }
    // }
    // case DealActionTypes.DETAILS_VIEW_ERROR: {
    //   return {
    //     ...state,
    //     dealDetailFetchError: action.payload
    //   }
    // }
    // case DealActionTypes.DEAL_STOP_ACTION_SUBMIT_SUCCESS: {
    //   return {
    //     ...state,
    //     dealStopActionAdminSuccess: action.payload
    //   }
    // }
    // case DealActionTypes.DEAL_STOP_ACTION_SUBMIT_ERROR: {
    //   return {
    //     ...state,
    //     dealStopActionAdminError: action.payload
    //   }
    // }
    // case DealActionTypes.TIMELINE_ADD_SUCCESS: {
    //   return {
    //     ...state,
    //     dealTimelineAddSuccess: action.payload
    //   }
    // } 
    // case DealActionTypes.TIMELINE_ADD_ERROR: {
    //   return {
    //     ...state,
    //     dealTimelineAddError: action.payload
    //   }
    // }
    default: {
      return state
    }
  }
}

export default dealReducer
