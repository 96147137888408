import { FC } from 'react'

type AdDetailsProps = {
  data: {
    location: string
    category: string
    subcategory: string
  }
}

const AdDetails: FC<AdDetailsProps> = ({ data }) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>AD DETAILS</h3>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Category</label>
              <div className='col-lg-8'>
                <span className='fw-bold fs-6 text-dark'>{data.category}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Subcategory</label>
              <div className='col-lg-8'>
                <span className='fw-bold fs-6 text-dark'>{data.subcategory}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Location</label>
              <div className='col-lg-8'>
                <span className='fw-bold fs-6 text-dark'>{data.location}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdDetails
