import { FC }                      from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate }             from 'react-router-dom'
import { RootState }               from '../../../../../../../store'
import { useAppDispatch }          from '../../../../../../../store/hooks'
import {
  AdminSettingsActions,
  setCategoryName }                from '../../../../../../../store/actions/admin/settings.action'

const SubcategoriesCell: FC<SubcategoriesCellProps> = ({
  id,
  categoryName,
  setCategoryName
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const showSubcategories = () => {
    dispatch(setCategoryName(categoryName))
    navigate('/admin-sub-category-list/' + id)
  }

  return (
    <button
      type='button'
      title='View'
      onClick={showSubcategories}
      className='btn btn-sm btn-light btn-active-light-primary'>
      <i className='bi bi-eye-fill pe-0'></i>
    </button>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    id: ownProps.id,
    categoryName: ownProps.category
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AdminSettingsActions>) => {
  return {
    setCategoryName
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type SubcategoriesCellProps = ConnectedProps<typeof connector>

export default connector(SubcategoriesCell)
