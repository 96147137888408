import axios           from 'axios'
import AppConfig       from '../../../../appConfig'
import { AppDispatch } from '../../../store'

// *************** TYPES

export interface NewPost {
  _id: string
  price: string
  crop_name: string
  user_name: string
  crop_yield: string
  is_aproved: string
  offer_mode: string
  farmer_name: string
  property_status: string
  relieving_status: string
  cultivation_period: string
  thumbnailmyproperty_image: string
}

export interface KissanPost {
  thumbnailmyproperty_image: string
  deal_status: string
  farmer_details: {
    name: string
    address: string
    methodology: string
    email: string
    phone: string
  }
  cultivation_details: {
    cultivation_period: string
    crop_name: string
    crop_yield: string
    land_area: string
    land_area_details: string
  }
  share_details: {
    investor_amount: string
    farmer_amount: string
    investor_yield: string
    farmer_yield: string
    investor_share: string
    farmer_share: string
    farmer_max_share: string
    farmer_min_share: string
    investor_max_share: string
    investor_min_share: string
  }
}

// *************** STATE

export interface KissanPostsState {
  newPostsList: NewPost[]
  newPostsFetchError: boolean
  newPostsFetchSuccess: boolean
  kissanPost: KissanPost | null
  kissanPostFetchError: boolean
  kissanPostFetchSuccess: boolean
}

// *************** ACTION TYPES

export const KissanPostsActionTypes = {
  FETCH_KISSAN_POSTS                  : 'FETCH_KISSAN_POSTS',
  FETCH_KISSAN_POSTS_ERROR            : 'FETCH_KISSAN_POSTS_ERROR',
  FETCH_KISSAN_POSTS_SUCCESS          : 'FETCH_KISSAN_POSTS_SUCCESS',
  FETCH_KISSAN_POST                   : 'FETCH_KISSAN_POST',
  FETCH_KISSAN_POST_ERROR             : 'FETCH_KISSAN_POST_ERROR',
  FETCH_KISSAN_POST_SUCCESS           : 'FETCH_KISSAN_POST_SUCCESS',

  // SAVE_NEW_POST                       : 'CREATE_CATEGORY',
  // KISSAN_POST_EDIT                    : 'KISSAN_POST_EDIT',
  // SET_ERROR                           : 'SET_ERROR_NEW',
  // SET_SAVE_STATUS                     : 'SET_SAVE_STATUS',
  // KISSAN_POST_STATUS_UPDATE           : 'KISSAN_POST_STATUS_UPDATE',
  // KISSAN_POST_UPDATE_RESPONSE_SUCCESS : 'KISSAN_POST_UPDATE_RESPONSE_SUCCESS',
  // KISSAN_POST_UPDATE_RESPONSE_FAIL    : 'KISSAN_POST_UPDATE_RESPONSE_FAIL',
  // UPDATE_NEW_POST                     : 'UPDATE_NEW_POST',
  // UPDATE_NEW_POST_FAIL                : 'UPDATE_NEW_POST_FAIL'
} as const

// *************** ACTIONS

interface FetchKissanPostsListAction {
  type: typeof KissanPostsActionTypes.FETCH_KISSAN_POSTS;
  payload: NewPost[];
}
interface FetchKissanPostsListSuccessAction {
  type: typeof KissanPostsActionTypes.FETCH_KISSAN_POSTS_SUCCESS;
  payload: boolean;
}
interface FetchKissanPostsListFailureAction {
  type: typeof KissanPostsActionTypes.FETCH_KISSAN_POSTS_ERROR;
  payload: boolean;
}

interface FetchKissanPostAction {
  type: typeof KissanPostsActionTypes.FETCH_KISSAN_POST;
  payload: KissanPost;
}
interface FetchKissanPostSuccessAction {
  type: typeof KissanPostsActionTypes.FETCH_KISSAN_POST_SUCCESS;
  payload: boolean;
}
interface FetchKissanPostFailureAction {
  type: typeof KissanPostsActionTypes.FETCH_KISSAN_POST_ERROR;
  payload: boolean;
}

export type KissanPostsActions =
  | FetchKissanPostsListAction
  | FetchKissanPostsListSuccessAction
  | FetchKissanPostsListFailureAction
  | FetchKissanPostAction
  | FetchKissanPostSuccessAction
  | FetchKissanPostFailureAction


// *************** API CALLS
/* FETCH KISSAN POSTS */

export function fetchKissanPostsList() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_POST_LISTS

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: KissanPostsActionTypes.FETCH_KISSAN_POSTS,
        payload: response.data.propertydetails
      })
      dispatch(fetchKissanPostsListSuccess(true))
    })
    .catch(error => {
      dispatch(fetchKissanPostsListError(true))
    })
  }
}
export function fetchKissanPostsListError(status: boolean) {
  return {
    type: KissanPostsActionTypes.FETCH_KISSAN_POSTS_ERROR,
    payload: status
  }
}
export function fetchKissanPostsListSuccess(status: boolean) {
  return {
    type: KissanPostsActionTypes.FETCH_KISSAN_POSTS_SUCCESS,
    payload: status
  }
}

/* FETCH KISSAN POST */

export function fetchKissanPost(id: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_POST_VIEW

    return axios.get(`${url}/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: KissanPostsActionTypes.FETCH_KISSAN_POST,
        payload: response.data.propertydetails
      })
      dispatch(fetchKissanPostSuccess(true))
    })
    .catch(error => {
      dispatch(fetchKissanPostError(true))
    })
  }
}
export function fetchKissanPostError(status: boolean) {
  return {
    type: KissanPostsActionTypes.FETCH_KISSAN_POST_ERROR,
    payload: status
  }
}
export function fetchKissanPostSuccess(status: boolean) {
  return {
    type: KissanPostsActionTypes.FETCH_KISSAN_POST_SUCCESS,
    payload: status
  }
}

/*
export function saveNewPost(formData) {
  return (dispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_NEW_POST

    return axios.post(url, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      },
      onUploadProgress: progressEvent => {
        console.log(progressEvent.loaded / progressEvent.total)
      }
    })
    .then((response) => {
      dispatch({
        type: SAVE_NEW_POST,
        payload: true
      })
    })
    .catch(error => {
      dispatch(setError(true))
    })
  }
}
export function setError(status: boolean) {
  return {
    type: SET_ERROR,
    payload: status
  }
}

export function setSaveStatus(status: boolean) {
  return {
    type: SET_SAVE_STATUS,
    payload: status
  }
}

export function editPost(id) {
  return (dispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_POST_EDIT

    return axios.get(`${url}/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: KISSAN_POST_EDIT,
        payload: response.data.propertydetails
      })
    })
    .catch(error => {
      alert('Wrong Credentials')
    })
  }
}

export function statusUpdate(formData, id) {
  return (dispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_POST_STATUS_UPDATE

    return axios.post(`${url}/${id}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(statusUpdateSuccess(true))
    })
    .catch(error => {
      dispatch(statusUpdateError(true))
    })
  }
}
export function statusUpdateError(status: boolean) {
  return {
    type: KISSAN_POST_UPDATE_RESPONSE_FAIL,
    payload: status
  }
}
export function statusUpdateSuccess(status: boolean) {
  return {
    type: KISSAN_POST_UPDATE_RESPONSE_SUCCESS,
    payload: status
  }
}

export function updateNewPost(formData, id) {
  return (dispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_NEW_POST_UPDATE

    return axios.post(`${url}/${id}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      },
      onUploadProgress: progressEvent => {
        console.log(progressEvent.loaded / progressEvent.total)
      }
    })
    .then((response) => {
      dispatch({
        type: UPDATE_NEW_POST,
        payload: true
      })
    })
    .catch(error => {
      dispatch(updateNewPostError(true))
    })
  }
}
export function updateNewPostError(status: boolean) {
  return {
    type: UPDATE_NEW_POST_FAIL,
    payload: status
  }
}
*/
