import { FC, useState, useEffect } from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { Link, useParams }         from 'react-router-dom'
import { PageLink, PageTitle }     from '../../../../../_metronic/layout/core'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  fetchAdDetails,
  fetchAdDetailsError,
  fetchAdDetailsSuccess,
  GoAdsActions }                   from '../../../../store/actions/go/ads.actions'
import AdDetails                   from './AdDetails'
import AdminAction                 from './AdminAction'
import PropertyDetails             from './PropertyDetails'
import SelectedDaysDetails         from './SelectedDaysDetails'
import ShareDetails                from './ShareDetails'

const breadcrumbs: Array<PageLink> = [{
  path: '/go-list-post',
  title: 'New Ads',
  isActive: false,
  isSeparator: false
}, {
  path: '',
  title: '',
  isActive: false,
  isSeparator: true
}]

const ViewGoAd: FC<ViewGoAdProps> = ({
  adDetails,
  adDetailsError,
  adDetailsSuccess,
  fetchAdDetails,
  fetchAdDetailsError,
  fetchAdDetailsSuccess
}) => {
  const params   = useParams()
  const dispatch = useAppDispatch()

  const [currentId, setCurrentId] = useState<string>('')

  useEffect(() => {
    if (params.id) {
      setCurrentId(params.id)
      dispatch(fetchAdDetails(params.id))
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Ad Details</PageTitle>

      {
        adDetails != null &&
        <>
          <AdDetails           data={adDetails.ad_details} />
          <PropertyDetails     data={adDetails.property_details} />
          <ShareDetails        data={adDetails.share_details} />
          <SelectedDaysDetails data={adDetails.user_selected_days} />
          <AdminAction         currentId={currentId} />
        </>
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    adDetails: state.goAdsReducer.adDetails,
    adDetailsError: state.goAdsReducer.adDetailsError,
    adDetailsSuccess: state.goAdsReducer.adDetailsSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GoAdsActions>) => {
  return {
    fetchAdDetails,
    fetchAdDetailsError,
    fetchAdDetailsSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ViewGoAdProps = ConnectedProps<typeof connector>

export default connector(ViewGoAd)
