import axios           from 'axios'
import AppConfig       from '../../../../appConfig'
import { AppDispatch } from '../../../store'

/*********************/
/* Types */

export interface Partner {
  name: string
  days: number
  amount: number
  user_id: string
  percentage: number
  property_id: string
  interest_id: string
}

interface PartnershipCycleDetails {
  _id: string
  type: string
  status: string
  starting_date: string
  total_cycle_steps: string
  property_details_id: string
  property_share_details_id: string
}

export interface Partnership {
  _id: string
  type: string
  status: string
  starting_date: string
  property_title: string
  total_cycle_steps: string
  property_details_id: string
  property_share_details_id: string
}

export interface PartnerDetail {
  user_id: string
  user_name: string
  posted_details: {
    amount: number
    user_id: string
    user_name: string
    percentage: number
  }
  agreed_details: {
    amount: string
    percentage: string
  }
  personal_details: {
    id: string
    name: string
    email: string
    phone: string
  }
}

export interface Document {
  _id: string
  description: string
  document_name: string
  document_link: string
  partnership_id: string
  property_partner_id: number
}

export interface PartnershipDetails {
  partners: PartnerDetail[]
  documents: Document[]
  partnership_id: string
  property_details: {
    id: string
    title: string
    image: string[]
    video_url: string
    category_type: string
  }
}

interface RelieveRequest {
  _id: string
  user_name: string
  user_type: number
  partner_id: string
  partnership_id: string
  property_holder: {
    number: string
  }
  user_share_days: string[]
  relieving_status: string
  user_share_amount: string
  user_share_percentage: string
}

interface RelieveRequestWrapper {
  requests: RelieveRequest[]
  property_title: string
}

/*********************/
/* State */

export interface PartnershipState {
  partnershipCycleDetails: PartnershipCycleDetails | null
  partnershipCycleDetailsError: boolean
  partnershipCycleDetailsSuccess: boolean
  partnershipShareDetails: boolean
  partnershipShareDetailsError: boolean
  partnershipShareDetailsSuccess: boolean
  partnershipDocumentsDetails: boolean
  partnershipDocumentsDetailsError: boolean
  partnershipDocumentsDetailsSuccess: boolean
  partnershipList: Partnership[]
  partnershipListError: boolean
  partnershipListSuccess: boolean
  partnershipDetails: PartnershipDetails | null
  partnershipDetailsError: boolean
  partnershipDetailsSuccess: boolean
  partnersAllottedDays: null
  partnersAllottedDaysError: boolean
  partnersAllottedDaysSuccess: boolean
  partnershipDaysUpdate: boolean
  partnershipDaysUpdateError: boolean
  partnershipDaysUpdateSuccess: boolean
  partnersRequestedDays: null
  partnersRequestedDaysError: boolean
  partnersRequestedDaysSuccess: boolean
  dropPartnershipRequestError: boolean
  dropPartnershipRequestSuccess: boolean
  propertyTitle: string | null
  relievePartnershipRequests: RelieveRequest[]
  relievePartnershipRequestsError: boolean
  relievePartnershipRequestsSuccess: boolean
  partnershipRelieveRequestError: boolean
  partnershipRelieveRequestSuccess: boolean
}

/*********************/
/* Action Types */

export const PartnershipActionTypes = {
  PARTNERSHIP_CYCLE_DETAILS_SAVE              : 'PARTNERSHIP_CYCLE_DETAILS_SAVE',
  PARTNERSHIP_CYCLE_DETAILS_SAVE_ERROR        : 'PARTNERSHIP_CYCLE_DETAILS_SAVE_ERROR',
  PARTNERSHIP_CYCLE_DETAILS_SAVE_SUCCESS      : 'PARTNERSHIP_CYCLE_DETAILS_SAVE_SUCCESS',
  PARTNERSHIP_SHARE_DETAILS_SAVE              : 'PARTNERSHIP_SHARE_DETAILS_SAVE',
  PARTNERSHIP_SHARE_DETAILS_SAVE_ERROR        : 'PARTNERSHIP_SHARE_DETAILS_SAVE_ERROR',
  PARTNERSHIP_SHARE_DETAILS_SAVE_SUCCESS      : 'PARTNERSHIP_SHARE_DETAILS_SAVE_SUCCESS',
  PARTNERSHIP_DOCUMENT_DETAILS_SAVE           : 'PARTNERSHIP_DOCUMENT_DETAILS_SAVE',
  PARTNERSHIP_DOCUMENT_DETAILS_SAVE_ERROR     : 'PARTNERSHIP_DOCUMENT_DETAILS_SAVE_ERROR',
  PARTNERSHIP_DOCUMENT_DETAILS_SAVE_SUCCESS   : 'PARTNERSHIP_DOCUMENT_DETAILS_SAVE_SUCCESS',
  PARTNERSHIP_LIST                            : 'PARTNERSHIP_LIST',
  PARTNERSHIP_LIST_ERROR                      : 'PARTNERSHIP_LIST_ERROR',
  PARTNERSHIP_LIST_SUCCESS                    : 'PARTNERSHIP_LIST_SUCCESS',
  PARTNERSHIP_DETAILS                         : 'PARTNERSHIP_DETAILS',
  PARTNERSHIP_DETAILS_ERROR                   : 'PARTNERSHIP_DETAILS_ERROR',
  PARTNERSHIP_DETAILS_SUCCESS                 : 'PARTNERSHIP_DETAILS_SUCCESS',
  PARTNERS_ALLOTTED_DAYS                      : 'PARTNERS_ALLOTTED_DAYS',
  PARTNERS_ALLOTTED_DAYS_ERROR                : 'PARTNERS_ALLOTTED_DAYS_ERROR',
  PARTNERS_ALLOTTED_DAYS_SUCCESS              : 'PARTNERS_ALLOTTED_DAYS_SUCCESS',
  PARTNERSHIP_DAYS_UPDATE                     : 'PARTNERSHIP_DAYS_UPDATE',
  PARTNERSHIP_DAYS_UPDATE_ERROR               : 'PARTNERSHIP_DAYS_UPDATE_ERROR',
  PARTNERSHIP_DAYS_UPDATE_SUCCESS             : 'PARTNERSHIP_DAYS_UPDATE_SUCCESS',
  PARTNERS_REQUESTED_DAYS                     : 'PARTNERS_REQUESTED_DAYS',
  PARTNERS_REQUESTED_DAYS_ERROR               : 'PARTNERS_REQUESTED_DAYS_ERROR',
  PARTNERS_REQUESTED_DAYS_SUCCESS             : 'PARTNERS_REQUESTED_DAYS_SUCCESS',
  DROP_PARTNERSHIP_REQUEST_ERROR              : 'DROP_PARTNERSHIP_REQUEST_ERROR',
  DROP_PARTNERSHIP_REQUEST_SUCCESS            : 'DROP_PARTNERSHIP_REQUEST_SUCCESS',
  RELIEVE_PARTNERSHIP_REQUESTS                : 'RELIEVE_PARTNERSHIP_REQUESTS',
  RELIEVE_PARTNERSHIP_REQUESTS_ERROR          : 'RELIEVE_PARTNERSHIP_REQUESTS_ERROR',
  RELIEVE_PARTNERSHIP_REQUESTS_SUCCESS        : 'RELIEVE_PARTNERSHIP_REQUESTS_SUCCESS',
  APPROVE_PARTNERSHIP_RELIEVE_REQUEST_ERROR   : 'APPROVE_PARTNERSHIP_RELIEVE_REQUEST_ERROR',
  APPROVE_PARTNERSHIP_RELIEVE_REQUEST_SUCCESS : 'APPROVE_PARTNERSHIP_RELIEVE_REQUEST_SUCCESS'
} as const

/*********************/
/* Action Interfaces */

interface SavePartnershipCycleDetailsAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_CYCLE_DETAILS_SAVE
  payload: PartnershipCycleDetails
}
interface SavePartnershipCycleDetailsFailureAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_CYCLE_DETAILS_SAVE_ERROR
  payload: boolean
}
interface SavePartnershipCycleDetailsSuccessAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_CYCLE_DETAILS_SAVE_SUCCESS
  payload: boolean
}

interface SavePartnershipShareDetailsAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_SHARE_DETAILS_SAVE
  payload: boolean
}
interface SavePartnershipShareDetailsFailureAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_SHARE_DETAILS_SAVE_ERROR
  payload: boolean
}
interface SavePartnershipShareDetailsSuccessAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_SHARE_DETAILS_SAVE_SUCCESS
  payload: boolean
}

interface SavePartnershipDocumentsDetailsAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_DOCUMENT_DETAILS_SAVE
  payload: boolean
}
interface SavePartnershipDocumentsDetailsFailureAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_DOCUMENT_DETAILS_SAVE_ERROR
  payload: boolean
}
interface SavePartnershipDocumentsDetailsSuccessAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_DOCUMENT_DETAILS_SAVE_SUCCESS
  payload: boolean
}

interface FetchPartnershipListAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_LIST
  payload: Partnership[]
}
interface FetchPartnershipListFailureAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_LIST_ERROR
  payload: boolean
}
interface FetchPartnershipListSuccessAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_LIST_SUCCESS
  payload: boolean
}

interface FetchPartnershipDetailsAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_DETAILS
  payload: PartnershipDetails
}
interface FetchPartnershipDetailsFailureAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_DETAILS_ERROR
  payload: boolean
}
interface FetchPartnershipDetailsSuccessAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_DETAILS_SUCCESS
  payload: boolean
}

interface FetchPartnersAllottedDaysAction {
  type: typeof PartnershipActionTypes.PARTNERS_ALLOTTED_DAYS
  payload: any
}
interface FetchPartnersAllottedDaysFailureAction {
  type: typeof PartnershipActionTypes.PARTNERS_ALLOTTED_DAYS_ERROR
  payload: boolean
}
interface FetchPartnersAllottedDaysSuccessAction {
  type: typeof PartnershipActionTypes.PARTNERS_ALLOTTED_DAYS_SUCCESS
  payload: boolean
}

interface SubmitPartnershipUpdatedDaysAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_DAYS_UPDATE
  payload: boolean
}
interface SubmitPartnershipUpdatedDaysFailureAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_DAYS_UPDATE_ERROR
  payload: boolean
}
interface SubmitPartnershipUpdatedDaysSuccessAction {
  type: typeof PartnershipActionTypes.PARTNERSHIP_DAYS_UPDATE_SUCCESS
  payload: boolean
}

interface FetchPartnersRequestedDaysAction {
  type: typeof PartnershipActionTypes.PARTNERS_REQUESTED_DAYS
  payload: any
}
interface FetchPartnersRequestedDaysFailureAction {
  type: typeof PartnershipActionTypes.PARTNERS_REQUESTED_DAYS_ERROR
  payload: boolean
}
interface FetchPartnersRequestedDaysSuccessAction {
  type: typeof PartnershipActionTypes.PARTNERS_REQUESTED_DAYS_SUCCESS
  payload: boolean
}

interface DropPartnershipRequestFailureAction {
  type: typeof PartnershipActionTypes.DROP_PARTNERSHIP_REQUEST_ERROR
  payload: boolean
}
interface DropPartnershipRequestSuccessAction {
  type: typeof PartnershipActionTypes.DROP_PARTNERSHIP_REQUEST_SUCCESS
  payload: boolean
}

interface FetchRelievePartnershipRequestsAction {
  type: typeof PartnershipActionTypes.RELIEVE_PARTNERSHIP_REQUESTS
  payload: RelieveRequestWrapper
}
interface FetchRelievePartnershipRequestsFailureAction {
  type: typeof PartnershipActionTypes.RELIEVE_PARTNERSHIP_REQUESTS_ERROR
  payload: boolean
}
interface FetchRelievePartnershipRequestsSuccessAction {
  type: typeof PartnershipActionTypes.RELIEVE_PARTNERSHIP_REQUESTS_SUCCESS
  payload: boolean
}

interface ApprovePartnershipRelieveRequestFailureAction {
  type: typeof PartnershipActionTypes.APPROVE_PARTNERSHIP_RELIEVE_REQUEST_ERROR
  payload: boolean
}
interface ApprovePartnershipRelieveRequestSuccessAction {
  type: typeof PartnershipActionTypes.APPROVE_PARTNERSHIP_RELIEVE_REQUEST_SUCCESS
  payload: boolean
}

/*********************/
/* Actions */

export type PartnershipActions =
  | SavePartnershipCycleDetailsAction
  | SavePartnershipCycleDetailsFailureAction
  | SavePartnershipCycleDetailsSuccessAction
  | SavePartnershipShareDetailsAction
  | SavePartnershipShareDetailsFailureAction
  | SavePartnershipShareDetailsSuccessAction
  | SavePartnershipDocumentsDetailsAction
  | SavePartnershipDocumentsDetailsFailureAction
  | SavePartnershipDocumentsDetailsSuccessAction
  | FetchPartnershipListAction
  | FetchPartnershipListFailureAction
  | FetchPartnershipListSuccessAction
  | FetchPartnershipDetailsAction
  | FetchPartnershipDetailsFailureAction
  | FetchPartnershipDetailsSuccessAction
  | FetchPartnersAllottedDaysAction
  | FetchPartnersAllottedDaysFailureAction
  | FetchPartnersAllottedDaysSuccessAction
  | SubmitPartnershipUpdatedDaysAction
  | SubmitPartnershipUpdatedDaysFailureAction
  | SubmitPartnershipUpdatedDaysSuccessAction
  | FetchPartnersRequestedDaysAction
  | FetchPartnersRequestedDaysFailureAction
  | FetchPartnersRequestedDaysSuccessAction
  | DropPartnershipRequestFailureAction
  | DropPartnershipRequestSuccessAction
  | FetchRelievePartnershipRequestsAction
  | FetchRelievePartnershipRequestsFailureAction
  | FetchRelievePartnershipRequestsSuccessAction
  | ApprovePartnershipRelieveRequestFailureAction
  | ApprovePartnershipRelieveRequestSuccessAction


/*********************/
/* API Calls */

/* Submit Partnership data (Cycle, Share & Documents) */

export function submitPartnershipCycleDetails(formData: any, propertyId: string, categoryType: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.PARTNESHIP_CYCLE_DETAILS_SAVE

    return axios.post(`${url}/${propertyId}/${categoryType}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: PartnershipActionTypes.PARTNERSHIP_CYCLE_DETAILS_SAVE,
        payload: response.data.partnership
      })
    })
    .catch((error) => {
      dispatch(submitPartnershipCycleDetailsError(true))
    })
  }
}
export function submitPartnershipCycleDetailsError(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_CYCLE_DETAILS_SAVE_ERROR,
    payload: status
  }
}
export function submitPartnershipCycleDetailsSuccess(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_CYCLE_DETAILS_SAVE_SUCCESS,
    payload: status
  }
}

export function submitPartnershipShareDetails(formData: any, propertyId: string, partnershipId: string, categoryType: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.PARTNESHIP_SHARE_DETAILS_SAVE

    return axios.post(`${url}/${propertyId}/${partnershipId}/${categoryType}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: PartnershipActionTypes.PARTNERSHIP_SHARE_DETAILS_SAVE,
        payload: true
      })
    })
    .catch((error) => {
      dispatch(submitPartnershipShareDetailsError(true))
    })
  }
}
export function submitPartnershipShareDetailsError(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_SHARE_DETAILS_SAVE_ERROR,
    payload: status
  }
}
export function submitPartnershipShareDetailsSuccess(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_SHARE_DETAILS_SAVE_SUCCESS,
    payload: status
  }
}

export function submitPartnershipDocumentsDetails(formData: any, propertyId: string, partnershipId: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.PARTNESHIP_DOCUMENTS_DETAILS_SAVE

    return axios.post(`${url}/${propertyId}/${partnershipId}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: PartnershipActionTypes.PARTNERSHIP_DOCUMENT_DETAILS_SAVE,
        payload: true
      })
    })
    .catch((error) => {
      dispatch(submitPartnershipDocumentsDetailsError(true))
    })
  }
}
export function submitPartnershipDocumentsDetailsError(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_DOCUMENT_DETAILS_SAVE_ERROR,
    payload: status
  }
}
export function submitPartnershipDocumentsDetailsSuccess(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_DOCUMENT_DETAILS_SAVE_SUCCESS,
    payload: status
  }
}

/* Get Partnerships */

export function fetchPartnershipList() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.ALL_PARTNESHIP

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: PartnershipActionTypes.PARTNERSHIP_LIST,
        payload: response.data.partnership
      })
    })
    .catch((error) => {
      dispatch(fetchPartnershipListError(true))
    })
  }
}
export function fetchPartnershipListError(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_LIST_ERROR,
    payload: status
  }
}
export function fetchPartnershipListSuccess(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_LIST_SUCCESS,
    payload: status
  }
}

/* Get Partnership Details */

export function fetchPartnershipDetails(propertyId: string, partnershipId: string, categoryType: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.PARTNESHIP_DETAILS

    return axios.get(`${url}/${propertyId}/${partnershipId}/${categoryType}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: PartnershipActionTypes.PARTNERSHIP_DETAILS,
        payload: response.data.partnershipviewdetails
      })
    })
    .catch((error) => {
      dispatch(fetchPartnershipDetailsError(true))
    })
  }
}
export function fetchPartnershipDetailsError(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_DETAILS_ERROR,
    payload: status
  }
}
export function fetchPartnershipDetailsSuccess(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_DETAILS_SUCCESS,
    payload: status
  }
}

/* Get Partners Allotted Days */

export function fetchPartnersAllottedDays(partnershipId: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.PARTNERS_ALLOTED_DAYS

    return axios.get(`${url}/${partnershipId}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: PartnershipActionTypes.PARTNERS_ALLOTTED_DAYS,
        payload: response.data.partnerdays
      })
    })
    .catch((error) => {
      dispatch(fetchPartnersAllottedDaysError(true))
    })
  }
}
export function fetchPartnersAllottedDaysError(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERS_ALLOTTED_DAYS_ERROR,
    payload: status
  }
}
export function fetchPartnersAllottedDaysSuccess(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERS_ALLOTTED_DAYS_SUCCESS,
    payload: status
  }
}

/* Update Partnership Days */

export function submitPartnershipUpdatedDays(formData: any, partnershipId: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.PARTNERS_ALLOTED_DAYS_UPDATE

    return axios.post(`${url}/${partnershipId}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: PartnershipActionTypes.PARTNERSHIP_DAYS_UPDATE,
        payload: true
      })
    })
    .catch((error) => {
      dispatch(submitPartnershipUpdatedDaysError(true))
    })
  }
}
export function submitPartnershipUpdatedDaysError(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_DAYS_UPDATE_ERROR,
    payload: status
  }
}
export function submitPartnershipUpdatedDaysSuccess(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERSHIP_DAYS_UPDATE_SUCCESS,
    payload: status
  }
}

/* Get Allotted Days for Partners (MAYBE AFTER UPDATE) */

export function fetchPartnershipUpdatedDays(partnershipId: string, cycleStep: number) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.PARTNERS_ALLOTED_DAYS_WITH_CYCLE

    return axios.get(`${url}/${partnershipId}/${cycleStep}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: PartnershipActionTypes.PARTNERS_ALLOTTED_DAYS,
        payload: response.data.partnerdays
      })
    })
    .catch((error) => {
      dispatch(fetchPartnersAllottedDaysError(true))
    })
  }
}

/* Get Partners Requested Days */

export function fetchPartnersRequestedDays(partnershipId: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.PARTNERS_RQUESTED_DAYS

    return axios.get(`${url}/${partnershipId}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: PartnershipActionTypes.PARTNERS_REQUESTED_DAYS,
        payload: response.data.partnerdays
      })
    })
    .catch((error) => {
      dispatch(fetchPartnersRequestedDaysError(true))
    })
  }
}
export function fetchPartnersRequestedDaysError(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERS_REQUESTED_DAYS_ERROR,
    payload: status
  }
}
export function fetchPartnersRequestedDaysSuccess(status: boolean) {
  return {
    type: PartnershipActionTypes.PARTNERS_REQUESTED_DAYS_SUCCESS,
    payload: status
  }
}

/* Submit Partnership Drop Request */

export function submitDropPartnershipRequest(formData: any, partnershipId: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.PARTNERSHIP_DROP_REQUESTS

    return axios.post(`${url}/${partnershipId}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(submitDropPartnershipRequestSuccess(true))
    })
    .catch((error) => {
      dispatch(submitDropPartnershipRequestError(true))
    })
  }
}
export function submitDropPartnershipRequestError(status: boolean) {
  return {
    type: PartnershipActionTypes.DROP_PARTNERSHIP_REQUEST_ERROR,
    payload: status
  }
}
export function submitDropPartnershipRequestSuccess(status: boolean) {
  return {
    type: PartnershipActionTypes.DROP_PARTNERSHIP_REQUEST_SUCCESS,
    payload: status
  }
}

/* Get Partnership Relieve Requests */

export function fetchRelievePartnershipRequests(propertyId: string, categoryType: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.PARTNERS_RELIEVING_REQUESTS

    return axios.get(`${url}/${propertyId}/${categoryType}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: PartnershipActionTypes.RELIEVE_PARTNERSHIP_REQUESTS,
        payload: response.data.partnerrelieverequest
      })
    })
    .catch((error) => {
      dispatch(fetchRelievePartnershipRequestsError(true))
    })
  }
}
export function fetchRelievePartnershipRequestsError(status: boolean) {
  return {
    type: PartnershipActionTypes.RELIEVE_PARTNERSHIP_REQUESTS_ERROR,
    payload: status
  }
}
export function fetchRelievePartnershipRequestsSuccess(status: boolean) {
  return {
    type: PartnershipActionTypes.RELIEVE_PARTNERSHIP_REQUESTS_SUCCESS,
    payload: status
  }
}

/* Approve Partnership Relieve Request */

export function approvePartnershipRelieveRequest(formData: any, partnershipId: string, partnerId: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.APPROVE_PARTNERSHIP_RELIEVE_REQUEST

    return axios.post(`${url}/${partnershipId}/${partnerId}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(approvePartnershipRelieveRequestSuccess(true))
    })
    .catch((error) => {
      dispatch(approvePartnershipRelieveRequestError(true))
    })
  }
}
export function approvePartnershipRelieveRequestError(status: boolean) {
  return {
    type: PartnershipActionTypes.APPROVE_PARTNERSHIP_RELIEVE_REQUEST_ERROR,
    payload: status
  }
}
export function approvePartnershipRelieveRequestSuccess(status: boolean) {
  return {
    type: PartnershipActionTypes.APPROVE_PARTNERSHIP_RELIEVE_REQUEST_SUCCESS,
    payload: status
  }
}
