import { useState }         from 'react'
import AddCategoryTypeModal from '../../../modal/AddCategoryTypeModal'

const ListToolbar = () => {
  const [showCategoryTypeModal, setShowCategoryTypeModal] = useState<boolean>(false)

  const toggleCategoryTypeModal = () => {
    setShowCategoryTypeModal(!showCategoryTypeModal)
  }

  return (
    <>
      <div className='d-flex justify-content-end'>
        <button type='button' className='btn btn-sm btn-primary' onClick={toggleCategoryTypeModal}>
          <i className="bi bi-plus-circle-fill"></i> New Category Type
        </button>
      </div>

      <AddCategoryTypeModal
        showCategoryTypeModal={showCategoryTypeModal}
        toggleCategoryTypeModal={toggleCategoryTypeModal} />
    </>
  )
}

export { ListToolbar }
