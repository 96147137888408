let config = {
  "development": {
    "API_BASE_URL": "https://dev.api.aanshik.com/api",
    "END_POINTS": {
      "ADMIN_LOGIN": "/admin-login",
      "ADMIN_GO_LOGIN": "/admin-go-login",
      "ADMIN_KISSAN_LOGIN": "/admin-kissan-login",
      "CREATE_CATEGORY": "",
      "SAVE_CATEGORY": "/admin-category",
      "EDIT_CATEGORY": "",
      "UPDATE_CATEGORY": "",
      "DELETE_CATEGORY": "",
      "LIST_CATEGORY": "/admin-category",
      "CATEGORY": "/admin-category",
      "CATEGORY_EN_DIS": "/admin-category-activate-inactivate",
      "CREATE_SUB_CATEGORY": "",
      "SAVE_SUB_CATEGORY": "",
      "EDIT_SUB_CATEGORY" : "",
      "UPDATE_SUB_CATEGORY": "",
      "DELETE_SUB_CATEGORY": "",
      "LIST_SUB_CATEGORY": "/admin-sub-category",
      "SUB_CATEGORY": "",
      "SUB_CATEGORY_CATEGORY_WISE": "/sub-category-view",
      "COMMON_ALL_CATEGORY": "/category",
      "COMMON_ALL_LOCATIONS": "/location",
      "COMMON_ALL_CATEGORY_TYPE": "/category-types",

      // ADMIN RELATED

      "ADMIN_SETTINGS_LOCATION_LIST": "/admin-location",
      "ADMIN_SETTINGS_CATEGORY_LIST": "/admin-category",
      "ADMIN_SETTINGS_SUB_CATEGORY_LIST": "/sub-category-listing",
      "ADMIN_SETTINGS_CATEGORY_TYPES_LIST": "/category-types",

      // GO RELATED

      "APPROVED_INTERESTED_PERSONS_GO_ADS_LIST": "/go-interest-approved",
      "APPROVED_INTERESTED_PERSONS_GO_LIST": "/interested-users",

      // KISSAN RELATED

      // ADS > NEW ADS
      "KISSAN_POST_LISTS": "/property-list",
      // ADS > INTEREST LIST
      "KISSAN_INTEREST_LIST": "/kissan-interest-list",
      // ADS > APPROVED LIST
      "KISSAN_INTERESTED_PERSONS_APPROVED_LIST": "/interest-approved",

      // DEALS > DEALS LIST
      "KISSAN_DEAL_LIST": "/kissan-deal",
      // DEALS > RELIEVED LIST
      "KISSAN_DEAL_RELIEVED_LIST": "/kissan-relieved-deal",
      // FARMERS
      // INVESTORS
      "KISSAN_FARMERS_LIST": "/farmers-list",


      "KISSAN_NEW_POST": "/property-add",
      "KISSAN_NEW_POST_UPDATE": "/property-update",
      "KISSAN_POST_EDIT": "/property-edit",
      "KISSAN_POST_VIEW": "/property-view",
      "KISSAN_POST_STATUS_UPDATE": "/property-action",
      "KISSAN_INTERESTED_PERSONS_LIST": "/kissan-interest-view",
      "KISSAN_INTERESTED_PERSONS_APPROVE": "/interest-update",
      "KISSAN_FETCH_APPROVED_PROPERTY": "/deal-details",
      // Deal 
      "KISSAN_INVESTOR_RELIEVING_REQUESTS": "/investor-request",
      "KISSAN_INVESTOR_RELIEVING_REQUESTS_APPROVE": "/investor-request",
      "KISSAN_FARMER_RELIEVING_REQUESTS": "/farmer-request",
      "KISSAN_FARMER_RELIEVING_REQUESTS_APPROVE": "/farmer-request",
      "KISSAN_DEAL_DETAILS_VIEW": "/deal-view",
      "KISSAN_DEAL_ADMIN_STOP_ACTION": "/kissan-deal-stop",
      "KISSAN_DEAL_DOCUMENT_SUBMIT": "/kissan-document",
      "KISSAN_DEAL_TIMELINE_ADD": "/kissan-timeline",
      "KISSAN_DEAL_APPROVED_PROCEED": "/kissan-deal",
      "KISSAN_DEAL_DROPP_REQ_INIT": "/kissan-deal-drop",

      /* Kissan Dashboard */

      "KISAAN_DASHBOARD_COUNTS": "/kissan-dashboard",
      "KISAAN_DASHBOARD_REGISTERD_USERS": "/kissan-visitors",

      /* Admin settings */
      "ADMIN_SETTINGS_SUBMIT_SUB_CATEGORY": "/admin-sub-category",
      "ADMIN_SETTINGS_SUBMIT_CATEGORY": "/admin-category",
      "ADMIN_SETTINGS_SUBMIT_CATEGORY_TYPE": "/category-types",
      "ADMIN_SETTINGS_SUBMIT_LOCATION": "/admin-location",

      /* Go End points */
      "GO_ADS_LIST": "/go-list",
      "GO_ADS_DETAIL": "/go-view",
      "GO_STATUS_UPDATE_NEW_AD": "/go-action",
      "APPLY_OFFER_GO_ADS": "/offer-action",  
      "APPROVED_GO_ADS_LIST": "/offer-list",
      "INTERESTED_GO_ADS_LIST": "/go-interest-list",
      "INTERESTED_PERSONS_GO_ADS_LIST": "/go-interest-view",
      "INTERESTED_PERSONS_GO_STATUS_UPDATE": "/go-interest-update",

      /* Kissan multi investors */
      "KISSAN_FETCH_APPROVED_INVESTORS": "/kissan-interested-users",
      "KISSAN_REMOVE_APPROVED_INVESTOR": "/investor-remove",

      /* Adds initiate to deal start */
      "KISSAN_FETCH_ADD_INITIATE_TO_DEAL": "/deal-details",
      "KISSAN_PROCEED_ADD_TO_DEAL": "/kissan-deal",
      "KISSAN_DEAL_DETAILS_TO_EDIT": "/kissan-deal-edit",
      "KISSAN_DEAL_UPDATE": "/kissan-deal-update",

      "KISSAN_DEAL_INVESTOR_DETAILS": "/investor-deal-view",
      /* Adds initiate to deal end */

      /* Kissan deal documents start */
      "KISSAN_DEAL_INVESTORS_FOR_DOCUMENTATION": "/investor-dropdown" ,
      /* Kissan deal documents end */

      /* Partnership common start */
      "PARTNESHIP_CYCLE_DETAILS_SAVE": "/partnership",
      "PARTNESHIP_SHARE_DETAILS_SAVE": "/property-partner",
      "PARTNESHIP_DOCUMENTS_DETAILS_SAVE": "/partnership-document",
      "ALL_PARTNESHIP": "/partnership-list",
      "PARTNESHIP_DETAILS": "/partnership-view",
      "PARTNERS_RELIEVING_REQUESTS": "/partner-request",
      "PARTNERSHIP_DROP_REQUESTS": "/partnership-drop",
      "PARTNERS_ALLOTED_DAYS": "/partners-days",
      "PARTNERS_ALLOTED_DAYS_UPDATE": "/partners-days-update",
      "PARTNERS_ALLOTED_DAYS_WITH_CYCLE": "/partners-days-with-cycle",
      "PARTNERS_RQUESTED_DAYS": "/partners-requested-days",
      /* Partnership common end */

      /* Go profit based partenership start  */
      "PROFIT_BASED_APPROVED_PARTNERS": "/initiated-users-list"
      /* Go profit based partenership end */
    }
  },
  "production": {
    "API_BASE_URL": "https://dev.api.aanshik.com/api",
    "END_POINTS": {
      "ADMIN_LOGIN": "/admin-login",
      "ADMIN_GO_LOGIN": "/admin-go-login",
      "ADMIN_KISSAN_LOGIN": "/admin-kissan-login",
      "CREATE_CATEGORY": "",
      "SAVE_CATEGORY": "/admin-category",
      "EDIT_CATEGORY": "",
      "UPDATE_CATEGORY": "",
      "DELETE_CATEGORY": "",
      "LIST_CATEGORY": "/admin-category",
      "CATEGORY": "/admin-category",
      "CATEGORY_EN_DIS": "/admin-category-activate-inactivate",
      "CREATE_SUB_CATEGORY": "",
      "SAVE_SUB_CATEGORY": "",
      "EDIT_SUB_CATEGORY" : "",
      "UPDATE_SUB_CATEGORY": "",
      "DELETE_SUB_CATEGORY": "",
      "LIST_SUB_CATEGORY": "/admin-sub-category",
      "SUB_CATEGORY": "",
      "SUB_CATEGORY_CATEGORY_WISE": "/sub-category-view",
      "COMMON_ALL_CATEGORY": "/category",
      "COMMON_ALL_LOCATIONS": "/location",
      "COMMON_ALL_CATEGORY_TYPE": "/category-types",
      "KISSAN_NEW_POST": "/property-add",
      "KISSAN_NEW_POST_UPDATE": "/property-update",
      "KISSAN_POST_LISTS": "/property-list",
      "KISSAN_POST_EDIT": "/property-edit",
      "KISSAN_POST_VIEW": "/property-view",
      "KISSAN_POST_STATUS_UPDATE": "/property-action",
      "KISSAN_INTEREST_LIST": "/kissan-interest-list",
      "KISSAN_INTERESTED_PERSONS_LIST": "/kissan-interest-view",
      "KISSAN_INTERESTED_PERSONS_APPROVE": "/interest-update",
      "KISSAN_INTERESTED_PERSONS_APPROVED_LIST": "/interest-approved",
      "KISSAN_FETCH_APPROVED_PROPERTY": "/deal-details",
      // Deal 
      "KISSAN_DEAL_LIST": "/kissan-deal",
      "KISSAN_INVESTOR_RELIEVING_REQUESTS": "/investor-request",
      "KISSAN_INVESTOR_RELIEVING_REQUESTS_APPROVE": "/investor-request",
      "KISSAN_FARMER_RELIEVING_REQUESTS": "/farmer-request",
      "KISSAN_FARMER_RELIEVING_REQUESTS_APPROVE": "/farmer-request",
      "KISSAN_DEAL_DETAILS_VIEW": "/deal-view",
      "KISSAN_DEAL_ADMIN_STOP_ACTION": "/kissan-deal-stop",
      "KISSAN_DEAL_DOCUMENT_SUBMIT": "/kissan-document",
      "KISSAN_DEAL_TIMELINE_ADD": "/kissan-timeline",
      "KISSAN_DEAL_APPROVED_PROCEED": "/kissan-deal",
      "KISSAN_DEAL_DROPP_REQ_INIT": "/kissan-deal-drop",
      // Farmers

      "KISSAN_FARMERS_LIST": "/farmers-list",
      /* Kissan Dashboard */

      "KISAAN_DASHBOARD_COUNTS": "/kissan-dashboard",
      "KISAAN_DASHBOARD_REGISTERD_USERS": "/kissan-visitors",

      /* Admin settings */ 
      "ADMIN_SETTINGS_LOCATION_LIST": "/admin-location",
      "ADMIN_SETTINGS_CATEGORY_TYPES_LIST": "/category-types",
      "ADMIN_SETTINGS_CATEGORY_LIST": "/admin-category",
      "ADMIN_SETTINGS_SUB_CATEGORY_LIST": "/sub-category-listing",
      "ADMIN_SETTINGS_SUBMIT_SUB_CATEGORY": "/admin-sub-category",
      "ADMIN_SETTINGS_SUBMIT_CATEGORY": "/admin-category",
      "ADMIN_SETTINGS_SUBMIT_CATEGORY_TYPE": "/category-types",
      "ADMIN_SETTINGS_SUBMIT_LOCATION": "/admin-location",

      /* Go End points */
      "GO_ADS_LIST": "/go-list",
      "GO_ADS_DETAIL": "/go-view",
      "GO_STATUS_UPDATE_NEW_AD": "/go-action",
      "APPLY_OFFER_GO_ADS": "/offer-action",
      "APPROVED_GO_ADS_LIST": "/offer-list",
      "INTERESTED_GO_ADS_LIST": "/go-interest-list",
      "INTERESTED_PERSONS_GO_ADS_LIST": "/go-interest-view",
      "INTERESTED_PERSONS_GO_STATUS_UPDATE": "/go-interest-update",
      "APPROVED_INTERESTED_PERSONS_GO_ADS_LIST": "/go-interest-approved",
      "APPROVED_INTRESTED_PERSONS_GO_LIST": "/interested-users",

      /* Kissan multi investors */
      "KISSAN_FETCH_APPROVED_INVESTORS": "/kissan-interested-users",
      "KISSAN_REMOVE_APPROVED_INVESTOR": "/investor-remove",

      /* Adds initiate to deal start */
      "KISSAN_FETCH_ADD_INITIATE_TO_DEAL": "/deal-details",
      "KISSAN_PROCEED_ADD_TO_DEAL": "/kissan-deal",
      "KISSAN_DEAL_DETAILS_TO_EDIT": "/kissan-deal-edit",
      "KISSAN_DEAL_UPDATE": "/kissan-deal-update",
      "KISSAN_DEAL_RELIEVED_LIST": "/kissan-relieved-deal",
      "KISSAN_DEAL_INVESTOR_DETAILS": "/investor-deal-view",
      /* Adds initiate to deal end */

      /* Kissan deal documents start */
      "KISSAN_DEAL_INVESTORS_FOR_DOCUMENTATION": "/investor-dropdown" ,
      /* Kissan deal documents end */

      /* Partnership common start */
      "PARTNESHIP_CYCLE_DETAILS_SAVE": "/partnership",
      "PARTNESHIP_SHARE_DETAILS_SAVE": "/property-partner",
      "PARTNESHIP_DOCUMENTS_DETAILS_SAVE": "/partnership-document",

      "ALL_PARTNESHIP": "/partnership-list",
      "PARTNESHIP_DETAILS": "/partnership-view",
      "PARTNERS_RELIEVING_REQUESTS": "/partner-request",
      "PARTNERSHIP_DROP_REQUESTS": "/partnership-drop",
      "PARTNERS_ALLOTED_DAYS": "/partners-days",
      "PARTNERS_ALLOTED_DAYS_UPDATE": "/partners-days-update",
      "PARTNERS_ALLOTED_DAYS_WITH_CYCLE": "/partners-days-with-cycle",
      "PARTNERS_RQUESTED_DAYS": "/partners-requested-days",
      /* Partnership common end */

      /* Go profit based partenership start  */
      "PROFIT_BASED_APPROVED_PARTNERS": "/initiated-users-list"
      /* Go profit based partenership end */
    }
  }
}

let AppConfig: any;

switch (process.env.NODE_ENV) {
  case 'production':
    AppConfig = config.production
    break
  case 'development':
    AppConfig = config.development
    break
  default:
}

export default AppConfig
