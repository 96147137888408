import { FC, useEffect, useState } from 'react'
import { Dispatch }                from 'react'
import { ChangeEvent, MouseEvent } from 'react'
import { Link }                    from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import debounce                    from 'lodash/debounce'
import orderBy                     from 'lodash/orderBy'
import { KTCard, KTCardBody }      from '../../../../_metronic/helpers'
import { useDebounce }             from '../../../../_metronic/helpers'
import { PageTitle }               from '../../../../_metronic/layout/core'
import { ListLoading }             from '../../../components/ListLoading'
import ListPagination              from '../../../components/ListPagination'
import { showSnackbar1 }           from '../../../helpers/SnackbarHelper1'
import { RootState }               from '../../../store'
import { useAppDispatch }          from '../../../store/hooks'
import {
  Partnership,
  PartnershipActions,
  fetchPartnershipList,
  fetchPartnershipListError,
  fetchPartnershipListSuccess }    from '../../../store/actions/go/partnership.actions'
import DropPartnershipModal        from './modals/DropPartnershipModal'

const PartnershipsConstants = {
  partnershipListErrorMsg: 'Error in fetching partnerships. Please try again'
}

const Partnerships: FC<PartnershipsProps> = ({
  partnershipList,
  partnershipListError,
  partnershipListSuccess,
  fetchPartnershipList,
  fetchPartnershipListError,
  fetchPartnershipListSuccess
}) => {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading]                               = useState<boolean>(false)
  const [columnToQuery, setColumnToQuery]                       = useState<string>('title')
  const [searchTerm, setSearchTerm]                             = useState<string>('')
  const [adsList, setAdsList]                                   = useState<Partnership[]>([])
  const [page, setPage]                                         = useState<number>(0)
  const [rowsPerPage, setRowsPerPage]                           = useState<number>(5)
  const [selCategoryType, setSelCategoryType]                   = useState<string>('')
  const [selPropertyDetailsId, setSelPropertyDetailsId]         = useState<string>('')
  const [selPartnershipId, setSelPartnershipId]                 = useState<string>('')
  const [showDropPartnershipModal, setShowDropPartnershipModal] = useState<boolean>(false)

  useEffect(() => {
    dispatch(fetchPartnershipList())
    setAdsList(partnershipList)
  }, [])

  useEffect(() => {
    if (partnershipListError) {
      showSnackbar1(PartnershipsConstants.partnershipListErrorMsg)
      closePartnershipListErrorMsg()
    }
  }, [partnershipListError])

  useEffect(() => {
    if (searchTerm != '') {
      filterData()
    } else if (searchTerm == '') {
      setAdsList(partnershipList)
    }
  }, [searchTerm, columnToQuery])

  const filterData = () => {
    var debouncedSearch = debounce(() => {
      let currentList = [...partnershipList]
      let orderedList = orderBy(
        currentList.filter((x) =>
          x[columnToQuery as keyof Partnership]
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ),
        columnToQuery, 'asc'
      )

      setAdsList(orderedList)
    }, 1000)

    debouncedSearch()
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const closePartnershipListErrorMsg = () => {
    dispatch(fetchPartnershipListError(false))
  }

  const openDropPartnershipModal = (partnershipId: string, propertyDetailsId: string, categoryType: string) => {
    setSelCategoryType(categoryType)
    setSelPropertyDetailsId(propertyDetailsId)
    setSelPartnershipId(partnershipId)
    setShowDropPartnershipModal(true)
  }

  const closeDropPartnershipModal = () => {
    setShowDropPartnershipModal(false)
    setSelPartnershipId('')
    setSelPropertyDetailsId('')
    setSelCategoryType('')
  }

  const renderActions = (partnershipId: string, propertyDetailsId: string, categoryType: string) => {
    return (
      <>
        <Link
          to={`/go-partnership-details/${propertyDetailsId}/${partnershipId}/${categoryType}`}
          title='View Partnership'
          className='btn btn-sm btn-light btn-active-light-primary'>
          <i className='bi bi-eye-fill pe-0 fs-2'></i>
        </Link>
        <button
          type='button'
          title='Drop Partnership'
          onClick={(event) => openDropPartnershipModal(partnershipId, propertyDetailsId, categoryType)}
          className='ms-2 btn btn-sm btn-light btn-active-light-primary'>
          <i className='bi bi-arrow-down-square pe-0 fs-2'></i>
        </button>
        <Link
          to={`/go-partnership-relieve/${propertyDetailsId}/${categoryType}`}
          title='Relieve Partnership'
          className='ms-2 btn btn-sm btn-light btn-active-light-primary'>
          <i className='bi bi-box-arrow-in-right pe-0 fs-2'></i>
        </Link>
      </>
    )
  }

  const populatePartnershipsList = () => {
    return (
      rowsPerPage > 0
        ? adsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : adsList
    ).map((row, index) => (
      <tr role='row' key={index}>
        <td role='cell'>
          <div>{(page + 1 - 1) * rowsPerPage + (index + 1)}</div>
        </td>
        <td role='cell'>{row.property_title}</td>
        <td role='cell'>{row.starting_date}</td>
        <td role='cell'>{row.type}</td>
        <td role='cell' className='text-center'>{row.total_cycle_steps}</td>
        <td role='cell' className='text-end min-w-100px'>
          {renderActions(row._id, row.property_details_id, row.type)}
        </td>
      </tr>
    ))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Partnerships</PageTitle>

      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <select
              data-control='select2'
              data-hide-search='true'
              value={columnToQuery}
              onChange={(event) => setColumnToQuery(event.target.value)}
              className='form-select form-select-sm form-select-solid'>
              <option value='property_title'>Title</option>
              <option value='type'>Category</option>
              <option value='starting_date'>Starting Date</option>
              <option value='total_cycle_steps'>Cycle Steps</option>
            </select>
            <div className='d-flex align-items-center position-relative my-1 ms-3'>
              <i className='bi bi-search position-absolute ms-3'></i>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid w-250px ps-10'
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)} />
            </div>
          </div>
        </div>

        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='aanshik-go-partnerships'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th role='columnheader' className='min-w-50px'>#</th>
                  <th role='columnheader' className='min-w-125px'>Title</th>
                  <th role='columnheader'>Starting Date</th>
                  <th role='columnheader'>Type</th>
                  <th role='columnheader' className='text-center'>Total Cycle Steps</th>
                  <th role='columnheader' className='text-end min-w-100px'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600' role='rowgroup'>
              {
                adsList.length > 0 ? populatePartnershipsList() : (
                  <tr>
                    <td colSpan={6}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )
              }
              </tbody>
              <tfoot>
                <tr>
                  <ListPagination
                    page={page}
                    count={partnershipList.length}
                    colSpan={6}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} />
                </tr>
              </tfoot>
            </table>
          </div>

          {isLoading && <ListLoading />}
        </KTCardBody>
      </KTCard>

      {
        showDropPartnershipModal &&
        <DropPartnershipModal
          partnershipId={selPartnershipId}
          showDropPartnershipModal={showDropPartnershipModal}
          closeDropPartnershipModal={closeDropPartnershipModal} />
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    partnershipList: state.partnershipReducer.partnershipList,
    partnershipListError: state.partnershipReducer.partnershipListError,
    partnershipListSuccess: state.partnershipReducer.partnershipListSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<PartnershipActions>) => {
  return {
    fetchPartnershipList,
    fetchPartnershipListError,
    fetchPartnershipListSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PartnershipsProps = ConnectedProps<typeof connector>

export default connector(Partnerships)
