import { FC, useState }            from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { Link }                    from 'react-router-dom'
import { showSnackbar }            from '../../../../helpers/SnackbarHelper'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  GoAdsActions,
  updateGoAdStatus,
  updateGoAdStatusError,
  updateGoAdStatusSuccess }        from '../../../../store/actions/go/ads.actions'

const AdminActionConstants = {
  updateGoAdStatusErrorMsg: 'Error occured while approving ad. Please try again',
  updateGoAdStatusSuccessMsg1: 'Ad approved successfully',
  updateGoAdStatusSuccessMsg2: 'Ad declined successfully'
}

const AdminAction: FC<AdminActionProps> = ({
  currentId,
  goAdStatusError,
  goAdStatusSuccess,
  updateGoAdStatus,
  updateGoAdStatusError,
  updateGoAdStatusSuccess
}) => {
  const dispatch = useAppDispatch()

  const [approvalStatus, setApprovalStatus]     = useState<string>('0')
  const [updateSuccessMsg, setUpdateSuccessMsg] = useState<string>('')

  const handleFormSubmit = () => {
    let formData = new FormData()
    formData.append('approval_status', approvalStatus)

    if (approvalStatus == '0') {
      setUpdateSuccessMsg(AdminActionConstants.updateGoAdStatusSuccessMsg2)
    } else if (approvalStatus == '1') {
      setUpdateSuccessMsg(AdminActionConstants.updateGoAdStatusSuccessMsg1)
    }

    dispatch(updateGoAdStatus(formData, currentId))
  }

  const closeUpdateGoAdStatusErrorMsg = () => {
    dispatch(updateGoAdStatusError(false))
    setUpdateSuccessMsg('')
  }

  const closeUpdateGoAdStatusSuccessMsg = () => {
    dispatch(updateGoAdStatusSuccess(false))
    setUpdateSuccessMsg('')
  }

  return (
    <>
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>AD STATUS</h3>
              </div>
            </div>

            <div className='card-body p-9'>
              <div className='row mb-7'>
                <div className='col-lg-12'>
                  <select
                    name='approvalStatus'
                    data-control='select2'
                    data-hide-search='true'
                    onChange={(event) => setApprovalStatus(event.target.value)}
                    className='form-select form-select-sm form-select-solid w-125px d-inline-block'
                    defaultValue='Status'>
                    <option value='0'>Decline</option>
                    <option value='1'>Approve</option>
                  </select>

                  <button
                    type='button'
                    onClick={handleFormSubmit}
                    className='ms-2 btn btn-sm btn-primary'>
                    Save
                  </button>
                  <Link
                    to={'/go-list-post'}
                    className='ms-2 btn btn-sm btn-light-primary'>Cancel</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        goAdStatusError &&
        showSnackbar(AdminActionConstants.updateGoAdStatusErrorMsg, closeUpdateGoAdStatusErrorMsg)
      }
      {
        goAdStatusSuccess &&
        showSnackbar(updateSuccessMsg, closeUpdateGoAdStatusSuccessMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    currentId: ownProps.currentId,
    goAdStatusError: state.goAdsReducer.goAdStatusError,
    goAdStatusSuccess: state.goAdsReducer.goAdStatusSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GoAdsActions>) => {
  return {
    updateGoAdStatus,
    updateGoAdStatusError,
    updateGoAdStatusSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type AdminActionProps = ConnectedProps<typeof connector>

export default connector(AdminAction)
