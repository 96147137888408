// @ts-nocheck
import { FC, useEffect }           from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { KTCard }                  from '../../../../../_metronic/helpers'
import { PageTitle }               from '../../../../../_metronic/layout/core'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  AdminSettingsActions,
  fetchLocations,
  fetchLocationsError,
  fetchLocationsSuccess }          from '../../../../store/actions/admin/settings.action'
import { showSnackbar }            from '../../../../helpers/SnackbarHelper'
import { ListHeader }              from './list/components/header/ListHeader'
import LocationsTable              from './list/table/LocationsTable'

const LocationsConstants = {
  fetchLocationsErrorMsg: 'Error in fetching locations. Please try again'
}

const Locations: FC<LocationsProps> = ({
  locationsFetchError,
  locationsFetchSuccess,
  locationSubmitSuccess,
  fetchLocations,
  fetchLocationsError,
  fetchLocationsSuccess
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchLocations())
  }, [])

  useEffect(() => {
    if (locationSubmitSuccess) {
      dispatch(fetchLocations())
    }
  }, [locationSubmitSuccess])

  const closeFetchLocationsErrorMsg = () => {
    dispatch(fetchLocationsError(false))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Locations</PageTitle>

      <KTCard>
        <ListHeader />
        <LocationsTable />
      </KTCard>

      {
        locationsFetchError &&
        showSnackbar(LocationsConstants.fetchLocationsErrorMsg, closeFetchLocationsErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    locationsFetchError: state.settingsReducer.locationsFetchError,
    locationsFetchSuccess: state.settingsReducer.locationsFetchSuccess,
    locationSubmitSuccess: state.settingsReducer.locationSubmitSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AdminSettingsActions>) => {
  return {
    fetchLocations,
    fetchLocationsError,
    fetchLocationsSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type LocationsProps = ConnectedProps<typeof connector>

export default connector(Locations)
