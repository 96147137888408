import React                    from 'react'
import { AsideMenuItem }        from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Dashboard'
        fontIcon='bi-app-indicator' />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>SYSTEM</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Master Settings'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'>
        <AsideMenuItem hasBullet={true} to='admin-location-list'       title='Locations' />
        <AsideMenuItem hasBullet={true} to='admin-category-list'       title='Categories' />
        <AsideMenuItem hasBullet={true} to='admin-category-types-list' title='Category Types' />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>GO</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Ads'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'>
        <AsideMenuItem hasBullet={true} to='go-list-post'                            title='New Ads' />
        <AsideMenuItem hasBullet={true} to='go-approved-posts-list'                  title='Approved Ads' />
        <AsideMenuItem hasBullet={true} to='go-intrested-post-list'                  title='Interested Ads' />
        <AsideMenuItem hasBullet={true} to='approved-interested-persons-go-ads-list' title='Interested Persons Ads' />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Partnership'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'>
        <AsideMenuItem hasBullet={true} to='go-partnership-list' title='Partnerships' />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>KISSAN</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Ads'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'>
        <AsideMenuItem hasBullet={true} to='/kissan-list-posts'    title='New Ads'         />
        <AsideMenuItem hasBullet={true} to='/kissan-intrest-lists' title='Interested List' />
        <AsideMenuItem hasBullet={true} to='/kissan-approved-list' title='Approved List'   />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Deals'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'>
        <AsideMenuItem to='/kissan/deals/list'      title='Deals List'      hasBullet={true} />
        <AsideMenuItem to='/kissan/relieved/list'   title='Relieved List'   hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/agents'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Agents'
        fontIcon='bi-layers' />
      <AsideMenuItem
        to='/kissan-farmers-list'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Farmers'
        fontIcon='bi-layers' />
      <AsideMenuItem
        to='/kissan-investors-list'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Investors'
        fontIcon='bi-layers' />
    </>
  )
}
