// @ts-nocheck
import { FC, useEffect, useState }         from 'react'
import { Dispatch }                        from 'redux'
import { connect, ConnectedProps }         from 'react-redux'
import { useParams }                       from 'react-router-dom'
import _                                   from 'lodash'
import { PageLink, PageTitle }             from '../../../../../_metronic/layout/core'
import { RootState }                       from '../../../../store'
import { useAppDispatch }                  from '../../../../store/hooks'
import {
  GoAdsActions,
  fetchApprovedPersonsList,
  fetchApprovedPersonsListError,
  fetchApprovedPersonsListSuccess }        from '../../../../store/actions/go/ads.actions'
import { Partner }                         from '../../../../store/actions/go/partnership.actions'
import { default as NonTimeBasedCycle }    from '../profit-based-init/wizards/Start'
import { default as NonTimeBasedPartners } from '../profit-based-init/wizards/Partners'
import Cycle                               from './wizards/Cycle'
import Partners                            from './wizards/Partners'
import Documents                           from './wizards/Documents'

const breadcrumbs: Array<PageLink> = [{
  path: '/approved-interested-persons-go-ads-list',
  title: 'Interested Persons Ads',
  isActive: false,
  isSeparator: false
}, {
  path: '',
  title: '',
  isActive: false,
  isSeparator: true
}]

const TimeBasedInit: FC<TimeBasedInitProps> = ({
  partnershipCycleDetails,
  partnershipCycleDetailsError,
  partnershipCycleDetailsSuccess,
  approvedPersonsList,
  approvedPersonsListError,
  approvedPersonsListSuccess,
  fetchApprovedPersonsList,
  fetchApprovedPersonsListError,
  fetchApprovedPersonsListSuccess
}) => {
  const params   = useParams()
  const dispatch = useAppDispatch()

  const [currentStep, setCurrentStep]                         = useState<number>(1)
  const [categoryType, setCategoryType]                       = useState<string>('')
  const [propertyId, setPropertyId]                           = useState<string>('')
  const [initialHolder, setInitialHolder]                     = useState<string>('')
  const [totalCycleSteps, setTotalCycleSteps]                 = useState<number>(0)
  const [totalDaysPerCycle, setTotalDaysPerCycle]             = useState<number>(90)
  const [propertyName, setPropertyName]                       = useState<string>('')
  const [propertyAmount, setPropertyAmount]                   = useState<number>(0)
  const [partnerList, setPartnerList]                         = useState<Partner[]>([])
  const [partnerNames, setPartnerNames]                       = useState<string[]>([])
  const [partnerUserIds, setPartnerUserIds]                   = useState<string[]>([])
  const [partnerInterestIds, setPartnerInterestIds]           = useState<string[]>([])
  const [partnerShareAmounts, setPartnerShareAmounts]         = useState<number[]>([])
  const [partnerSharePercentages, setPartnerSharePercentages] = useState<number[]>([])
  const [totalDealDays, setTotalDealDays]                     = useState<number>(0)           // ONLY IN STEP:2 TIMEBASED
  const [totalDealPartners, setTotalDealPartners]             = useState<number>(0)
  const [totalDealPercentage, setTotalDealPercentage]         = useState<number>(0)
  const [partnerDaysCount, setPartnerDaysCount]               = useState<number[]>([])

  useEffect(() => {
    if (params.id) {
      setPropertyId(params.id)
      dispatch(fetchApprovedPersonsList(params.id))
    }
    if (params.type) {
      setCategoryType(params.type)
    }
  }, [])

  useEffect(() => {
    if (approvedPersonsListSuccess && approvedPersonsList != null) {
      let partnerList = approvedPersonsList.users_list

      let partnerNames = _.map(partnerList, 'name')
      let partnerUserIds = _.map(partnerList, 'user_id')
      let partnerInterestIds = _.map(partnerList, 'interest_id')
      let partnerDaysCount = _.map(partnerList, 'days')
      let partnerShareAmounts = _.map(partnerList, 'amount')
      let partnerSharePercentages = _.map(partnerList, 'percentage')

      setPropertyName(approvedPersonsList.property_name)
      setPropertyAmount(_.toNumber(approvedPersonsList.property_amount))
      setPartnerList(partnerList)
      setPartnerNames(partnerNames)
      setPartnerUserIds(partnerUserIds)
      setPartnerInterestIds(partnerInterestIds)
      setPartnerDaysCount(partnerDaysCount)
      setPartnerShareAmounts(partnerShareAmounts)
      setPartnerSharePercentages(partnerSharePercentages)
      setTotalDealPartners(partnerNames.length)
    }
  }, [approvedPersonsListSuccess])

  useEffect(() => {
    setTotalDealDays(_.sum(partnerDaysCount))
  }, [partnerDaysCount])

  useEffect(() => {
    setPropertyAmount(_.sum(partnerShareAmounts))
  }, [partnerShareAmounts])

  useEffect(() => {
    setTotalDealPercentage(_.sum(partnerSharePercentages))
  }, [partnerSharePercentages])

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  }

  const handleTextChange = (field: string, value: string) => {
    if (field == 'initialHolder') {
      setInitialHolder(value)
    } else if (field == 'totalCycleSteps') {
      setTotalCycleSteps(_.toNumber(value))
    } else if (field == 'totalDaysPerCycle') {
      setTotalDaysPerCycle(_.toNumber(value))
    }
  }

  const handleArrayChange = (field: string, index: number, value: string) => {
    if (field == 'partnerDaysCount') {
      var partnerDaysCountArr = [...partnerDaysCount]
      partnerDaysCountArr[index] = _.toNumber(value)
      setPartnerDaysCount(partnerDaysCountArr)
    } else if (field == 'partnerShareAmounts') {
      var shareAmtsArr = [...partnerShareAmounts]
      shareAmtsArr[index] = _.toNumber(value)
      setPartnerShareAmounts(shareAmtsArr)
    } else if (field == 'partnerSharePercentages') {
      var sharePercsArr = [...partnerSharePercentages]
      sharePercsArr[index] = _.toNumber(value)
      setPartnerSharePercentages(sharePercsArr)
    }
  }

  const renderFormSteps = () => {
    if (currentStep == 1) {
      if (categoryType == 'timebased') {
        return (
          <Cycle
            propertyId={propertyId}
            categoryType={categoryType}
            initialHolder={initialHolder}
            totalCycleSteps={totalCycleSteps}
            totalDaysPerCycle={totalDaysPerCycle}

            nextStep={nextStep}
            handleTextChange={handleTextChange} />
        )
      } else if (categoryType == 'profitbased' || categoryType == 'others') {
        return (
          <NonTimeBasedCycle
            propertyId={propertyId}
            categoryType={categoryType}

            nextStep={nextStep}
            handleTextChange={handleTextChange} />
        )
      }
    } else if (currentStep == 2) {
      if (categoryType == 'timebased') {
        return (
          <Partners
            propertyId={propertyId}
            propertyName={propertyName}
            propertyAmount={propertyAmount}
            categoryType={categoryType}
            initialHolder={initialHolder}
            partnershipId={partnershipCycleDetails._id}
            partnerList={partnerList}
            partnerListError={approvedPersonsListError}
            partnerListSuccess={approvedPersonsListSuccess}
            partnerNames={partnerNames}
            partnerUserIds={partnerUserIds}
            partnerInterestIds={partnerInterestIds}
            partnerDaysCount={partnerDaysCount}
            partnerShareAmounts={partnerShareAmounts}
            partnerSharePercentages={partnerSharePercentages}
            totalDealDays={totalDealDays}
            totalDealPartners={totalDealPartners}
            totalDealPercentage={totalDealPercentage}

            prevStep={prevStep}
            nextStep={nextStep}
            handleArrayChange={handleArrayChange} />
        )
      } else if (categoryType == 'profitbased' || categoryType == 'others') {
        return (
          <NonTimeBasedPartners
            propertyName={propertyName}
            propertyAmount={propertyAmount}
            partnerList={partnerList}
            partnerListError={approvedPersonsListError}
            partnerListSuccess={approvedPersonsListSuccess}
            partnerInterestIds={partnerInterestIds}
            partnerShareAmounts={partnerShareAmounts}
            partnerSharePercentages={partnerSharePercentages}
            totalDealPartners={totalDealPartners}
            totalDealPercentage={totalDealPercentage}

            handleArrayChange={handleArrayChange}
            prevStep={prevStep}
            nextStep={nextStep} />
        )
      }
    } else if (currentStep == 3) {
      return (
        <Documents
          propertyId={propertyId}
          propertyName={propertyName}
          partnershipId={partnershipCycleDetails._id}

          prevStep={prevStep} />
      )
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Initiate Partnership</PageTitle>

      {renderFormSteps()}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    approvedPersonsList: state.goAdsReducer.approvedPersonsList,
    approvedPersonsListError: state.goAdsReducer.approvedPersonsListError,
    approvedPersonsListSuccess: state.goAdsReducer.approvedPersonsListSuccess,
    partnershipCycleDetails: state.partnershipReducer.partnershipCycleDetails,
    partnershipCycleDetailsError: state.partnershipReducer.partnershipCycleDetailsError,
    partnershipCycleDetailsSuccess: state.partnershipReducer.partnershipCycleDetailsSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GoAdsActions>) => {
  return {
    fetchApprovedPersonsList,
    fetchApprovedPersonsListError,
    fetchApprovedPersonsListSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TimeBasedInitProps = ConnectedProps<typeof connector>

export default connector(TimeBasedInit)
