// @ts-nocheck
import { FC, useEffect, useState }      from 'react'
import { Dispatch }                     from 'redux'
import { connect, ConnectedProps }      from 'react-redux'
import DatePicker, { DateObject }       from 'react-multi-date-picker'
import Icon                             from 'react-multi-date-picker/components/icon'
import DatePanel                        from 'react-multi-date-picker/plugins/date_panel'
import _                                from 'lodash'
import { serialize }                    from 'object-to-formdata'
import moment                           from 'moment'
import { RootState }                    from '../../../../../store'
import { useAppDispatch }               from '../../../../../store/hooks'
import {
  PartnershipActions,
  fetchPartnersAllottedDays,
  fetchPartnersAllottedDaysError,
  fetchPartnersAllottedDaysSuccess,
  fetchPartnershipUpdatedDays,
  submitPartnershipUpdatedDays,
  submitPartnershipUpdatedDaysError,
  submitPartnershipUpdatedDaysSuccess } from '../../../../../store/actions/go/partnership.actions'
import { showSnackbar1 }                from '../../../../../helpers/SnackbarHelper1'

const PartnersDaysConstants = {
  partnershipDaysUpdateErrorMsg: 'Error in updating partnership dates. Please try again',
  partnershipDaysUpdateSuccessMsg: 'Partnership dates updated successfully'
}

const PartnersDays: FC<PartnersDaysProps> = ({
  propertyTitle,
  partnershipId,
  showAllottedDaysModal,
  toggleAllottedDaysModal,
  partnersAllottedDays,
  partnersAllottedDaysError,
  partnersAllottedDaysSuccess,
  partnershipDaysUpdate,
  partnershipDaysUpdateError,
  partnershipDaysUpdateSuccess,
  fetchPartnersAllottedDays,
  fetchPartnersAllottedDaysError,
  fetchPartnersAllottedDaysSuccess,
  submitPartnershipUpdatedDays,
  submitPartnershipUpdatedDaysError,
  submitPartnershipUpdatedDaysSuccess,
  fetchPartnershipUpdatedDays
}) => {
  const dispatch = useAppDispatch()

  const [cycleStep, setCycleStep]                 = useState<number>(1)
  const [cycleSteps, setCycleSteps]               = useState<any[]>([])
  const [propertyName, setPropertyName]           = useState<string>('')
  const [selectedIndex, setSelectedIndex]         = useState<number>(0)
  const [selectedDates, setSelectedDates]         = useState<DateObject[]>([])
  const [partnerIds, setPartnerIds]               = useState<string[]>([])
  const [partners, setPartners]                   = useState<any[]>([])
  const [partnersDaysCount, setPartnersDaysCount] = useState<number[]>([])
  const [partnershipDates, setPartnershipDates]   = useState<string[]>([])

  useEffect(() => {
    dispatch(fetchPartnersAllottedDays(partnershipId))
  }, [])

  useEffect(() => {
    if (partnersAllottedDaysSuccess && partnersAllottedDays != null) {
      let { partners } = partnersAllottedDays
      let currentCyclePartners = _.find(partners, {'cycle': cycleStep})

      var partnershipDatesArr: string[] = []
      var partnerIdsArr: string[] = []

      _.forEach(partners, function(value, index) {
        partnershipDatesArr[index] = value.days
        partnerIdsArr[index] = value.user_id
      })

      setCycleSteps(partnersAllottedDays.cycle_steps)
      setPropertyName(partnersAllottedDays.property_name)
      setPartnerIds(partnerIdsArr)
      setPartners(currentCyclePartners.cycle_details)
      setPartnershipDates(partnershipDatesArr)
    }
  }, [partnersAllottedDays])

  useEffect(() => {
    if (partnershipDaysUpdateSuccess) {
      showSnackbar1(PartnersDaysConstants.partnershipDaysUpdateSuccessMsg)
      hidePartnershipDaysUpdateSuccessMsg()
    }
  }, [partnershipDaysUpdateSuccess])

  useEffect(() => {
    if (partnershipDaysUpdateError) {
      showSnackbar1(PartnersDaysConstants.partnershipDaysUpdateErrorMsg)
      hidePartnershipDaysUpdateErrorMsg()
    }
  }, [partnershipDaysUpdateError])

  const setPartnershipDatess = (dates: DateObject | DateObject[] | null) => {
    if (dates != null) {
      var filteredDates = _.map(dates, function(dateObject) {
        if (dateObject.isValid) {
          return dateObject.toString()
        }
      })

      var partnershipDatesArr = [...partnershipDates]
      partnershipDatesArr[selectedIndex] = filteredDates

      var partnerDayCountsArr = [...partnersDaysCount]    
      partnerDayCountsArr[selectedIndex] = filteredDates.length

      var partnersArr = [...partners]
      partnersArr[selectedIndex].days = partnershipDatesArr[selectedIndex]

      setPartners(partnersArr)
      setPartnershipDates(partnershipDatesArr)
      setPartnersDaysCount(partnerDayCountsArr)
    }
  }

  const handleOpenDatePicker = (index: number) => {
    setSelectedIndex(index)

    var partnershipDatess = [...partnershipDates]
    var dateObjectsStr = partnershipDatess[selectedIndex]

    if (typeof dateObjectsStr !== 'undefined') {
      var dateObjs = _.map(dateObjectsStr, function(dateObj) {
        let selectedDate = new Date(dateObj)
        return new DateObject(selectedDate)
      })

      setSelectedDates(dateObjs)
    }
  }

  const handleCloseDatePicker = () => {
    setSelectedDates([])
  }

  const handleDisabledDates = () => {
    let disabledDates = [...partnershipDates]
    var selectedIndexx = selectedIndex
    var activeDates = _.remove(disabledDates, function(n, i) {
      return i === selectedIndexx
    })

    let partnershipDatess = _.flatten(disabledDates)
    partnershipDatess = _.map(partnershipDatess, function(dateObj) {
      let selectedDate = new Date(dateObj)
      let actualDateobject = new DateObject(selectedDate)
      return actualDateobject.toString()
    })

    return partnershipDatess
  }

  const updatePartnerDays = () => {
    let formData1 = new FormData()
    var partnershipDays = partnershipDates
    var partnershipDaysLen = partnershipDates.length

    for (var i = 0; i < partnershipDaysLen; i++) {
      var l = partnershipDays[i].length
      var a = partnershipDays[i]

      for (var j = 0; j < l; j++) {
        formData1.append(`days[${i}][${j}]`, moment(new Date(a[j])).format('YYYY-MM-DD'))
      }
    }

    let model = {
      'cycle_step': cycleStep,
      'partner_id': partnerIds
    }

    const formData = serialize(model, '', formData1)
    dispatch(submitPartnershipUpdatedDays(formData, partnershipId))
  }

  const getUpdatedDays = () => {
    dispatch(fetchPartnershipUpdatedDays(partnershipId, cycleStep))
  }

  const hidePartnershipDaysUpdateErrorMsg = () => {
    dispatch(submitPartnershipUpdatedDaysError(false))
  }

  const hidePartnershipDaysUpdateSuccessMsg = () => {
    dispatch(submitPartnershipUpdatedDaysSuccess(false))
  }

  const populateAllottedDays = () => {
    return (
      partners.map((partner, index) => (
        <div className='row' key={index}>
          <div className='col-xl-4'>{partner.user_name}</div>
          <div className='col-xl-4'>
            <DatePicker
              value={selectedDates}
              format='DD MMM YYYY'
              render={<Icon/>}
              multiple
              onChange={(dates) => setPartnershipDatess(dates)}
              onOpen={() => handleOpenDatePicker(index)}
              onClose={() => handleCloseDatePicker()}
              plugins={[<DatePanel />]}
              mapDays={({ date }) => {
                let dateProps: any = {}
                let disabledDates = handleDisabledDates()
                // let selectedDate = new Date(date)
                // let selectedDateObject = new DateObject(selectedDate)

                if (disabledDates.includes(date.toString())) {
                  dateProps.title     = 'Taken'
                  dateProps.disabled  = true
                  dateProps.className = 'highlight highlight-red'
                }

                return dateProps
              }} />
          </div>
          <div className='col-xl-4'>{partner.days.length}</div>
        </div>
      ))
    )
  }

  return (
    <>
      <div
        className={'modal fade' + (showAllottedDaysModal ? ' show d-block' : '')}
        aria-hidden={showAllottedDaysModal ? 'false' : 'true'}
        aria-labelledby='modal-go-show-allotted-days'
        tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 id='modal-go-show-allotted-days'>Allotted Days</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={toggleAllottedDaysModal}>
                <i className='bi bi-x-circle-fill'></i>
              </div>
            </div>

            <div className='modal-body m-3 mx-xl-10'>
              <div className='row'>
                <div className='col-xl-12'>
                  <h5>{propertyTitle}</h5>
                </div>
                <div className='col-xl-4'>
                  <form noValidate id='form-go-update-allotted-days'>
                    {/*label_name='Cycle Steps'*/}
                    <select
                      name='cycleStep'
                      value={cycleStep}
                      data-control='select2'
                      data-hide-search='true'
                      onChange={(event) => setCycleStep(_.toNumber(event.target.value))}
                      className='form-select form-select-sm form-select-white'>
                      {
                        cycleSteps.length > 0 &&
                        cycleSteps.map(el =>
                          <option key={el} value={el}>{el}</option>
                        )
                      }
                    </select>
                  </form>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-4'>
                  <h5>Partner</h5>
                </div>
                <div className='col-xl-4'>
                  <h5>Days</h5>
                </div>
                <div className='col-xl-4'>
                  <h5>Total Days</h5>
                </div>

                {partners.length > 0 && populateAllottedDays()}
              </div>

              <div className='d-flex flex-stack'>
                <div className='me-3'>
                  <button
                    type='button'
                    onClick={updatePartnerDays}
                    className='btn btn-sm btn-secondary'>
                    Update
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    onClick={toggleAllottedDaysModal}
                    className='ms-2 btn btn-sm btn-secondary'>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        showAllottedDaysModal &&
        <div className='modal-backdrop fade show'></div>
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    propertyTitle: ownProps.propertyTitle,
    partnershipId: ownProps.partnershipId,
    showAllottedDaysModal: ownProps.showAllottedDaysModal,
    toggleAllottedDaysModal: ownProps.toggleAllottedDaysModal,
    partnersAllottedDays: state.partnershipReducer.partnersAllottedDays,
    partnersAllottedDaysError: state.partnershipReducer.partnersAllottedDaysError,
    partnersAllottedDaysSuccess: state.partnershipReducer.partnersAllottedDaysSuccess,
    partnershipDaysUpdate: state.partnershipReducer.partnershipDaysUpdate,
    partnershipDaysUpdateError: state.partnershipReducer.partnershipDaysUpdateError,
    partnershipDaysUpdateSuccess: state.partnershipReducer.partnershipDaysUpdateSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<PartnershipActions>) => {
  return {
    fetchPartnersAllottedDays,
    fetchPartnersAllottedDaysError,
    fetchPartnersAllottedDaysSuccess,
    fetchPartnershipUpdatedDays,
    submitPartnershipUpdatedDays,
    submitPartnershipUpdatedDaysError,
    submitPartnershipUpdatedDaysSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PartnersDaysProps = ConnectedProps<typeof connector>

export default connector(PartnersDays)
