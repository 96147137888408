import axios         from 'axios'
import AppConfig     from '../../../../appConfig'
import {AppDispatch} from '../../../store'

// *************** TYPES

export interface Farmer {
  name: string
  phone: string
  methodology: string
}

// *************** STATE

export interface FarmerState {
  farmersList: Farmer[]
  farmersFetchError: boolean
  farmersFetchSuccess: boolean
}

// *************** ACTION TYPES

export const FarmerActionTypes = {
  FARMERS_LIST         : 'FARMERS_LIST',
  FARMERS_LIST_ERROR   : 'FARMERS_LIST_ERROR',
  FARMERS_LIST_SUCCESS : 'FARMERS_LIST_SUCCESS'
} as const

// *************** ACTIONS

interface FetchFarmersListAction {
  type: typeof FarmerActionTypes.FARMERS_LIST;
  payload: Farmer[];
}

interface FetchFarmersListSuccessAction {
  type: typeof FarmerActionTypes.FARMERS_LIST_SUCCESS;
  payload: boolean;
}

interface FetchFarmersListFailureAction {
  type: typeof FarmerActionTypes.FARMERS_LIST_ERROR;
  payload: boolean;
}

export type FarmerActions =
  | FetchFarmersListAction
  | FetchFarmersListSuccessAction
  | FetchFarmersListFailureAction


// *************** API CALLS
/* FETCH FARMERS LIST */

export function fetchFarmersList() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_FARMERS_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: FarmerActionTypes.FARMERS_LIST,
        payload: response.data.farmerdetails
      })
      dispatch(fetchFarmersListSuccess(true))
    })
    .catch(error => {
      dispatch(fetchFarmersListError(true))
    })
  }
}
export function fetchFarmersListError(status: boolean) {
  return {
    type: FarmerActionTypes.FARMERS_LIST_ERROR,
    payload: status
  }
}
export function fetchFarmersListSuccess(status: boolean) {
  return {
    type: FarmerActionTypes.FARMERS_LIST_SUCCESS,
    payload: status
  }
}
