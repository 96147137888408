import { ChangeEvent, FC, useState} from 'react'
import { Dispatch }                 from 'redux'
import { connect, ConnectedProps }  from 'react-redux'
import { RootState }                from '../../../../../store'
import { useAppDispatch }           from '../../../../../store/hooks'
import {
  AdminSettingsActions,
  submitNewCategoryType,
  submitNewCategoryTypeError,
  submitNewCategoryTypeSuccess }    from '../../../../../store/actions/admin/settings.action'
import { showSnackbar }             from '../../../../../helpers/SnackbarHelper'

const AddCategoryTypeModalConstants = {
  categoryTypeSubmitErrorMsg: 'Failed to add Category Type. Please try again',
  categoryTypeSubmitSuccessMsg: 'Category Type added successfully'
}

const AddCategoryTypeModal: FC<AddCategoryTypeModalProps> = ({
  showCategoryTypeModal,
  toggleCategoryTypeModal,
  categoryTypeSubmitError,
  categoryTypeSubmitSuccess,
  submitNewCategoryType,
  submitNewCategoryTypeError,
  submitNewCategoryTypeSuccess
}) => {
  const dispatch = useAppDispatch()

  const [categoryType, setCategoryType] = useState<string>('')
  const [isFormValid, setIsFormValid]   = useState<boolean>(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let fieldValue = event.target.value
    setCategoryType(fieldValue)

    if (fieldValue === '') {
      setIsFormValid(false)
    } else {
      if (fieldValue.length < 3) {
        setIsFormValid(false)
      } else {
        setIsFormValid(true)
      }
    }
  }

  const handleFormValid = () => {
    setIsFormValid(true)
  }

  const handleFormInvalid = () => {
    setIsFormValid(false)
  }

  const handleFormSubmit = () => {
    let formData = new FormData()
    formData.append('type', categoryType)
    formData.append('status', '1')

    dispatch(submitNewCategoryType(formData))
    closeCategoryTypeModal()
  }

  const closeCategoryTypeModal = () => {
    setCategoryType('')
    toggleCategoryTypeModal()
  }

  const closeCategoryTypeSubmitSuccessmMsg = () => {
    dispatch(submitNewCategoryTypeSuccess(false))
  }

  const closeCategoryTypeSubmitErrorMsg = () => {
    dispatch(submitNewCategoryTypeError(false))
  }

  return (
    <>
      <div
        className={'modal fade' + (showCategoryTypeModal ? ' show d-block' : '')}
        aria-hidden={showCategoryTypeModal ? 'false' : 'true'}
        aria-labelledby='modal-admin-add-category-type'
        tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 id='modal-admin-add-category-type'>Add Category Type</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeCategoryTypeModal}>
                <i className="bi bi-x-circle-fill"></i>
              </div>
            </div>

            <div className='modal-body m-3 mx-xl-10'>
              <form noValidate id='form-admin-add-category-type'>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>New Category Type</span>
                  </label>
                  <input
                    type='text'
                    name='categoryType'
                    value={categoryType}
                    className='form-control form-control-lg form-control-solid'
                    autoComplete='off'
                    onChange={(e) => handleChange(e)} />

                  {
                    categoryType && categoryType.length < 3 &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>Minimum 3 letters required</div>
                    </div>
                  }
                </div>

                <div className='d-flex flex-stack'>
                  <div className='me-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary'
                      onClick={closeCategoryTypeModal}>Cancel</button>
                  </div>
                  <div>
                    <button
                      type='button'
                      disabled={!isFormValid}
                      className='btn btn-sm btn-primary'
                      onClick={handleFormSubmit}>Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {
        showCategoryTypeModal &&
        <div className="modal-backdrop fade show"></div>
      }

      {
        categoryTypeSubmitSuccess &&
        showSnackbar(AddCategoryTypeModalConstants.categoryTypeSubmitSuccessMsg, closeCategoryTypeSubmitSuccessmMsg)
      }
      {
        categoryTypeSubmitError &&
        showSnackbar(AddCategoryTypeModalConstants.categoryTypeSubmitErrorMsg, closeCategoryTypeSubmitErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    showCategoryTypeModal: ownProps.showCategoryTypeModal,
    toggleCategoryTypeModal: ownProps.toggleCategoryTypeModal,
    categoryTypeSubmitError: state.settingsReducer.categoryTypeSubmitError,
    categoryTypeSubmitSuccess: state.settingsReducer.categoryTypeSubmitSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AdminSettingsActions>) => {
  return {
    submitNewCategoryType,
    submitNewCategoryTypeError,
    submitNewCategoryTypeSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type AddCategoryTypeModalProps = ConnectedProps<typeof connector>

export default connector(AddCategoryTypeModal)
