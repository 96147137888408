import { Reducer } from 'react'
import {
  GoAdsActions,
  GoAdsActionTypes,
  GoAdsState }     from '../../actions/go/ads.actions'

const initialState: GoAdsState = {
  newAdsList: [],
  newAdsListError: false,
  newAdsListSuccess: false,
  adDetails: null,
  adDetailsError: false,
  adDetailsSuccess: false,
  goAdStatusError: false,
  goAdStatusSuccess: false,
  approvedAdsList: [],
  approvedAdsListError: false,
  approvedAdsListSuccess: false,
  applyOfferError: false,
  applyOfferSuccess: false,
  interestedAdsList: [],
  interestedAdsListError: false,
  interestedAdsListSuccess: false,
  interestedPersonsList: [],
  interestedPersonsListError: false,
  interestedPersonsListSuccess: false,
  interestedPersonsApproveStatusError: false,
  interestedPersonsApproveStatusSuccess: false,
  approvedPersonsAdsList: [],
  approvedPersonsAdsListError: false,
  approvedPersonsAdsListSuccess: false,
  approvedPersonsList: null,
  approvedPersonsListError: false,
  approvedPersonsListSuccess: false
}

const goAdsReducer: Reducer<GoAdsState, GoAdsActions> = (state = initialState, action) => {
  switch (action.type) {
    case GoAdsActionTypes.FETCH_NEW_ADS_LIST: {
      return {
        ...state,
        newAdsList: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_NEW_ADS_LIST_ERROR: {
      return {
        ...state,
        newAdsListError: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_NEW_ADS_LIST_SUCCESS: {
      return {
        ...state,
        newAdsListSuccess: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_AD_DETAILS: {
      return {
        ...state,
        adDetails: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_AD_DETAILS_ERROR: {
      return {
        ...state,
        adDetailsError: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_AD_DETAILS_SUCCESS: {
      return {
        ...state,
        adDetailsSuccess: action.payload
      }
    }
    case GoAdsActionTypes.UPDATE_GO_AD_STATUS_ERROR: {
      return {
        ...state,
        goAdStatusError: action.payload
      }
    }
    case GoAdsActionTypes.UPDATE_GO_AD_STATUS_SUCCESS: {
      return {
        ...state,
        goAdStatusSuccess: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_APPROVED_ADS_LIST: {
      return {
        ...state,
        approvedAdsList: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_APPROVED_ADS_LIST_ERROR: {
      return {
        ...state,
        approvedAdsListError: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_APPROVED_ADS_LIST_SUCCESS: {
      return {
        ...state,
        approvedAdsListSuccess: action.payload
      }
    }
    case GoAdsActionTypes.SUBMIT_APPLY_OFFER_ERROR: {
      return {
        ...state,
        applyOfferError: action.payload
      }
    }
    case GoAdsActionTypes.SUBMIT_APPLY_OFFER_SUCCESS: {
      return {
        ...state,
        applyOfferSuccess: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_INTERESTED_ADS_LIST: {
      return {
        ...state,
        interestedAdsList: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_INTERESTED_ADS_LIST_ERROR: {
      return {
        ...state,
        interestedAdsListError: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_INTERESTED_ADS_LIST_SUCCESS: {
      return {
        ...state,
        interestedAdsListSuccess: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_INTERESTED_PERSONS_LIST: {
      return {
        ...state,
        interestedPersonsList: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_INTERESTED_PERSONS_LIST_ERROR: {
      return {
        ...state,
        interestedPersonsListError: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_INTERESTED_PERSONS_LIST_SUCCESS: {
      return {
        ...state,
        interestedPersonsListSuccess: action.payload
      }
    }
    case GoAdsActionTypes.APPROVE_INTERESTED_PERSON_ERROR: {
      return {
        ...state,
        interestedPersonsApproveStatusError: action.payload
      }
    }
    case GoAdsActionTypes.APPROVE_INTERESTED_PERSON_SUCCESS: {
      return {
        ...state,
        interestedPersonsApproveStatusSuccess: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_APPROVED_PERSONS_ADS_LIST: {
      return {
        ...state,
        approvedPersonsAdsList: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_APPROVED_PERSONS_ADS_LIST_ERROR: {
      return {
        ...state,
        approvedPersonsAdsListError: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_APPROVED_PERSONS_ADS_LIST_SUCCESS: {
      return {
        ...state,
        approvedPersonsAdsListSuccess: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_APPROVED_PERSONS_LIST: {
      return {
        ...state,
        approvedPersonsList: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_APPROVED_PERSONS_LIST_ERROR: {
      return {
        ...state,
        approvedPersonsListError: action.payload
      }
    }
    case GoAdsActionTypes.FETCH_APPROVED_PERSONS_LIST_SUCCESS: {
      return {
        ...state,
        approvedPersonsListSuccess: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default goAdsReducer
