// @ts-nocheck
import { FC }             from 'react'
import { ColumnInstance } from 'react-table'
import { Category }       from '../../core/_models'

type CustomHeaderColumnProps = {
  column: ColumnInstance<Category>
}

const CustomHeaderColumn: FC<CustomHeaderColumnProps> = ({ column }) => (
  <>
    {
      column.Header && typeof column.Header === 'string' ? (
        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
      ) : (
        column.render('Header')
      )
    }
  </>
)

export { CustomHeaderColumn }
