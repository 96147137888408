// @ts-nocheck
import { FC, useEffect, useState } from 'react'
import { useParams }               from 'react-router-dom'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { KTCard }                  from '../../../../../_metronic/helpers'
import { PageLink, PageTitle }     from '../../../../../_metronic/layout/core'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  AdminSettingsActions,
  fetchSubcategories,
  fetchSubcategoriesError,
  fetchSubcategoriesSuccess }      from '../../../../store/actions/admin/settings.action'
import { showSnackbar }            from '../../../../helpers/SnackbarHelper'
import { ListHeader }              from './list/components/header/ListHeader'
import SubcategoriesTable          from './list/table/SubcategoriesTable'

const SubcategoriesConstants = {
  fetchSubcategoriesErrorMsg: 'Error in fetching subcategories. Please try again'
}

const breadcrumbs: Array<PageLink> = [{
  path: '/admin-category-list',
  title: 'Category List',
  isActive: false,
  isSeparator: false
}, {
  path: '',
  title: '',
  isActive: false,
  isSeparator: true
}]

const Subcategories: FC<SubcategoriesProps> = ({
  categoryName,
  subcategoriesFetchError,
  subcategoriesFetchSuccess,
  subcategorySubmitSuccess,
  fetchSubcategories,
  fetchSubcategoriesError,
  fetchSubcategoriesSuccess
}) => {
  const params   = useParams()
  const dispatch = useAppDispatch()

  const [categoryId, setCategoryId] = useState<string>('')

  useEffect(() => {
    if (params.id) {
      setCategoryId(params.id)
      dispatch(fetchSubcategories(params.id))
    }
  }, [])

  useEffect(() => {
    if (subcategorySubmitSuccess) {
      dispatch(fetchSubcategories(categoryId))
    }
  }, [subcategorySubmitSuccess])

  const closeFetchSubcategoriesErrorMsg = () => {
    dispatch(fetchSubcategoriesError(false))
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{`Subcategories [${categoryName.toUpperCase()}]`}</PageTitle>

      <KTCard>
        <ListHeader />
        <SubcategoriesTable />
      </KTCard>

      {
        subcategoriesFetchError &&
        showSnackbar(SubcategoriesConstants.fetchSubcategoriesErrorMsg, closeFetchSubcategoriesErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    categoryName: state.settingsReducer.categoryName,
    subcategoriesFetchError: state.settingsReducer.subcategoriesFetchError,
    subcategoriesFetchSuccess: state.settingsReducer.subcategoriesFetchSuccess,
    subcategorySubmitSuccess: state.settingsReducer.subcategorySubmitSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AdminSettingsActions>) => {
  return {
    fetchSubcategories,
    fetchSubcategoriesError,
    fetchSubcategoriesSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type SubcategoriesProps = ConnectedProps<typeof connector>

export default connector(Subcategories)
