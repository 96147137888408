// @ts-nocheck
import { FC }                      from 'react'
import { connect, ConnectedProps } from 'react-redux'
import moment                      from 'moment'
import { toAbsoluteUrl }           from '../../../../../_metronic/helpers'
import { RootState }               from '../../../../store'

const InterestedPerson: FC<InterestedPersonProps> = ({ person, approvePerson }) => {
  const renderStatusButton = (interestId: string, approvalStatus: string) => {
    if (approvalStatus == '0') {
      return (
        <button
          type='button'
          className='btn btn-primary' 
          onClick={() => approvePerson(interestId)}>
          APPROVE
        </button>
      )
    } else if (approvalStatus == '1') {
      return (
        <button
          type='button'
          disabled
          className='btn btn-light-primary'>
          APPROVED
        </button>
      )
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-50px me-5'>
              <img src={toAbsoluteUrl('/media/avatars/300-6.jpg')} className='rounded-circle' alt='user-avatar' />
            </div>
            <div className='flex-grow-1'>
              <h4 className='fw-bolder m-0 pb-1'>{person.user_name}</h4>
              <h6 className='fw-semibold text-muted'>{person.location}</h6>
            </div>
          </div>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='row mb-5'>
              <label className='col-lg-3 fw-bold text-muted'>Amount</label>
              <div className='col-lg-9'>
                <span className='fw-bold fs-6 text-dark'>{person.user_amount}</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-lg-3 fw-bold text-muted'>Share</label>
              <div className='col-lg-9'>
                <span className='fw-bold fs-6 text-dark'>{person.user_share}</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-lg-3 fw-bold text-muted'>Number of days</label>
              <div className='col-lg-9'>
                <span className='fw-bold fs-6 text-dark'>{person.user_days}</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-lg-3 fw-bold text-muted'>Email</label>
              <div className='col-lg-9'>
                <span className='fw-bold fs-6 text-dark'>{person.user_email}</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-lg-3 fw-bold text-muted'>Phone</label>
              <div className='col-lg-9'>
                <span className='fw-bold fs-6 text-dark'>{person.user_phone}</span>
              </div>
            </div>
            <div className='row mb-8'>
              <label className='col-lg-3 fw-bold text-muted'>Date</label>
              <div className='col-lg-9'>
                <span className='fw-bold fs-6 text-dark'>{moment(person.date).format('DD-MM-YYYY')}</span>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                {renderStatusButton(person.interest_id, person.approval_status)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    person: ownProps.person,
    approvePerson: ownProps.approvePerson
  }
}

const connector = connect(mapStateToProps, null)
type InterestedPersonProps = ConnectedProps<typeof connector>

export default connector(InterestedPerson)
