import { FC, useState }            from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState }               from '../../../../../store'
import PartnersDays                from './PartnersDays'

const AllottedDays: FC<AllottedDaysProps> = ({
  categoryType,
  propertyTitle,
  partnershipId
}) => {
  const [showAllottedDaysModal, setShowAllottedDaysModal] = useState<boolean>(false)

  const toggleAllottedDaysModal = () => {
    setShowAllottedDaysModal(!showAllottedDaysModal)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>PARTNERS ALLOTTED DAYS</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row'>
            <div className='col-xl-12'>
              <button
                onClick={toggleAllottedDaysModal}
                className='btn btn-sm btn-secondary'>
                View Partner Days
              </button>
            </div>
          </div>
        </div>
      </div>

      {
        showAllottedDaysModal &&
        <PartnersDays
          propertyTitle={propertyTitle}
          partnershipId={partnershipId}
          showAllottedDaysModal={showAllottedDaysModal}
          toggleAllottedDaysModal={toggleAllottedDaysModal} />
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    categoryType: ownProps.categoryType,
    propertyTitle: ownProps.propertyTitle,
    partnershipId: ownProps.partnershipId
  }
}

const connector = connect(mapStateToProps, null)
type AllottedDaysProps = ConnectedProps<typeof connector>

export default connector(AllottedDays)
