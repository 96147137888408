// @ts-nocheck
import { FC, FormEvent }                 from 'react'
import { useEffect, useRef, useState }   from 'react'
import { Dispatch }                      from 'redux'
import { connect, ConnectedProps }       from 'react-redux'
import DatePicker, { DateObject }        from 'react-multi-date-picker'
import Icon                              from 'react-multi-date-picker/components/icon'
import DatePanel                         from 'react-multi-date-picker/plugins/date_panel'
import _                                 from 'lodash'
import { serialize }                     from 'object-to-formdata'
import moment                            from 'moment'
import { RootState }                     from '../../../../../store'
import { useAppDispatch }                from '../../../../../store/hooks'
import {
  PartnershipActions,
  submitPartnershipShareDetails,
  submitPartnershipShareDetailsError,
  submitPartnershipShareDetailsSuccess } from '../../../../../store/actions/go/partnership.actions'
import { showSnackbar1 }                 from '../../../../../helpers/SnackbarHelper1'

const PartnersConstants = {
  partnershipSaveErrorMsg: 'Error in saving partnership data. Please try again',
  partnershipSaveSuccessMsg: 'Saved partnership data successfully'
}

const Partners: FC<PartnersProps> = ({
  propertyId,
  propertyName,
  propertyAmount,
  categoryType,
  initialHolder,
  partnershipId,
  partnerList,
  partnerListError,
  partnerListSuccess,
  partnerNames,
  partnerUserIds,
  partnerInterestIds,
  partnerDaysCount,
  partnerShareAmounts,
  partnerSharePercentages,
  totalDealDays,
  totalDealPartners,
  totalDealPercentage,
  prevStep,
  nextStep,
  handleArrayChange,
  partnershipShareDetailsError,
  partnershipShareDetailsSuccess,
  submitPartnershipShareDetails,
  submitPartnershipShareDetailsError,
  submitPartnershipShareDetailsSuccess
}) => {
  const formRef  = useRef<HTMLFormElement | null>(null)
  const dispatch = useAppDispatch()

  const [isFormValid, setIsFormValid]           = useState<boolean>(false)
  const [partnershipDates, setPartnershipDates] = useState<string[]>([])
  const [selectedIndex, setSelectedIndex]       = useState<number>(-1)
  const [selectedDates, setSelectedDates]       = useState<DateObject[]>([])

  useEffect(() => {
    if (partnershipShareDetailsSuccess) {
      showSnackbar1(PartnersConstants.partnershipSaveSuccessMsg)
      hidePartnershipSaveSuccessMsg()
    }
  }, [partnershipShareDetailsSuccess])

  useEffect(() => {
    if (partnershipShareDetailsError) {
      showSnackbar1(PartnersConstants.partnershipSaveErrorMsg)
      hidePartnershipSaveErrorMsg()
    }
  }, [partnershipShareDetailsError])

  const setPartnershipDatess = (dates: DateObject | DateObject[] | null) => {
    if (dates != null) {
      var filteredDates = _.map(dates, function(dateObject) {
        if (dateObject.isValid) {
          return dateObject.toString()
        }
      })

      var partnershipDatesArr = [...partnershipDates]
      partnershipDatesArr[selectedIndex] = filteredDates

      handleArrayChange('partnerDaysCount', selectedIndex, filteredDates.length)
      setPartnershipDates(partnershipDatesArr)
    }
  }

  const handleOpenDatePicker = (index: number) => {
    setSelectedIndex(index)

    var partnershipDatesArr = [...partnershipDates]
    var dateObjectsStr = partnershipDatesArr[selectedIndex]

    if (typeof dateObjectsStr !== 'undefined') {
      var dateObjs = _.map(dateObjectsStr, function(dateObj) {
        let $date = new Date(dateObj)
        return new DateObject($date)
      })

      setSelectedDates(dateObjs)
    }
  }

  const handleCloseDatePicker = () => {
    setSelectedIndex(-1)
    setSelectedDates([])
  }

  const handleDisabledDates = () => {
    var disabledDates = [...partnershipDates]
    var activeDates = _.remove(disabledDates, function(n, i) {
      return i === selectedIndex
    })

    var partnershipDatess = _.flatten(disabledDates)
    partnershipDatess = _.map(partnershipDatess, function(dateObj) {
      let $date = new Date(dateObj)
      let actualDateObject = new DateObject($date)
      return actualDateObject.toString()
    })

    return partnershipDatess
  }

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    savePartnershipShareDetails()
  }

  const savePartnershipShareDetails = () => {
    var model = {
      'user_name': partnerNames,
      'partner_id': partnerUserIds,
      'property_holder': initialHolder,
      'user_share_amount': partnerShareAmounts,
      'user_share_percentage': partnerSharePercentages
    }

    var formData1 = new FormData()
    var partnershipDaysLen = partnershipDates.length

    for (var i = 0; i < partnershipDaysLen; i++) {
      var l = partnershipDates[i].length
      var a = partnershipDates[i]

      for (var j = 0; j < l; j++) {
        formData1.append(`user_share_days[${i}][${j}]`, moment(new Date(a[j])).format('YYYY-MM-DD'))
      }
    }

    const formData2 = serialize(model, '', formData1)

    dispatch(submitPartnershipShareDetails(formData2, propertyId, partnershipId, categoryType))
  }

  const hidePartnershipSaveErrorMsg = () => {
    dispatch(submitPartnershipShareDetailsError(false))
  }

  const hidePartnershipSaveSuccessMsg = () => {
    dispatch(submitPartnershipShareDetailsSuccess(false))
  }

  const populatePartnerDetails = () => {
    return (
      <div className='table-responsive'>
        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th>Name</th>
              <th>Share Percentage</th>
              <th className='text-center'>Days</th>
              <th>Days Count</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
          {
            partnerList !== null &&
            partnerList.map((row, index) => (
              <tr key={index} tabIndex={-1}>
                <td className='text-dark fw-bold text-hover-primary fs-6'>
                  {row.name}
                  <input
                    type='hidden'
                    name={`partnerInterestIds[${index}]`}
                    value={partnerInterestIds.length > 0 ? partnerInterestIds[index] : ''} />
                  <input
                    type='hidden'
                    name={`partnerUserIds[${index}]`}
                    value={partnerUserIds.length > 0 ? partnerUserIds[index] : ''} />
                </td>
                <td>
                  <input
                    type='text'
                    required
                    name={`partnerSharePercentages[${index}]`}
                    value={partnerSharePercentages[index]}
                    className='form-control form-control-sm'
                    onChange={(event) => handleArrayChange('partnerSharePercentages', index, event.target.value)} />
                </td>
                <td className='text-center'>
                  <DatePicker
                    value={selectedDates}
                    format='DD MMM YYYY'
                    render={<Icon />}
                    multiple
                    onChange={(dates) => setPartnershipDatess(dates)}
                    onOpen={() => handleOpenDatePicker(index)}
                    onClose={() => handleCloseDatePicker()}
                    plugins={[<DatePanel />]}
                    mapDays={({ date }) => {
                      let dateProps: any = {}
                      let disabledDates = handleDisabledDates()
                      let selectedDateObject = new DateObject(date)

                      if (disabledDates.includes(selectedDateObject.toString())) {
                        dateProps.title     = 'Taken'
                        dateProps.disabled  = true
                        dateProps.className = 'highlight highlight-red'
                      }

                      return dateProps
                    }} />
                </td>
                <td>
                  <input
                    type='text'
                    disabled
                    name={`partnerDaysCount[${index}]`}
                    value={partnerDaysCount[index]}
                    className='form-control form-control-sm' />
                </td>
                <td>
                  <input
                    type='text'
                    required
                    name={`partnerShareAmounts[${index}]`}
                    value={partnerShareAmounts[index]}
                    className='form-control form-control-sm'
                    onChange={(event) => handleArrayChange('partnerShareAmounts', index, event.target.value)} />
                </td>
              </tr>
            ))
          }
          </tbody>
          <tfoot>
            <tr className='fw-bold text-muted'>
              <td>Total Partners : {totalDealPartners}</td>
              <td>Total Percentage : {totalDealPercentage}</td>
              <td></td>
              <td>Total days : {totalDealDays}</td>
              <td>Total share amount : {propertyAmount}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }

  return (
    <div className='card'>
      <form
        ref={formRef}
        onSubmit={handleFormSubmit}>
        <div className='card-body'>
          <div className='pb-10 pb-lg-10'>
            <h2 className='fw-bolder text-dark'>Partnership Details</h2>
          </div>

          <div className='row mb-10'>
            <div className='col-xl-4'>
              <label className='form-label mb-3'>Property Name</label>
              <input
                type='text'
                name='propertyName'
                value={propertyName.toUpperCase()}
                disabled
                className='form-control form-control-sm' />
            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-xl-12'>
            {
              partnerList.length > 0
                ? populatePartnerDetails()
                : <div>Partners not available</div>
            }
            </div>
          </div>
        </div>

        <div className='card-footer'>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              onClick={prevStep}
              className='btn btn-secondary'>
              <i className="bi bi-chevron-double-left pe-0"></i> Prev
            </button>
            <button
              type='submit'
              className='ms-2 btn btn-primary'>
              Save <i className="bi bi-save2-fill pe-0"></i>
            </button>
            <button
              type='button'
              onClick={nextStep}
              className='ms-2 btn btn-secondary'>
              Next <i className="bi bi-chevron-double-right pe-0"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    propertyId: ownProps.propertyId,
    propertyName: ownProps.propertyName,
    propertyAmount: ownProps.propertyAmount,
    categoryType: ownProps.categoryType,
    initialHolder: ownProps.initialHolder,
    partnershipId: ownProps.partnershipId,
    partnerList: ownProps.partnerList,
    partnerListError: ownProps.partnerListError,
    partnerListSuccess: ownProps.partnerListSuccess,
    partnerNames: ownProps.partnerNames,
    partnerUserIds: ownProps.partnerUserIds,
    partnerInterestIds: ownProps.partnerInterestIds,
    partnerDaysCount: ownProps.partnerDaysCount,
    partnerShareAmounts: ownProps.partnerShareAmounts,
    partnerSharePercentages: ownProps.partnerSharePercentages,
    totalDealDays: ownProps.totalDealDays,
    totalDealPartners: ownProps.totalDealPartners,
    totalDealPercentage: ownProps.totalDealPercentage,
    prevStep: ownProps.prevStep,
    nextStep: ownProps.nextStep,
    handleArrayChange: ownProps.handleArrayChange,
    partnershipShareDetailsError: state.partnershipReducer.partnershipShareDetailsError,
    partnershipShareDetailsSuccess: state.partnershipReducer.partnershipShareDetailsSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<PartnershipActions>) => {
  return {
    submitPartnershipShareDetails,
    submitPartnershipShareDetailsError,
    submitPartnershipShareDetailsSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PartnersProps = ConnectedProps<typeof connector>

export default connector(Partners)
