import { FC, useEffect, useState }      from 'react'
import { Dispatch }                     from 'redux'
import { connect, ConnectedProps }      from 'react-redux'
import { AdapterDateFns }               from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker }                   from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider }         from '@mui/x-date-pickers/LocalizationProvider'
import moment                           from 'moment'
import { RootState }                    from '../../../../store'
import { useAppDispatch }               from '../../../../store/hooks'
import {
  PartnershipActions,
  submitDropPartnershipRequest,
  submitDropPartnershipRequestError,
  submitDropPartnershipRequestSuccess } from '../../../../store/actions/go/partnership.actions'
import { showSnackbar1 }                from '../../../../helpers/SnackbarHelper1'

const DropPartnershipModalConstants = {
  dropPartnershipRequestErrorMsg: 'Deal dropping error occured. Please try again',
  dropPartnershipRequestSuccessMsg: 'Deal dropping request successfully sent'
}

const DropPartnershipModal: FC<DropPartnershipModalProps> = ({
  partnershipId,
  showDropPartnershipModal,
  closeDropPartnershipModal,
  dropPartnershipRequestError,
  dropPartnershipRequestSuccess,
  submitDropPartnershipRequest,
  submitDropPartnershipRequestError,
  submitDropPartnershipRequestSuccess
}) => {
  const dispatch = useAppDispatch()

  const [isFormValid, setIsFormValid]   = useState<boolean>(false)
  const [dropReqDate, setDropReqDate]   = useState<Date>(new Date())
  const [dropReqNote, setDropReqNote]   = useState<string>('')
  const [dropReqTitle, setDropReqTitle] = useState<string>('')

  useEffect(() => {
    if (dropPartnershipRequestSuccess) {
      showSnackbar1(DropPartnershipModalConstants.dropPartnershipRequestSuccessMsg)
      closeDropPartnershipRequestSuccessMsg()
      setTimeout(() => {
        closeDropPartnershipModal()
      }, 2000)
    }
  }, [dropPartnershipRequestSuccess])

  useEffect(() => {
    if (dropPartnershipRequestError) {
      showSnackbar1(DropPartnershipModalConstants.dropPartnershipRequestErrorMsg)
      closeDropPartnershipRequestErrorMsg()
    }
  }, [dropPartnershipRequestError])

  const setDropPartnershipRequestDate = (newDate: Date | null) => {
    if (newDate != null) {
      setDropReqDate(newDate)
    }
  }

  const handleFormValid = () => {
    setIsFormValid(true)
  }

  const handleFormInValid = () => {
    setIsFormValid(false)
  }

  const handleFormSubmit = () => {
    var dropRequestDate = moment(new Date(dropReqDate)).format('YYYY-MM-DD')

    var formData = new FormData()
    formData.append('date', dropRequestDate)
    formData.append('note', dropReqNote)
    formData.append('title', dropReqTitle)

    dispatch(submitDropPartnershipRequest(formData, partnershipId))
  }

  const closeDropPartnershipRequestSuccessMsg = () => {
    dispatch(submitDropPartnershipRequestSuccess(false))
  }

  const closeDropPartnershipRequestErrorMsg = () => {
    dispatch(submitDropPartnershipRequestError(false))
  }

  return (
    <>
      <div
        className={'modal fade' + (showDropPartnershipModal ? ' show d-block' : '')}
        aria-hidden={showDropPartnershipModal ? 'false' : 'true'}
        aria-labelledby='modal-go-drop-partnership-request'
        tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 id='modal-go-drop-partnership-request'>Drop Partnership Request</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeDropPartnershipModal}>
                <i className='bi bi-x-circle-fill'></i>
              </div>
            </div>

            <div className='modal-body m-3 mx-xl-10'>
              <form noValidate id='form-go-drop-partnership-request'>
                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Title</span>
                  </label>
                  <input
                    type='text'
                    name='dropReqTitle'
                    value={dropReqTitle}
                    required
                    className='form-control form-control-lg form-control-solid'
                    onChange={(event) => setDropReqTitle(event.target.value)} />

                  {
                    !dropReqTitle && isFormValid &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>Title is required</div>
                    </div>
                  }
                </div>

                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Note</span>
                  </label>
                  {/*<input rows='5' />*/}
                  <input
                    type='text'
                    name='dropReqNote'
                    value={dropReqNote}
                    required
                    className='form-control form-control-lg form-control-solid'
                    onChange={(event) => setDropReqNote(event.target.value)} />

                  {
                    !dropReqNote && isFormValid &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>Note is required</div>
                    </div>
                  }
                </div>

                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Date</span>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Date'
                      value={dropReqDate}
                      inputFormat='dd-MM-yyyy'
                      minDate={new Date()}
                      onChange={(newDate) => setDropPartnershipRequestDate(newDate)}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <div className='d-flex align-items-center'>
                          <input ref={inputRef} className='form-control form-control-sm' {...inputProps} />
                          {InputProps?.endAdornment}
                        </div>
                      )} />
                  </LocalizationProvider>
                </div>

                <div className='d-flex flex-stack'>
                  <div className='me-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary'
                      onClick={closeDropPartnershipModal}>Cancel</button>
                  </div>
                  <div>
                    <button
                      type='button'
                      disabled={!isFormValid}
                      className='btn btn-sm btn-primary'
                      onClick={handleFormSubmit}>Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {
        showDropPartnershipModal &&
        <div className='modal-backdrop fade show'></div>
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    partnershipId: ownProps.partnershipId,
    showDropPartnershipModal: ownProps.showDropPartnershipModal,
    closeDropPartnershipModal: ownProps.closeDropPartnershipModal,
    dropPartnershipRequestError: state.partnershipReducer.dropPartnershipRequestError,
    dropPartnershipRequestSuccess: state.partnershipReducer.dropPartnershipRequestSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<PartnershipActions>) => {
  return {
    submitDropPartnershipRequest,
    submitDropPartnershipRequestError,
    submitDropPartnershipRequestSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type DropPartnershipModalProps = ConnectedProps<typeof connector>

export default connector(DropPartnershipModal)
