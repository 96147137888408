import { useState }        from 'react'
import { useParams }       from 'react-router-dom'
import AddSubcategoryModal from '../../../modal/AddSubcategoryModal'

const ListToolbar = () => {
  const { id } = useParams()
  const [showSubcategoryModal, setShowSubcategoryModal] = useState<boolean>(false)

  const toggleSubcategoryModal = () => {
    setShowSubcategoryModal(!showSubcategoryModal)
  }

  return (
    <>
      <div className='d-flex justify-content-end'>
        <button type='button' className='btn btn-sm btn-primary' onClick={toggleSubcategoryModal}>
          <i className="bi bi-plus-circle-fill"></i> Add Subcategory
        </button>
      </div>

      <AddSubcategoryModal
        categoryId={id}
        showSubcategoryModal={showSubcategoryModal}
        toggleSubcategoryModal={toggleSubcategoryModal} />
    </>
  )
}

export { ListToolbar }
