import { FC, useEffect, useState } from 'react'
import { ChangeEvent, MouseEvent } from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate }             from 'react-router-dom'
import debounce                    from 'lodash/debounce'
import orderBy                     from 'lodash/orderBy'
import { KTCard, KTCardBody }      from '../../../../../_metronic/helpers'
import { PageTitle }               from '../../../../../_metronic/layout/core'
import { ListLoading }             from '../../../../components/ListLoading'
import ListPagination              from '../../../../components/ListPagination'
import { showSnackbar }            from '../../../../helpers/SnackbarHelper'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  InterestedAd,
  GoAdsActions,
  fetchInterestedAdsList,
  fetchInterestedAdsListError,
  fetchInterestedAdsListSuccess }  from '../../../../store/actions/go/ads.actions'

const InterestedAdsConstants = {
  interestedAdsListErrorMsg: 'Error in fetching interested ads. Please try again'
}

const InterestedAds: FC<InterestedAdsProps> = ({
  interestedAdsList,
  interestedAdsListError,
  interestedAdsListSuccess,
  fetchInterestedAdsList,
  fetchInterestedAdsListError,
  fetchInterestedAdsListSuccess
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading]         = useState<boolean>(false)
  const [columnToQuery, setColumnToQuery] = useState<string>('title')
  const [adsList, setAdsList]             = useState<InterestedAd[]>([])
  const [page, setPage]                   = useState<number>(0)
  const [rowsPerPage, setRowsPerPage]     = useState<number>(5)
  const [searchTerm, setSearchTerm]       = useState<string>('')

  useEffect(() => {
    dispatch(fetchInterestedAdsList())
    setAdsList(interestedAdsList)
  }, [])

  useEffect(() => {
    if (searchTerm != '') {
      filterData()
    }
  }, [searchTerm, columnToQuery])

  useEffect(() => {
    if (searchTerm == '') {
      setAdsList(interestedAdsList)
    }
  }, [searchTerm, interestedAdsList])

  const filterData = () => {
    var debouncedSearch = debounce(() => {
      let currentList = [...adsList]
      let orderedList = orderBy(
        currentList.filter((x) =>
          x[columnToQuery as keyof InterestedAd]
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ),
        columnToQuery, 'asc'
      )

      setAdsList(orderedList)
    }, 1000)

    debouncedSearch()
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const closeInterestedAdsListErrorMsg = () => {
    dispatch(fetchInterestedAdsListError(false))
  }

  const renderActions = (propertyDetailsId: string, interestCount: number) => {
    return (
      <button
        type='button'
        title='View Interested Persons'
        onClick={() => viewInterestedPersons(propertyDetailsId, interestCount)}
        className='btn btn-sm btn-light btn-active-light-primary position-relative'>
        <i className="bi bi-people-fill pe-0"></i>
        <span className="position-absolute top-0 start-100 translate-middle badge badge-circle bg-danger">
          {interestCount}
        </span>
      </button>
    )
  }

  const viewInterestedPersons = (propertyDetailsId: string, interestCount: number) => {
    if (interestCount > 0) {
      navigate(`/intrested-persons-go-list/${propertyDetailsId}`)
    }
  }

  const populateInterestedAdsList = () => {
    return (
      rowsPerPage > 0
        ? adsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : adsList
    ).map((row, index) => (
      <tr role='row' key={index}>
        <td role='cell'>
          <div>{(page + 1 - 1) * rowsPerPage + (index + 1)}</div>
        </td>
        <td role='cell'>{row.title}</td>
        <td role='cell'>{row.location_name}</td>
        <td role='cell'>{row.amount}</td>
        <td role='cell' className='text-center min-w-50px'>{renderActions(row.property_details_id, row.interest_count)}</td>
      </tr>
    ))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Interested Ads</PageTitle>

      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <select
              data-control='select2'
              data-hide-search='true'
              value={columnToQuery}
              onChange={(event) => setColumnToQuery(event.target.value)}
              className='form-select form-select-sm form-select-solid'>
              <option value='title'>Title</option>
              <option value='location-name'>Location</option>
              <option value='amount'>Amount</option>
            </select>
            <div className='d-flex align-items-center position-relative my-1 ms-3'>
              <i className='bi bi-search position-absolute ms-3'></i>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid w-250px ps-10'
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)} />
            </div>
          </div>
        </div>

        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='aanshik-go-interested-ads'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th role='columnheader' className='min-w-50px'>#</th>
                  <th role='columnheader' className='min-w-125px'>Title</th>
                  <th role='columnheader'>Location</th>
                  <th role='columnheader'>Amount</th>
                  <th role='columnheader' className='min-w-50px'>Interested Persons</th>
                </tr>
              </thead>
              <tbody className='text-gray-600' role='rowgroup'>
              {
                adsList.length > 0 ? populateInterestedAdsList() : (
                  <tr>
                    <td colSpan={5}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )
              }
              </tbody>
              <tfoot>
                <tr>
                  <ListPagination
                    page={page}
                    count={interestedAdsList.length}
                    colSpan={5}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} />
                </tr>
              </tfoot>
            </table>
          </div>

          {isLoading && <ListLoading />}
        </KTCardBody>
      </KTCard>

      {
        interestedAdsListError &&
        showSnackbar(InterestedAdsConstants.interestedAdsListErrorMsg, closeInterestedAdsListErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    interestedAdsList: state.goAdsReducer.interestedAdsList,
    interestedAdsListError: state.goAdsReducer.interestedAdsListError,
    interestedAdsListSuccess: state.goAdsReducer.interestedAdsListSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GoAdsActions>) => {
  return {
    fetchInterestedAdsList,
    fetchInterestedAdsListError,
    fetchInterestedAdsListSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type InterestedAdsProps = ConnectedProps<typeof connector>

export default connector(InterestedAds)
