import { FC } from 'react'
import moment from 'moment'

type SelectedDaysDetailsProps = {
  data: string[]
}

const SelectedDaysDetails: FC<SelectedDaysDetailsProps> = ({ data }) => {
  const generateDateString = () => {
    let formattedDays = data.map(function(date) {
      return moment(new Date(date)).format('DD-MM-YYYY')
    }).join(', ')

    return formattedDays
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>USER SELECTED DATES</h3>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Selected Days</label>
              <div className='col-lg-8'>
                <span className='fw-bold fs-6 text-dark'>{generateDateString()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectedDaysDetails
