import { FC, useEffect, useState }          from 'react'
import { Dispatch }                         from 'redux'
import { connect, ConnectedProps }          from 'react-redux'
import moment                               from 'moment'
import { AdapterDateFns }                   from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker }                       from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider }             from '@mui/x-date-pickers/LocalizationProvider'
import DropzoneWrapper                      from '../../../../../components/DropzoneWrapper'
import { showSnackbar1 }                    from '../../../../../helpers/SnackbarHelper1'
import { RootState }                        from '../../../../../store'
import { useAppDispatch }                   from '../../../../../store/hooks'
import {
  PartnershipActions,
  approvePartnershipRelieveRequest,
  approvePartnershipRelieveRequestError,
  approvePartnershipRelieveRequestSuccess } from '../../../../../store/actions/go/partnership.actions'

// TODO: SHOW VALIDATION MESSAGES (PER FIELD)

const ApproveRelieveRequestModalConstants = {
  fileRequiredMsg: 'Minimum 1 file is required',
  partnershipRelieveRequestErrorMsg: 'Unable to approve relieve request. Please try again',
  partnershipRelieveRequestSuccessMsg: 'Approved relieve request successfully'
}

const ApproveRelieveRequestModal: FC<ApproveRelieveRequestModalProps> = ({
  partnershipId,
  partnerId,
  showApproveRelieveRequestModal,
  toggleApproveRelieveRequestModal,
  partnershipRelieveRequestError,
  partnershipRelieveRequestSuccess,
  approvePartnershipRelieveRequest,
  approvePartnershipRelieveRequestError,
  approvePartnershipRelieveRequestSuccess
}) => {
  const dispatch = useAppDispatch()

  const [isFormValid, setIsFormValid]             = useState<boolean>(false)
  const [relieveDate, setRelieveDate]             = useState<Date>(new Date())
  const [relieveReason, setRelieveReason]         = useState<string>('')
  const [relieveStatus, setRelieveStatus]         = useState<string>('')
  const [relieveDocument, setRelieveDocument]     = useState<File[]>([])
  const [fileRequiredError, setFileRequiredError] = useState<boolean>(false)

  useEffect(() => {
    if (partnershipRelieveRequestSuccess) {
      showSnackbar1(ApproveRelieveRequestModalConstants.partnershipRelieveRequestSuccessMsg)
      closePartnershipRelieveRequestSuccessMsg()
      setTimeout(() => {
        toggleApproveRelieveRequestModal()
      }, 2000)
    }
  }, [partnershipRelieveRequestSuccess])

  useEffect(() => {
    if (partnershipRelieveRequestError) {
      showSnackbar1(ApproveRelieveRequestModalConstants.partnershipRelieveRequestErrorMsg)
      closePartnershipRelieveRequestErrorMsg()
    }
  }, [partnershipRelieveRequestError])

  useEffect(() => {
    if (fileRequiredError) {
      showSnackbar1(ApproveRelieveRequestModalConstants.fileRequiredMsg)
      setFileRequiredError(false)
    }
  }, [fileRequiredError])

  const setPartnershipRelieveDate = (newDate: Date | null) => {
    if (newDate != null) {
      setRelieveDate(newDate)
    }
  }

  const setPartnershipRelieveReason = (reason: string) => {
    var strLength = reason.length
    if (strLength < 5) {
      // 'Minimum 5 characters'
      return false
    } else if (strLength > 50) {
      // 'Maximum 50 characters'
      return false
    }

    setRelieveReason(reason)
  }

  const setPartnershipRelieveStatus = (status: string) => {
    if (status == '-1') {
      // 'Select status'
      return false
    }

    setRelieveStatus(status)
  }

  const setPartnershipRelieveDocument = (relieveDoc: File[]) => {
    setRelieveDocument(relieveDoc)
  }

  const handleFormSubmit = () => {
    var fileCount = relieveDocument.length
    if (fileCount == 0) {
      setFileRequiredError(true)
      return false
    }

    var partnershipRelieveDate = moment(new Date(relieveDate)).format('YYYY-MM-DD')

    var formData = new FormData()
    formData.append('date', partnershipRelieveDate)
    formData.append('reason', relieveReason)
    formData.append('relieving_status', relieveStatus)
    relieveDocument.forEach((relieveDoc, index) => {
      formData.append('relieve_document', relieveDoc)
    })

    dispatch(approvePartnershipRelieveRequest(formData, partnershipId, partnerId))
  }

  const closePartnershipRelieveRequestSuccessMsg = () => {
    dispatch(approvePartnershipRelieveRequestSuccess(false))
  }

  const closePartnershipRelieveRequestErrorMsg = () => {
    dispatch(approvePartnershipRelieveRequestError(false))
  }

  return (
    <>
      <div
        className={'modal fade' + (showApproveRelieveRequestModal ? ' show d-block' : '')}
        aria-hidden={showApproveRelieveRequestModal ? 'false' : 'true'}
        aria-labelledby='modal-go-relieve-partnership-request'
        tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 id='modal-go-relieve-partnership-request'>Approve Requests</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={toggleApproveRelieveRequestModal}>
                <i className='bi bi-x-circle-fill'></i>
              </div>
            </div>

            <div className='modal-body m-3 mx-xl-10'>
              <form noValidate id='form-go-relieve-partnership-request'>
                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Date</span>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={relieveDate}
                      inputFormat='dd-MM-yyyy'
                      minDate={new Date()}
                      onChange={(newDate) => setPartnershipRelieveDate(newDate)}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <div className='d-flex align-items-center'>
                          <input ref={inputRef} className='form-control form-control-sm' {...inputProps} />
                          {InputProps?.endAdornment}
                        </div>
                      )} />
                  </LocalizationProvider>
                </div>

                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Reason</span>
                  </label>
                  <input
                    type='text'
                    name='relieveReason'
                    value={relieveReason}
                    required
                    className='form-control form-control-lg form-control-solid'
                    onChange={(event) => setPartnershipRelieveReason(event.target.value)} />
                </div>

                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Status</span>
                  </label>
                  <select
                    name='relieveStatus'
                    data-control='select2'
                    data-hide-search='true'
                    onChange={(event) => setPartnershipRelieveStatus(event.target.value)}
                    className='form-select form-select-lg form-select-solid'>
                    <option value='-1' selected>Select</option>
                    <option value='0'>Decline</option>
                    <option value='1'>Approve</option>
                  </select>
                </div>

                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Document</span>
                  </label>
                  <DropzoneWrapper
                    filesLimit={1}
                    handleOnAdd={setPartnershipRelieveDocument}
                    dropzoneText='Upload Document'
                    acceptedFileTypes='documents' />
                </div>

                <div className='d-flex flex-stack'>
                  <div className='me-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary'
                      onClick={toggleApproveRelieveRequestModal}>Cancel</button>
                  </div>
                  <div>
                    <button
                      type='button'
                      disabled={!isFormValid}
                      className='btn btn-sm btn-primary'
                      onClick={handleFormSubmit}>Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {
        showApproveRelieveRequestModal &&
        <div className='modal-backdrop fade show'></div>
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    partnershipId: ownProps.partnershipId,
    partnerId: ownProps.partnerId,
    showApproveRelieveRequestModal: ownProps.showApproveRelieveRequestModal,
    toggleApproveRelieveRequestModal: ownProps.toggleApproveRelieveRequestModal,
    partnershipRelieveRequestError: state.partnershipReducer.partnershipRelieveRequestError,
    partnershipRelieveRequestSuccess: state.partnershipReducer.partnershipRelieveRequestSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<PartnershipActions>) => {
  return {
    approvePartnershipRelieveRequest,
    approvePartnershipRelieveRequestError,
    approvePartnershipRelieveRequestSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ApproveRelieveRequestModalProps = ConnectedProps<typeof connector>

export default connector(ApproveRelieveRequestModal)
