import { Reducer }   from 'react'
import {
  PartnershipActions,
  PartnershipActionTypes,
  PartnershipState } from '../../actions/go/partnership.actions'

const initialState: PartnershipState = {
  partnershipCycleDetails: null,
  partnershipCycleDetailsError: false,
  partnershipCycleDetailsSuccess: false,
  partnershipShareDetails: false,
  partnershipShareDetailsError: false,
  partnershipShareDetailsSuccess: false,
  partnershipDocumentsDetails: false,
  partnershipDocumentsDetailsError: false,
  partnershipDocumentsDetailsSuccess: false,
  partnershipList: [],
  partnershipListError: false,
  partnershipListSuccess: false,
  partnershipDetails: null,
  partnershipDetailsError: false,
  partnershipDetailsSuccess: false,
  partnersAllottedDays: null,
  partnersAllottedDaysError: false,
  partnersAllottedDaysSuccess: false,
  partnershipDaysUpdate: false,
  partnershipDaysUpdateError: false,
  partnershipDaysUpdateSuccess: false,
  partnersRequestedDays: null,
  partnersRequestedDaysError: false,
  partnersRequestedDaysSuccess: false,
  dropPartnershipRequestError: false,
  dropPartnershipRequestSuccess: false,
  propertyTitle: null,
  relievePartnershipRequests: [],
  relievePartnershipRequestsError: false,
  relievePartnershipRequestsSuccess: false,
  partnershipRelieveRequestError: false,
  partnershipRelieveRequestSuccess: false
}

const partnershipReducer: Reducer<PartnershipState, PartnershipActions> = (state = initialState, action) => {
  switch (action.type) {
    case PartnershipActionTypes.PARTNERSHIP_CYCLE_DETAILS_SAVE: {
      return {
        ...state,
        partnershipCycleDetails: action.payload,
        partnershipCycleDetailsSuccess: true
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_CYCLE_DETAILS_SAVE_ERROR: {
      return {
        ...state,
        partnershipCycleDetailsError: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_CYCLE_DETAILS_SAVE_SUCCESS: {
      return {
        ...state,
        partnershipCycleDetailsSuccess: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_SHARE_DETAILS_SAVE: {
      return {
        ...state,
        partnershipShareDetails: action.payload,
        partnershipShareDetailsSuccess: true
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_SHARE_DETAILS_SAVE_ERROR: {
      return {
        ...state,
        partnershipShareDetailsError: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_SHARE_DETAILS_SAVE_SUCCESS: {
      return {
        ...state,
        partnershipShareDetailsSuccess: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_DOCUMENT_DETAILS_SAVE: {
      return {
        ...state,
        partnershipDocumentsDetails: action.payload,
        partnershipDocumentsDetailsSuccess: true
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_DOCUMENT_DETAILS_SAVE_ERROR: {
      return {
        ...state,
        partnershipDocumentsDetailsError: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_DOCUMENT_DETAILS_SAVE_SUCCESS: {
      return {
        ...state,
        partnershipDocumentsDetailsSuccess: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_LIST: {
      return {
        ...state,
        partnershipList: action.payload,
        partnershipListSuccess: true
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_LIST_ERROR: {
      return {
        ...state,
        partnershipListError: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_LIST_SUCCESS: {
      return {
        ...state,
        partnershipListSuccess: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_DETAILS: {
      return {
        ...state,
        partnershipDetails: action.payload,
        partnershipDetailsSuccess: true
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_DETAILS_ERROR: {
      return {
        ...state,
        partnershipDetailsError: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_DETAILS_SUCCESS: {
      return {
        ...state,
        partnershipDetailsSuccess: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERS_ALLOTTED_DAYS: {
      return {
        ...state,
        partnersAllottedDays: action.payload,
        partnersAllottedDaysSuccess: true
      }
    }
    case PartnershipActionTypes.PARTNERS_ALLOTTED_DAYS_ERROR: {
      return {
        ...state,
        partnersAllottedDaysError: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERS_ALLOTTED_DAYS_SUCCESS: {
      return {
        ...state,
        partnersAllottedDaysSuccess: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_DAYS_UPDATE: {
      return {
        ...state,
        partneshipDaysUpdate: action.payload,
        partneshipDaysUpdateSuccess: true
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_DAYS_UPDATE_ERROR: {
      return {
        ...state,
        partneshipDaysUpdateError: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERSHIP_DAYS_UPDATE_SUCCESS: {
      return {
        ...state,
        partneshipDaysUpdateSuccess: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERS_REQUESTED_DAYS: {
      return {
        ...state,
        partnersRequestedDays: action.payload,
        partnersRequestedDaysSuccess: true
      }
    }
    case PartnershipActionTypes.PARTNERS_REQUESTED_DAYS_ERROR: {
      return {
        ...state,
        partnersRequestedDaysError: action.payload
      }
    }
    case PartnershipActionTypes.PARTNERS_REQUESTED_DAYS_SUCCESS: {
      return {
        ...state,
        partnersRequestedDaysSuccess: action.payload
      }
    }
    case PartnershipActionTypes.DROP_PARTNERSHIP_REQUEST_ERROR: {
      return {
        ...state,
        dropPartnershipRequestError: action.payload
      }
    }
    case PartnershipActionTypes.DROP_PARTNERSHIP_REQUEST_SUCCESS: {
      return {
        ...state,
        dropPartnershipRequestSuccess: action.payload
      }
    }
    case PartnershipActionTypes.RELIEVE_PARTNERSHIP_REQUESTS: {
      return {
        ...state,
        propertyTitle: action.payload.property_title,
        relievePartnershipRequests: action.payload.requests,
        relievePartnershipRequestsSuccess: true
      }
    }
    case PartnershipActionTypes.RELIEVE_PARTNERSHIP_REQUESTS_ERROR: {
      return {
        ...state,
        relievePartnershipRequestsError: action.payload
      }
    }
    case PartnershipActionTypes.RELIEVE_PARTNERSHIP_REQUESTS_SUCCESS: {
      return {
        ...state,
        relievePartnershipRequestsSuccess: action.payload
      }
    }
    case PartnershipActionTypes.APPROVE_PARTNERSHIP_RELIEVE_REQUEST_ERROR: {
      return {
        ...state,
        partnershipRelieveRequestError: action.payload
      }
    }
    case PartnershipActionTypes.APPROVE_PARTNERSHIP_RELIEVE_REQUEST_SUCCESS: {
      return {
        ...state,
        partnershipRelieveRequestSuccess: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default partnershipReducer
