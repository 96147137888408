import { FC, FormEvent }                 from 'react'
import { useEffect, useRef, useState }   from 'react'
import { Dispatch }                      from 'redux'
import { connect, ConnectedProps }       from 'react-redux'
import { useNavigate }                   from 'react-router-dom'
import { AdapterDateFns }                from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker }                    from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider }          from '@mui/x-date-pickers/LocalizationProvider'
import moment                            from 'moment'
import { RootState }                     from '../../../../../store'
import { useAppDispatch }                from '../../../../../store/hooks'
import {
  PartnershipActions,
  submitPartnershipCycleDetails,
  submitPartnershipCycleDetailsError,
  submitPartnershipCycleDetailsSuccess } from '../../../../../store/actions/go/partnership.actions'
import {
  PartnershipTimeBasedActions,
  fetchApprovedPartners,
  fetchApprovedPartnersError,
  fetchApprovedPartnersSuccess }         from '../../../../../store/actions/go/partnershipTimeBased.actions'
import { showSnackbar1 }                 from '../../../../../helpers/SnackbarHelper1'

const CycleConstants = {
  cycleSaveErrorMsg: 'Error in saving partnership cycle data. Please try again',
  cycleSaveSuccessMsg: 'Saved partnership cycle data successfully'
}

const Cycle: FC<CycleProps> = ({
  propertyId,
  categoryType,
  initialHolder,
  totalCycleSteps,
  totalDaysPerCycle,
  nextStep,
  handleTextChange,
  approvedPartners,
  approvedPartnersError,
  approvedPartnersSuccess,
  partnershipCycleDetailsError,
  partnershipCycleDetailsSuccess,
  fetchApprovedPartners,
  fetchApprovedPartnersError,
  fetchApprovedPartnersSuccess,
  submitPartnershipCycleDetails,
  submitPartnershipCycleDetailsError,
  submitPartnershipCycleDetailsSuccess
}) => {
  const formRef  = useRef<HTMLFormElement | null>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [cycleStartDate, setCycleStartDate]   = useState<Date>(new Date())
  const [cancelBtnStatus, setCancelBtnStatus] = useState<boolean>(true)
  const [saveBtnStatus, setSaveBtnStatus]     = useState<boolean>(true)
  const [nextBtnStatus, setNextBtnStatus]     = useState<boolean>(true)

  useEffect(() => {
    dispatch(fetchApprovedPartners(propertyId))
  }, [])

  useEffect(() => {
    if (partnershipCycleDetailsSuccess) {
      showSnackbar1(CycleConstants.cycleSaveSuccessMsg)
      hideCycleSaveSuccessMsg()
      setCancelBtnStatus(true)
      setSaveBtnStatus(true)
      setNextBtnStatus(true)
    }
  }, [partnershipCycleDetailsSuccess])

  useEffect(() => {
    if (partnershipCycleDetailsError) {
      showSnackbar1(CycleConstants.cycleSaveErrorMsg)
      hideCycleSaveErrorMsg()
      setCancelBtnStatus(true)
      setSaveBtnStatus(true)
      setNextBtnStatus(true)
    }
  }, [partnershipCycleDetailsError])

  const handleDateChange = (newDate: Date | null) => {
    if (newDate != null) {
      setCycleStartDate(newDate)
    }
  }

  const OnCancelBtnClick = () => {
    navigate('/approved-interested-persons-go-ads-list')
  }

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setCancelBtnStatus(false)
    setSaveBtnStatus(false)
    setNextBtnStatus(false)
    savePartnershipCycleDetails()
  }

  const savePartnershipCycleDetails = () => {
    var convertedDate = moment(new Date(cycleStartDate)).format('YYYY-MM-DD')

    var formData = new FormData()
    formData.append('starting_date', convertedDate)
    formData.append('initial_holder', initialHolder)
    formData.append('total_cycle_steps', totalCycleSteps)
    formData.append('total_days_per_cycle', totalDaysPerCycle)

    dispatch(submitPartnershipCycleDetails(formData, propertyId, categoryType))
  }

  const hideCycleSaveErrorMsg = () => {
    dispatch(submitPartnershipCycleDetailsError(false))
  }

  const hideCycleSaveSuccessMsg = () => {
    dispatch(submitPartnershipCycleDetailsSuccess(false))
  }

  return (
    <div className='card'>
      <form
        ref={formRef}
        onSubmit={handleFormSubmit}>
        <div className='card-body'>
          <div className='pb-10 pb-lg-10'>
            <h2 className='fw-bolder text-dark'>Partnership Cycle Details</h2>
          </div>

          <div className='row mb-5'>
            <div className='col-xl-4'>
              <label className='form-label mb-3'>Category Type</label>
              <input
                type='text'
                name='categoryType'
                value={categoryType.toUpperCase()}
                disabled
                className='form-control form-control-sm' />
            </div>
            <div className='col-xl-4'>
              <label className='form-label mb-3 required'>Initial Holder</label>
              <select
                name='initialHolder'
                value={initialHolder}
                data-control='select2'
                data-hide-search='true'
                onChange={(event) => handleTextChange('initialHolder', event.target.value)}
                className='form-select form-select-sm'>
                {
                  approvedPartners.length > 0 &&
                  approvedPartners.map(el =>
                    <option key={el.user_id} value={el.user_id}>{el.name}</option>
                  )
                }
              </select>
              <div className='text-danger mt-2'></div>
            </div>
            <div className='col-xl-4'>
              <label className='form-label mb-3 required'>Total Cycle Steps</label>
              <input
                type='number'
                min='1'
                step='1'
                name='totalCycleSteps'
                value={totalCycleSteps}
                required
                className='form-control form-control-sm'
                onChange={(event) => handleTextChange('totalCycleSteps', event.target.value)} />
              <div className='text-danger mt-2'></div>
            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-xl-4'>
              <label className='form-label mb-3'>Total Days per Cycle</label>
              <input
                type='text'
                name='totalDaysPerCycle'
                value={totalDaysPerCycle}
                className='form-control form-control-sm'
                disabled
                onChange={(event) => handleTextChange('totalDaysPerCycle', event.target.value)} />
            </div>
            <div className='col-xl-4'>
              <label className='form-label mb-3 required'>Partnership Start Date</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label='Partnership Start Date'
                  value={cycleStartDate}
                  inputFormat='dd-MM-yyyy'
                  minDate={new Date()}
                  onChange={(newDate) => handleDateChange(newDate)}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <div className='d-flex align-items-center'>
                      <input ref={inputRef} className='form-control form-control-sm' {...inputProps} />
                      {InputProps?.endAdornment}
                    </div>
                  )} />
              </LocalizationProvider>
            </div>
          </div>
        </div>

        <div className='card-footer'>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              onClick={OnCancelBtnClick}
              disabled={!cancelBtnStatus}
              className='btn btn-secondary'>
              Cancel
            </button>
            <button
              type='submit'
              disabled={!saveBtnStatus}
              className='ms-2 btn btn-primary'>
              Save <i className="bi bi-save2-fill pe-0"></i>
            </button>
            <button
              type='button'
              onClick={nextStep}
              disabled={!nextBtnStatus}
              className='ms-2 btn btn-secondary'>
              Next <i className="bi bi-chevron-double-right pe-0"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    propertyId: ownProps.propertyId,
    categoryType: ownProps.categoryType,
    initialHolder: ownProps.initialHolder,
    totalCycleSteps: ownProps.totalCycleSteps,
    totalDaysPerCycle: ownProps.totalDaysPerCycle,
    nextStep: ownProps.nextStep,
    handleTextChange: ownProps.handleTextChange,
    approvedPartners: state.partnershipTimeBasedReducer.approvedPartners,
    approvedPartnersError: state.partnershipTimeBasedReducer.approvedPartnersError,
    approvedPartnersSuccess: state.partnershipTimeBasedReducer.approvedPartnersSuccess,
    partnershipCycleDetailsError: state.partnershipReducer.partnershipCycleDetailsError,
    partnershipCycleDetailsSuccess: state.partnershipReducer.partnershipCycleDetailsSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<PartnershipTimeBasedActions | PartnershipActions>) => {
  return {
    fetchApprovedPartners,
    fetchApprovedPartnersError,
    fetchApprovedPartnersSuccess,
    submitPartnershipCycleDetails,
    submitPartnershipCycleDetailsError,
    submitPartnershipCycleDetailsSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type CycleProps = ConnectedProps<typeof connector>

export default connector(Cycle)
