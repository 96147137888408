import { FC } from 'react'

type ShareDetailsProps = {
  data: {
    user_days: number
    user_share: number
    user_amount: number
    balance_amount: number
  }
}

const ShareDetails: FC<ShareDetailsProps> = ({ data }) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>SHARE DETAILS</h3>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>User Share</label>
              <div className='col-lg-8'>
                <span className='fw-bold fs-6 text-dark'>{data.user_share}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>User Days</label>
              <div className='col-lg-8'>
                <span className='fw-bold fs-6 text-dark'>{data.user_days}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>User Amount</label>
              <div className='col-lg-8'>
                <span className='fw-bold fs-6 text-dark'>{data.user_amount}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Balance</label>
              <div className='col-lg-8'>
                <span className='fw-bold fs-6 text-dark'>{data.balance_amount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareDetails
