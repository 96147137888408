import { useState }     from 'react'
import AddCategoryModal from '../../../modal/AddCategoryModal'

const ListToolbar = () => {
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false)

  const toggleCategoryModal = () => {
    setShowCategoryModal(!showCategoryModal)
  }

  return (
    <>
      <div className='d-flex justify-content-end'>
        <button type='button' className='btn btn-sm btn-primary' onClick={toggleCategoryModal}>
          <i className="bi bi-plus-circle-fill"></i> New Category
        </button>
      </div>

      <AddCategoryModal
        showCategoryModal={showCategoryModal}
        toggleCategoryModal={toggleCategoryModal} />
    </>
  )
}

export { ListToolbar }
