import { FC } from 'react'

type CategoryImageCellProps = {
  image: string
  category: string
}

const CategoryImageCell: FC<CategoryImageCellProps> = ({ category, image }) => (
  <img src={image} height='50px' alt={category} />
)

export { CategoryImageCell }
