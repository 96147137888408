import { FC, useEffect, useState }   from 'react'
import { Dispatch }                  from 'redux'
import { connect, ConnectedProps }   from 'react-redux'
import moment                        from 'moment'
import { RootState }                 from '../../../../../store'
import { useAppDispatch }            from '../../../../../store/hooks'
import {
  DealActions,
  uploadKissanDealDocuments, 
  uploadKissanDealDocumentsError,
  uploadKissanDealDocumentsSuccess } from '../../../../../store/actions/kissan/deals/deal.actions'
import { showSnackbar1 }             from '../../../../../helpers/SnackbarHelper1'
import DropzoneWrapper               from '../../../../../components/DropzoneWrapper'

const DocumentUploadModalConstants = {
  fileRequiredMsg: 'Minimum 1 file is required',
  dealDocumentsUploadErrorMsg: 'Failed to upload document(s). Please try again',
  dealDocumentsUploadSuccessMsg: 'Document(s) uploaded successfully'
}

const DocumentUploadModal: FC<DocumentUploadModalProps> = ({
  partnershipId,
  showDocumentUploadModal,
  toggleDocumentUploadModal,
  dealDocumentsUploadError,
  dealDocumentsUploadSuccess,
  uploadKissanDealDocuments,
  uploadKissanDealDocumentsError,
  uploadKissanDealDocumentsSuccess
}) => {
  const dispatch = useAppDispatch()

  const [isFormValid, setIsFormValid]                 = useState<boolean>(false)
  const [requestTitle, setRequestTitle]               = useState<string>('')
  const [requestReason, setRequestReason]             = useState<string>('')
  const [fileObjects, setFileObjects]                 = useState<File[]>([])
  const [showFileRequiredMsg, setShowFileRequiredMsg] = useState<boolean>(false)

  useEffect(() => {
    if (dealDocumentsUploadSuccess) {
      showSnackbar1(DocumentUploadModalConstants.dealDocumentsUploadSuccessMsg)
      closeDealDocumentsUploadSuccessMsg()
      toggleDocumentUploadModal()
    }
  }, [dealDocumentsUploadSuccess])

  useEffect(() => {
    if (dealDocumentsUploadError) {
      showSnackbar1(DocumentUploadModalConstants.dealDocumentsUploadErrorMsg)
      closeDealDocumentsUploadErrorMsg()
    }
  }, [dealDocumentsUploadError])

  useEffect(() => {
    if (showFileRequiredMsg) {
      showSnackbar1(DocumentUploadModalConstants.fileRequiredMsg)
      setShowFileRequiredMsg(false)
    }
  }, [showFileRequiredMsg])

  const handleFormValid = () => {
    setIsFormValid(true)
  }

  const handleFormInValid = () => {
    setIsFormValid(false)
  }

  const handleOnAdd = (fileObjects: File[]) => {
    setFileObjects(fileObjects)
  }

  const handleFormSubmit = () => {
    let fileCount = fileObjects.length
    if (fileCount == 0) {
      setShowFileRequiredMsg(true)
      return false
    }

    let reqDate = moment(new Date()).format('YYYY-MM-DD')

    let formData = new FormData()
    formData.append('date', reqDate)
    formData.append('reason', requestReason)
    // formData.append('relieving_status', requestStatus)
    formData.append('relieving_status', requestTitle)
    fileObjects.forEach((fileObject, index) => {
      formData.append('relieve_document', fileObject)
    })

    // CURRENT
    // dispatch(submitFarmerReleivingRequests(formData, partnershipId))
    // EXPECTED
    // dispatch(uploadKissanDealDocuments(formData, partnershipId))
  }

  const closeDealDocumentsUploadErrorMsg = () => {
    dispatch(uploadKissanDealDocumentsError(false))
  }

  const closeDealDocumentsUploadSuccessMsg = () => {
    dispatch(uploadKissanDealDocumentsSuccess(false))
  }

  return (
    <>
      <div
        className={'modal fade' + (showDocumentUploadModal ? ' show d-block' : '')}
        aria-hidden={showDocumentUploadModal ? 'false' : 'true'}
        aria-labelledby='modal-go-partnership-document-upload'
        tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 id='modal-go-partnership-document-upload'>Partnership Documents</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={toggleDocumentUploadModal}>
                <i className='bi bi-x-circle-fill'></i>
              </div>
            </div>

            <div className='modal-body m-3 mx-xl-10'>
              <form noValidate id='form-go-partnership-document-upload'>
                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Title</span>
                  </label>
                  <input
                    type='text'
                    name='requestTitle'
                    value={requestTitle}
                    required
                    className='form-control form-control-lg form-control-solid'
                    onChange={(event) => setRequestTitle(event.target.value)} />

                  {
                    !requestTitle && isFormValid &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>Title is required</div>
                    </div>
                  }
                </div>

                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Description</span>
                  </label>
                  <input
                    type='text'
                    name='requestReason'
                    value={requestReason}
                    required
                    className='form-control form-control-lg form-control-solid'
                    onChange={(event) => setRequestReason(event.target.value)} />

                  {
                    !requestReason && isFormValid &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>Description is required</div>
                    </div>
                  }
                </div>

                <div className='fv-row mb-10'>
                  <DropzoneWrapper
                    filesLimit={3}
                    handleOnAdd={handleOnAdd}
                    dropzoneText='Upload Documents'
                    acceptedFileTypes='documents' />
                </div>

                <div className='d-flex flex-stack'>
                  <div className='me-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary'
                      onClick={toggleDocumentUploadModal}>
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button
                      type='button'
                      disabled={!isFormValid}
                      className='btn btn-sm btn-primary'
                      onClick={handleFormSubmit}>
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {
        showDocumentUploadModal &&
        <div className='modal-backdrop fade show'></div>
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    partnershipId: ownProps.partnershipId,
    showDocumentUploadModal: ownProps.showDocumentUploadModal,
    toggleDocumentUploadModal: ownProps.toggleDocumentUploadModal,
    dealDocumentsUploadError: state.dealReducer.dealDocumentsUploadError,
    dealDocumentsUploadSuccess: state.dealReducer.dealDocumentsUploadSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<DealActions>) => {
  return {
    uploadKissanDealDocuments,
    uploadKissanDealDocumentsError,
    uploadKissanDealDocumentsSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type DocumentUploadModalProps = ConnectedProps<typeof connector>

export default connector(DocumentUploadModal)
