import { FC, useEffect }           from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { Link, useParams }         from 'react-router-dom'
import { PageLink, PageTitle }     from '../../../../../_metronic/layout/core'
import { showSnackbar }            from '../../../../helpers/SnackbarHelper'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  KissanPostsActions,
  fetchKissanPost,
  fetchKissanPostError }           from '../../../../store/actions/kissan/posts.actions'

const breadcrumbs: Array<PageLink> = [{
  path: '/kissan-list-posts',
  title: 'New Ads',
  isActive: false,
  isSeparator: false
}, {
  path: '',
  title: '',
  isActive: false,
  isSeparator: true
}]

const ViewKissanPostConstants = {
  kissanPostError: 'Error in fetching post details. Please try again'
}

const ViewKissanPost: FC<ViewKissanPostProps> = ({
  kissanPost,
  kissanPostFetchError,
  fetchKissanPost,
  fetchKissanPostError
}) => {
  const params   = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (params.id) {
      dispatch(fetchKissanPost(params.id))
    }
  }, [])

  const closeKissanPostErrorMsg = () => {
    dispatch(fetchKissanPostError(false))
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Post Details</PageTitle>

      {
        kissanPost != null &&
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-5 pb-5'>
              <div className='d-flex flex-wrap flex-sm-nowrap'>
                <div className='flex-grow-1'>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <div className='d-flex'>
                      <span className='text-gray-800 fs-2 fw-bolder me-1'>{kissanPost.cultivation_details.crop_name}</span>
                    </div>
                    <div className='d-flex'>
                      <Link
                        to={'/kissan-list-posts'}
                        className='btn btn-sm btn-primary'>
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row gy-10 gx-xl-10'>
            <div className='col-xl-6'>
              <div className='card card-xxl-stretch mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>FARMER DETAILS</h3>
                  </div>
                </div>

                <div className='card-body p-9'>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Name</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.farmer_details.name}</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Address</label>
                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.farmer_details.address}</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Methodology</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.farmer_details.methodology}</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>E-mail</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.farmer_details.email}</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Phone</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.farmer_details.phone}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-6'>
              <div className='card card-xxl-stretch mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>CULTIVATION DETAILS</h3>
                  </div>
                </div>

                <div className='card-body p-9'>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Crop name</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.cultivation_details.crop_name}</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Cultivation period</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.cultivation_details.cultivation_period}</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Yield</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.cultivation_details.crop_yield} Kg</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Land area</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.cultivation_details.land_area}</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Land area details</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.cultivation_details.land_area_details}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>SHARE DETAILS</h3>
              </div>
            </div>

            <div className='card-body p-9'>
              <div className='row'>
                <div className='col-xl-6'>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Investor amount</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.share_details.investor_amount}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Investor share</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.share_details.investor_share}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Investor max share</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.share_details.investor_max_share}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Investor min share</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.share_details.investor_min_share}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Investor yield</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.share_details.investor_yield}</span>
                    </div>
                  </div>
                </div>

                <div className='col-xl-6'>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Farmer amount</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.share_details.farmer_amount}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Farmer share</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.share_details.farmer_share}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Farmer max share</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.share_details.farmer_max_share}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Farmer min share</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.share_details.farmer_min_share}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Farmer yield</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-dark'>{kissanPost.share_details.farmer_yield}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }

      {
        kissanPostFetchError &&
        showSnackbar(ViewKissanPostConstants.kissanPostError, closeKissanPostErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    kissanPost: state.kissanPostsReducer.kissanPost,
    kissanPostFetchError: state.kissanPostsReducer.kissanPostFetchError
  }
}

const mapDispatchToProps = (dispatch: Dispatch<KissanPostsActions>) => {
  return {
    fetchKissanPost,
    fetchKissanPostError
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ViewKissanPostProps = ConnectedProps<typeof connector>

export default connector(ViewKissanPost)
