import { FC, useEffect, useState }         from 'react'
import { Dispatch }                        from 'redux'
import { connect, ConnectedProps }         from 'react-redux'
import { useParams }                       from 'react-router-dom'
import { PageLink, PageTitle }             from '../../../../../_metronic/layout/core'
import { showSnackbar1 }                   from '../../../../helpers/SnackbarHelper1'
import { RootState }                       from '../../../../store'
import { useAppDispatch }                  from '../../../../store/hooks'
import {
  PartnershipActions,
  fetchRelievePartnershipRequests,
  fetchRelievePartnershipRequestsError,
  fetchRelievePartnershipRequestsSuccess } from '../../../../store/actions/go/partnership.actions'
import ApproveRelieveRequestModal          from './modals/ApproveRelieveRequestModal'
import RelievePartnershipRequest           from './RelievePartnershipRequest'

const breadcrumbs: Array<PageLink> = [{
  path: '/go-partnership-list',
  title: 'Partnerships',
  isActive: false,
  isSeparator: false
}, {
  path: '',
  title: '',
  isActive: false,
  isSeparator: true
}]

const RelievePartnershipRequestsConstants = {
  relievePartnershipRequestsErrorMsg: 'Error in fetching partnership relieve requests. Please try again'
}

const RelievePartnershipRequests: FC<RelievePartnershipRequestsProps> = ({
  propertyTitle,
  relievePartnershipRequests,
  relievePartnershipRequestsError,
  relievePartnershipRequestsSuccess,
  fetchRelievePartnershipRequests,
  fetchRelievePartnershipRequestsError,
  fetchRelievePartnershipRequestsSuccess
}) => {
  const params   = useParams()
  const dispatch = useAppDispatch()

  const [categoryType, setCategoryType]                                     = useState<string>('')
  const [propertyId, setPropertyId]                                         = useState<string>('')
  const [selPartnershipId, setSelPartnershipId]                             = useState<string>('')
  const [selPartnerId, setSelPartnerId]                                     = useState<string>('')
  const [showApproveRelieveRequestModal, setShowApproveRelieveRequestModal] = useState<boolean>(false)

  useEffect(() => {
    if (params.type && params.id) {
      setCategoryType(params.type)
      setPropertyId(params.id)

      dispatch(fetchRelievePartnershipRequests(params.id, params.type))
    }
  }, [])

  useEffect(() => {
    if (relievePartnershipRequestsError) {
      showSnackbar1(RelievePartnershipRequestsConstants.relievePartnershipRequestsErrorMsg)
      closeRelievePartnershipRequestsErrorMsg()
    }
  }, [relievePartnershipRequestsError])

  const closeRelievePartnershipRequestsErrorMsg = () => {
    dispatch(fetchRelievePartnershipRequestsError(false))
  }

  const toggleApproveRelieveRequestModal = () => {
    setShowApproveRelieveRequestModal(!showApproveRelieveRequestModal)
  }

  const approveRelieveRequest = (partnershipId: string, partnerId: string) => {
    setSelPartnershipId(partnershipId)
    setSelPartnerId(partnerId)
    toggleApproveRelieveRequestModal()
  }

  const showNoRelieveRequestsMsg = () => {
    return (
      <div>
        <h6>No Relieving Requests</h6>
      </div>
    )
  }

  const populateRelieveRequests = () => {
    return relievePartnershipRequests.map((row, index) => (
      <RelievePartnershipRequest
        key={index}
        requestData={row}
        approveRelieveRequest={approveRelieveRequest} />
    ))
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Partnership Relieve Requests</PageTitle>

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-5 pb-5'>
          <div className='d-flex align-items-center'>
            <span className='text-gray-800 fs-2 fw-bolder me-1'>{propertyTitle}</span>
          </div>
        </div>
      </div>

      {
        relievePartnershipRequests.length > 0
          ? populateRelieveRequests()
          : showNoRelieveRequestsMsg()
      }

      {
        showApproveRelieveRequestModal &&
        <ApproveRelieveRequestModal
          partnershipId={selPartnershipId}
          partnerId={selPartnerId}
          showApproveRelieveRequestModal={showApproveRelieveRequestModal}
          toggleApproveRelieveRequestModal={toggleApproveRelieveRequestModal} />
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    propertyTitle: state.partnershipReducer.propertyTitle,
    relievePartnershipRequests: state.partnershipReducer.relievePartnershipRequests,
    relievePartnershipRequestsError: state.partnershipReducer.relievePartnershipRequestsError,
    relievePartnershipRequestsSuccess: state.partnershipReducer.relievePartnershipRequestsSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<PartnershipActions>) => {
  return {
    fetchRelievePartnershipRequests,
    fetchRelievePartnershipRequestsError,
    fetchRelievePartnershipRequestsSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type RelievePartnershipRequestsProps = ConnectedProps<typeof connector>

export default connector(RelievePartnershipRequests)
