// @ts-nocheck
import { Column }            from 'react-table'
import { Category }          from '../../core/_models'
import { ActionsCell }       from './ActionsCell'
import { CategoryImageCell } from './CategoryImageCell'
import { CustomHeader }      from './CustomHeader'
import { CustomIndexCell }   from './CustomIndexCell'
import SubcategoriesCell     from './SubcategoriesCell'
 
const categoryColumns: ReadonlyArray<Column<Category>> = [{
  id       : 'row',
  Header   : (props)      => <CustomHeader tableProps={props} title='#' className='min-w-50px' />,
  Cell     : ({...props}) => <CustomIndexCell rowNo={props.row.index} />
}, {
  accessor : 'category',
  Header   : (props)      => <CustomHeader tableProps={props} title='Category' className='min-w-125px' />
}, {
  id       : 'image',
  Header   : (props)      => <CustomHeader tableProps={props} title='Image' className='min-w-125px' />,
  Cell     : ({...props}) => <CategoryImageCell category={props.data[props.row.index].category} image={props.data[props.row.index].image} />
}, {
  accessor : 'type',
  Header   : (props)      => <CustomHeader tableProps={props} title='Type' className='min-w-125px' />
}, {
  id       : 'subcategories',
  Header   : (props)      => <CustomHeader tableProps={props} title='Subcategories' className='min-w-125px' />,
  Cell     : ({...props}) => <SubcategoriesCell id={props.data[props.row.index]._id} category={props.data[props.row.index].category} />
}, {
  id       : 'actions',
  Header   : (props)      => <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />,
  Cell     : ({...props}) => <ActionsCell id={props.data[props.row.index]._id} />
}]

export { categoryColumns }
