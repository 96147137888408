import { FC, useEffect, useState } from 'react'
import { ChangeEvent, MouseEvent } from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import debounce                    from 'lodash/debounce'
import orderBy                     from 'lodash/orderBy'
import { KTCard, KTCardBody }      from '../../../../../_metronic/helpers'
import { PageTitle }               from '../../../../../_metronic/layout/core'
import { ListLoading }             from '../../../../components/ListLoading'
import ListPagination              from '../../../../components/ListPagination'
import { showSnackbar }            from '../../../../helpers/SnackbarHelper'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  ApprovedAd,
  GoAdsActions,
  fetchApprovedAdsList,
  fetchApprovedAdsListError,
  fetchApprovedAdsListSuccess }    from '../../../../store/actions/go/ads.actions'
import OfferAction                 from './modal/OfferActionModal'

const ApprovedAdsConstants = {
  approvedAdsListErrorMsg: 'Error in fetching approved ads. Please try again'
}

const ApprovedAds: FC<ApprovedAdsProps> = ({
  approvedAdsList,
  approvedAdsListError,
  approvedAdsListSuccess,
  applyOfferSuccess,
  fetchApprovedAdsList,
  fetchApprovedAdsListError,
  fetchApprovedAdsListSuccess
}) => {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading]                       = useState<boolean>(false)
  const [columnToQuery, setColumnToQuery]               = useState<string>('title')
  const [adsList, setAdsList]                           = useState<ApprovedAd[]>([])
  const [page, setPage]                                 = useState<number>(0)
  const [rowsPerPage, setRowsPerPage]                   = useState<number>(5)
  const [searchTerm, setSearchTerm]                     = useState<string>('')
  const [adId, setAdId]                                 = useState<string>('')
  const [adTitle, setAdTitle]                           = useState<string>('')
  const [showOfferActionModal, setShowOfferActionModal] = useState<boolean>(false)

  useEffect(() => {
    dispatch(fetchApprovedAdsList())
    setAdsList(approvedAdsList)
  }, [])

  useEffect(() => {
    if (searchTerm != '') {
      filterData()
    }
  }, [searchTerm, columnToQuery])

  useEffect(() => {
    if (searchTerm == '') {
      setAdsList(approvedAdsList)
    }
  }, [searchTerm, approvedAdsList])

  useEffect(() => {
    if (applyOfferSuccess) {
      dispatch(fetchApprovedAdsList())
      setAdsList(approvedAdsList)
    }
  }, [applyOfferSuccess])

  const filterData = () => {
    var debouncedSearch = debounce(() => {
      let currentList = [...adsList]
      let orderedList = orderBy(
        currentList.filter((x) =>
          x[columnToQuery as keyof ApprovedAd]
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ),
        columnToQuery, 'asc'
      )

      setAdsList(orderedList)
    }, 1000)

    debouncedSearch()
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const toggleOfferActionModal = () => {
    setShowOfferActionModal(!showOfferActionModal)
  }

  const closeApprovedGoAdsListErrorMsg = () => {
    dispatch(fetchApprovedAdsListError(false))
  }

  const renderPropertyMode = (propertyMode: string) => {
    let modeTxt = ''
    switch (propertyMode) {
      case '0':
        modeTxt = 'New'
        break
      case '1':
        modeTxt = 'Used'
        break
      case '2':
        modeTxt = 'Running'
        break
      default:
    }

    return <div className='badge badge-light-success fw-bolder'>{modeTxt}</div>
  }

  const renderOfferMode = (offerMode: string) => {
    return (
      <div className='badge badge-light-success fw-bolder'>
        {offerMode == '1' ? 'Offered' : 'Not offer'}
      </div>
    )
  }

  const renderActions = (id: string, title: string) => {
    return (
      <button
        type='button'
        onClick={() => applyOffer(id, title)}
        className='btn btn-sm btn-light btn-active-light-primary'>
        Offers
      </button>
    )
  }

  const applyOffer = (id: string, title: string) => {
    setAdId(id)
    setAdTitle(title)
    setShowOfferActionModal(true)
  }

  const populateApprovedAdsList = () => {
    return (
      rowsPerPage > 0
        ? adsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : adsList
    ).map((row, index) => (
      <tr role='row' key={index}>
        <td role='cell'>
          <div>{(page + 1 - 1) * rowsPerPage + (index + 1)}</div>
        </td>
        <td role='cell'>{row.title}</td>
        <td role='cell'>{row.category_name}</td>
        <td role='cell'>{row.category_type}</td>
        <td role='cell'>{row.amount}</td>
        <td role='cell'>{row.location_name}</td>
        <td role='cell'>{renderPropertyMode(row.property_mode)}</td>
        <td role='cell'>{renderOfferMode(row.offer_mode)}</td>
        <td role='cell' className='text-end min-w-50px'>{renderActions(row._id, row.title)}</td>
      </tr>
    ))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Approved Ads</PageTitle>

      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <select
              data-control='select2'
              data-hide-search='true'
              value={columnToQuery}
              onChange={(event) => setColumnToQuery(event.target.value)}
              className='form-select form-select-sm form-select-solid'>
              <option value='title'>Title</option>
              <option value='category-name'>Category</option>
              <option value='category-type'>Category Type</option>
              <option value='amount'>Amount</option>
              <option value='location-name'>Location</option>
            </select>
            <div className='d-flex align-items-center position-relative my-1 ms-3'>
              <i className='bi bi-search position-absolute ms-3'></i>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid w-250px ps-10'
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)} />
            </div>
          </div>
        </div>

        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='aanshik-go-approved-ads'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th role='columnheader' className='min-w-50px'>#</th>
                  <th role='columnheader' className='min-w-125px'>Title</th>
                  <th role='columnheader'>Category Name</th>
                  <th role='columnheader'>Type</th>
                  <th role='columnheader'>Amount</th>
                  <th role='columnheader'>Location</th>
                  <th role='columnheader'>Mode</th>
                  <th role='columnheader'>Offer</th>
                  <th role='columnheader' className='min-w-50px'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600' role='rowgroup'>
              {
                adsList.length > 0 ? populateApprovedAdsList() : (
                  <tr>
                    <td colSpan={9}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )
              }
              </tbody>
              <tfoot>
                <tr>
                  <ListPagination
                    page={page}
                    count={approvedAdsList.length}
                    colSpan={9}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} />
                </tr>
              </tfoot>
            </table>
          </div>

          {isLoading && <ListLoading />}
        </KTCardBody>
      </KTCard>

      {
        approvedAdsListError &&
        showSnackbar(ApprovedAdsConstants.approvedAdsListErrorMsg, closeApprovedGoAdsListErrorMsg)
      }
      {
        showOfferActionModal &&
        <OfferAction
          adId={adId}
          adTitle={adTitle}
          showOfferActionModal={showOfferActionModal}
          toggleOfferActionModal={toggleOfferActionModal} />
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    approvedAdsList: state.goAdsReducer.approvedAdsList,
    approvedAdsListError: state.goAdsReducer.approvedAdsListError,
    approvedAdsListSuccess: state.goAdsReducer.approvedAdsListSuccess,
    applyOfferSuccess: state.goAdsReducer.applyOfferSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GoAdsActions>) => {
  return {
    fetchApprovedAdsList,
    fetchApprovedAdsListError,
    fetchApprovedAdsListSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ApprovedAdsProps = ConnectedProps<typeof connector>

export default connector(ApprovedAds)
