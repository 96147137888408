import { lazy, FC, Suspense }      from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import TopBarProgress              from 'react-topbar-progress-indicator'
import { getCSSVariableValue }     from '../../_metronic/assets/ts/_utils'
import { WithChildren }            from '../../_metronic/helpers'
import { MasterLayout }            from '../../_metronic/layout/MasterLayout'
import { DashboardWrapper}         from '../pages/dashboard/DashboardWrapper'

// ADMIN
import Locations                   from '../pages/admin/master-settings/locations/Locations'
import Categories                  from '../pages/admin/master-settings/categories/Categories'
import Subcategories               from '../pages/admin/master-settings/subcategories/Subcategories'
import CategoryTypes               from '../pages/admin/master-settings/category-types/CategoryTypes'
// GO
import NewAds                      from '../pages/go/ads/new-ads/NewAds'
import ViewGoAd                    from '../pages/go/ads/view-ad/ViewGoAd'
import ApprovedAds                 from '../pages/go/ads/approved-ads/ApprovedAds'
import InterestedAds               from '../pages/go/ads/interested-ads/InterestedAds'
import InterestedPersons           from '../pages/go/ads/interested-persons/InterestedPersons'
import InterestedPersonsAds        from '../pages/go/ads/interested-persons-ads/InterestedPersonsAds'
import ApprovedPersons             from '../pages/go/ads/approved-persons/ApprovedPersons'
import TimeBasedInit               from '../pages/go/ads/time-based-init/TimeBasedInit'
import Partnerships                from '../pages/go/partnership/Partnerships'
import ViewPartnership             from '../pages/go/partnership/view-partnership/ViewPartnership'
import RelievePartnershipRequests  from '../pages/go/partnership/relieve-partnership/RelievePartnershipRequests'
// KISSAN
import KissanPosts                 from '../pages/kissan/ads/new-ads/KissanPosts'
import ViewKissanPost              from '../pages/kissan/ads/view-ad/ViewKissanPost'
// import EditKissanAd             from '../pages/kissan/ads/edit-ad/EditKissanAd'
import InterestedPostsKissan       from '../pages/kissan/ads/interested-list/InterestedPostsKissan'
import InterestedPersonsKissan     from '../pages/kissan/ads/interested-persons/InterestedPersonsKissan'
import ApprovedPostsKissan         from '../pages/kissan/ads/approved-list/ApprovedPostsKissan'
import Farmers                     from '../pages/kissan/farmers/Farmers'
import Investors                   from '../pages/kissan/investors/Investors'

const PrivateRoutes = () => {
  const DealsPage    = lazy(() => import('../pages/kissan/deals/deals-list/DealsPage'))
  const RelievedPage = lazy(() => import('../pages/kissan/deals/relieved-list/RelievedPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*'                element={<Navigate to='/dashboard' />} />
        <Route path='dashboard'             element={<DashboardWrapper />} />

        {/* ADMIN ROUTES */}

        <Route path='admin-location-list'                     element={<Locations />} />
        <Route path='admin-category-list'                     element={<Categories />} />
        <Route path='admin-sub-category-list/:id'             element={<Subcategories />} />
        <Route path='admin-category-types-list'               element={<CategoryTypes />} />

        {/* GO ROUTES */}

        <Route path='go-list-post'                            element={<NewAds />} />
        <Route path='go-view-ad/:id'                          element={<ViewGoAd />} />
        <Route path='go-approved-posts-list'                  element={<ApprovedAds />} />
        <Route path='go-intrested-post-list'                  element={<InterestedAds />} />
        <Route path='intrested-persons-go-list/:id'           element={<InterestedPersons />} />
        <Route path='approved-interested-persons-go-ads-list' element={<InterestedPersonsAds />} />
        <Route path='approved-persons-go-list/:id/:type'      element={<ApprovedPersons />} />
        <Route path='time-based-partnership-init/:id/:type'   element={<TimeBasedInit />} />
        <Route path='go-partnership-list'                     element={<Partnerships />} />
        <Route path='go-partnership-details/:id/:pid/:type'   element={<ViewPartnership />} />
        <Route path='go-partnership-relieve/:id/:type'        element={<RelievePartnershipRequests />} />

        {/* KISSAN ROUTES */}

        <Route path='kissan-list-posts'                       element={<KissanPosts />} />
        <Route path='kissan-view-post/:id'                    element={<ViewKissanPost />} />
        {/*<Route path='kissan-edit-post/:id'                 element={<EditKissanAd />} />*/}
        <Route path='kissan-intrest-lists'                    element={<InterestedPostsKissan />} />
        <Route path='kissan-interested-person-lists/:id'      element={<InterestedPersonsKissan />} />
        <Route path='kissan-approved-list'                    element={<ApprovedPostsKissan />} />
        <Route path='kissan/deals/*'        element={<SuspensedView><DealsPage /></SuspensedView>} />
        <Route path='kissan/relieved/*'     element={<SuspensedView><RelievedPage /></SuspensedView>} />
        <Route path='kissan-farmers-list'                     element={<Farmers />} />
        <Route path='kissan-investors-list'                   element={<Investors />} />

        <Route path='*'                     element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
