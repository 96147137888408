import { Reducer }            from 'react'
import {
  AdminSettingsActions,
  AdminSettingsActionTypes,
  AdminSettingsActionsState } from '../../actions/admin/settings.action'

const initialState: AdminSettingsActionsState = {
  locations: [],
  locationsFetchError: false,
  locationsFetchSuccess: false,
  locationSubmitError: false,
  locationSubmitSuccess: false,
  categories: [],
  categoriesFetchError: false,
  categoriesFetchSuccess: false,
  categorySubmitError: false,
  categorySubmitSuccess: false,
  subcategories: [],
  subcategoriesFetchError: false,
  subcategoriesFetchSuccess: false,
  subcategorySubmitError: false,
  subcategorySubmitSuccess: false,
  categoryTypes: [],
  categoryTypesFetchError: false,
  categoryTypesFetchSuccess: false,
  categoryTypeSubmitError: false,
  categoryTypeSubmitSuccess: false,
  categoryName: ''
}

const settingsReducer: Reducer<AdminSettingsActionsState, AdminSettingsActions> = (state = initialState, action) => {
  switch (action.type) {
    case AdminSettingsActionTypes.FETCH_LOCATIONS: {
      return {
        ...state,
        locations: action.payload
      }
    }
    case AdminSettingsActionTypes.FETCH_LOCATIONS_ERROR: {
      return {
        ...state,
        locationsFetchError: action.payload
      }
    }
    case AdminSettingsActionTypes.FETCH_LOCATIONS_SUCCESS: {
      return {
        ...state,
        locationsFetchSuccess: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_LOCATION: {
      return {
        ...state,
        locations: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_LOCATION_ERROR: {
      return {
        ...state,
        locationSubmitError: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_LOCATION_SUCCESS: {
      return {
        ...state,
        locationSubmitSuccess: action.payload
      }
    }
    case AdminSettingsActionTypes.FETCH_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      }
    }
    case AdminSettingsActionTypes.FETCH_CATEGORIES_ERROR: {
      return {
        ...state,
        categoriesFetchError: action.payload
      }
    }
    case AdminSettingsActionTypes.FETCH_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categoriesFetchSuccess: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY: {
      return {
        ...state,
        categories: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_ERROR: {
      return {
        ...state,
        categorySubmitError: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_SUCCESS: {
      return {
        ...state,
        categorySubmitSuccess: action.payload
      }
    }
    case AdminSettingsActionTypes.FETCH_SUBCATEGORIES: {
      return {
        ...state,
        subcategories: action.payload
      }
    }
    case AdminSettingsActionTypes.FETCH_SUBCATEGORIES_ERROR: {
      return {
        ...state,
        subcategoriesFetchError: action.payload
      }
    }
    case AdminSettingsActionTypes.FETCH_SUBCATEGORIES_SUCCESS: {
      return {
        ...state,
        subcategoriesFetchSuccess: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_SUBCATEGORY: {
      return {
          ...state,
          subcategories: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_SUBCATEGORY_ERROR: {
      return {
        ...state,
        subcategorySubmitError: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_SUBCATEGORY_SUCCESS: {
      return {
        ...state,
        subcategorySubmitSuccess: action.payload
      }
    }
    case AdminSettingsActionTypes.FETCH_CATEGORY_TYPES: {
      return {
        ...state,
        categoryTypes: action.payload
      }
    }
    case AdminSettingsActionTypes.FETCH_CATEGORY_TYPES_ERROR: {
      return {
        ...state,
        categoryTypesFetchError: action.payload
      }
    }
    case AdminSettingsActionTypes.FETCH_CATEGORY_TYPES_SUCCESS: {
      return {
        ...state,
        categoryTypesFetchSuccess: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_TYPE: {
      return {
        ...state,
        categoryTypes: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_TYPE_ERROR: {
      return {
        ...state,
        categoryTypeSubmitError: action.payload
      }
    }
    case AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_TYPE_SUCCESS: {
      return {
        ...state,
        categoryTypeSubmitSuccess: action.payload
      }
    }
    case AdminSettingsActionTypes.SET_CATEGORY_NAME: {
      return {
        ...state,
        categoryName: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default settingsReducer
