// @ts-nocheck
import { Column }          from 'react-table'
import { CategoryType }    from '../../core/_models'
import { ActionsCell }     from './ActionsCell'
import { CustomHeader }    from './CustomHeader'
import { CustomIndexCell } from './CustomIndexCell'
 
const categoryTypeColumns: ReadonlyArray<Column<CategoryType>> = [{
  id       : 'row',
  Header   : (props)      => <CustomHeader tableProps={props} title='#' className='min-w-50px' />,
  Cell     : ({...props}) => <CustomIndexCell rowNo={props.row.index} />
}, {
  accessor : 'type',
  Header   : (props)      => <CustomHeader tableProps={props} title='Category Type' className='min-w-125px' />
}, {
  id       : 'actions',
  Header   : (props)      => <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />,
  Cell     : ({...props}) => <ActionsCell />
}]

export { categoryTypeColumns }
