import { ChangeEvent, FC, useState} from 'react'
import { Dispatch }                 from 'redux'
import { connect, ConnectedProps }  from 'react-redux'
import { RootState }                from '../../../../../store'
import { useAppDispatch }           from '../../../../../store/hooks'
import {
  AdminSettingsActions,
  submitNewSubcategory,
  submitNewSubcategoryError,
  submitNewSubcategorySuccess }     from '../../../../../store/actions/admin/settings.action'
import { showSnackbar }             from '../../../../../helpers/SnackbarHelper'

const AddSubcategoryModalConstants = {
  subcategorySubmitErrorMsg: 'Failed to add Subcategory. Please try again',
  subcategorySubmitSuccessMsg: 'Subcategory added successfully'
}

const AddSubcategoryModal: FC<AddSubcategoryModalProps> = ({
  categoryId,
  showSubcategoryModal,
  toggleSubcategoryModal,
  subcategorySubmitError,
  subcategorySubmitSuccess,
  submitNewSubcategory,
  submitNewSubcategoryError,
  submitNewSubcategorySuccess
}) => {
  const dispatch = useAppDispatch()

  const [subcategory, setSubcategory] = useState<string>('')
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let fieldValue = event.target.value
    setSubcategory(fieldValue)

    if (fieldValue === '') {
      setIsFormValid(false)
    } else {
      setIsFormValid(true)
    }
  }

  const handleFormValid = () => {
    setIsFormValid(true)
  }

  const handleFormInvalid = () => {
    setIsFormValid(false)
  }

  const formSubmitHandler = () => {
    let formData = new FormData()
    formData.append('category_id', categoryId)
    formData.append('subcategory', subcategory)
    formData.append('status', '1')

    dispatch(submitNewSubcategory(formData))
    closeSubcategoryModal()
  }

  const closeSubcategoryModal = () => {
    setSubcategory('')
    toggleSubcategoryModal()
  }

  const closeSubcategorySubmitSuccessMsg = () => {
    dispatch(submitNewSubcategorySuccess(false))
  }

  const closeSubcategorySubmitErrorMsg = () => {
    dispatch(submitNewSubcategoryError(false))
  }

  return (
    <>
      <div
        className={'modal fade' + (showSubcategoryModal ? ' show d-block' : '')}
        aria-hidden={showSubcategoryModal ? 'false' : 'true'}
        aria-labelledby='modal-admin-add-subcategory'
        tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 id='modal-admin-add-subcategory'>Add Subcategory</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeSubcategoryModal}>
                <i className="bi bi-x-circle-fill"></i>
              </div>
            </div>

            <div className='modal-body m-3 mx-xl-10'>
              <form noValidate id='form-admin-add-subcategory'>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>New Subcategory</span>
                  </label>
                  <input
                    type='text'
                    name='subcategory'
                    value={subcategory}
                    className='form-control form-control-lg form-control-solid'
                    autoComplete='off'
                    onChange={(e) => handleChange(e)} />

                  {
                    !subcategory &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>Subcategory is required</div>
                    </div>
                  }
                </div>

                <div className='d-flex flex-stack'>
                  <div className='me-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary'
                      onClick={closeSubcategoryModal}>Cancel</button>
                  </div>
                  <div>
                    <button
                      type='button'
                      disabled={!isFormValid}
                      className='btn btn-sm btn-primary'
                      onClick={formSubmitHandler}>Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {
        showSubcategoryModal &&
        <div className="modal-backdrop fade show"></div>
      }

      {
        subcategorySubmitSuccess &&
        showSnackbar(AddSubcategoryModalConstants.subcategorySubmitSuccessMsg, closeSubcategorySubmitSuccessMsg)
      }
      {
        subcategorySubmitError &&
        showSnackbar(AddSubcategoryModalConstants.subcategorySubmitErrorMsg, closeSubcategorySubmitErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    categoryId: ownProps.categoryId,
    showSubcategoryModal: ownProps.showSubcategoryModal,
    toggleSubcategoryModal: ownProps.toggleSubcategoryModal,
    subcategorySubmitError: state.settingsReducer.subcategorySubmitError,
    subcategorySubmitSuccess: state.settingsReducer.subcategorySubmitSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AdminSettingsActions>) => {
  return {
    submitNewSubcategory,
    submitNewSubcategoryError,
    submitNewSubcategorySuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type AddSubcategoryModalProps = ConnectedProps<typeof connector>

export default connector(AddSubcategoryModal)
