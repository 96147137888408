import { Reducer } from 'react'
import {
  InterestActions,
  InterestActionTypes,
  InterestState }  from '../../actions/kissan/interest.actions'

const initialState: InterestState = {
  interestedPostsList: [],
  interestedPostsListError: false,
  interestedPostsListSuccess: false,
  interestedPersonsList: [],
  interestedPersonsListError: false,
  interestedPersonsListSuccess: false,
  interestedPersonApproveError: false,
  interestedPersonApproveSuccess: false,
  approvedPostsList: [],
  approvedPostsListError: false,
  approvedPostsListSuccess: false,

  // approvedProperty: '',
  // approvedPropertyFetchError: false,
  // propertyProceedSuccess: false,
  // propertyProceedError: false
}

const interestReducer: Reducer<InterestState, InterestActions> = function(state = initialState, action) {
  switch (action.type) {
    case InterestActionTypes.INTERESTED_POSTS_LIST: {
      return {
        ...state,
        interestedPostsList: action.payload
      }
    }
    case InterestActionTypes.INTERESTED_POSTS_LIST_ERROR: {
      return {
        ...state,
        interestedPostsListError: action.payload
      }
    }
    case InterestActionTypes.INTERESTED_POSTS_LIST_SUCCESS: {
      return {
        ...state,
        interestedPostsListSuccess: action.payload
      }
    }
    case InterestActionTypes.INTERESTED_PERSONS_LIST: {
      return {
        ...state,
        interestedPersonsList: action.payload
      }
    }
    case InterestActionTypes.INTERESTED_PERSONS_LIST_ERROR: {
      return {
        ...state,
        interestedPersonsListError: action.payload
      }
    }
    case InterestActionTypes.INTERESTED_PERSONS_LIST_SUCCESS: {
      return {
        ...state,
        interestedPersonsListSuccess: action.payload
      }
    }
    case InterestActionTypes.APPROVE_INTERESTED_PERSON_ERROR: {
      return {
        ...state,
        interestedPersonApproveError: action.payload
      }
    }
    case InterestActionTypes.APPROVE_INTERESTED_PERSON_SUCCESS: {
      return {
        ...state,
        interestedPersonApproveSuccess: action.payload
      }
    }
    case InterestActionTypes.APPROVED_POSTS_LIST: {
      return {
        ...state,
        approvedPostsList: action.payload
      }
    }
    case InterestActionTypes.APPROVED_POSTS_LIST_ERROR: {
      return {
        ...state,
        approvedPostsListError: action.payload
      }
    }
    case InterestActionTypes.APPROVED_POSTS_LIST_SUCCESS: {
      return {
        ...state,
        approvedPostsListSuccess: action.payload
      }
    }

    // case InterestActionTypes.FETCH_APPROVED_PROPERTY: {
    //   return {
    //     ...state,
    //     approvedProperty: action.payload
    //   }
    // }
    // case InterestActionTypes.FETCH_APPROVED_PROPERTY_ERROR: {
    //   return {
    //     ...state,
    //     approvedPropertyFetchError: action.payload
    //   }
    // }
    // case InterestActionTypes.PROPERTY_PROCEED_SUCCESS: {
    //   return {
    //     ...state,
    //     propertyProceedSuccess: action.payload
    //   }
    // }
    // case InterestActionTypes.PROPERTY_PROCEED_ERROR: {
    //   return {
    //     ...state,
    //     propertyProceedError: action.payload
    //   }
    // }
    default: {
      return state
    }
  }
}

export default interestReducer
