import { Reducer } from 'react'
import {
  FarmerActions,
  FarmerActionTypes,
  FarmerState }    from '../../actions/kissan/farmer.actions'

const initialState: FarmerState = {
  farmersList: [],
  farmersFetchError: false,
  farmersFetchSuccess: false
}

const farmerReducer: Reducer<FarmerState, FarmerActions> = (state = initialState, action) => {
  switch (action.type) {
    case FarmerActionTypes.FARMERS_LIST: {
      return {
        ...state,
        farmersList: action.payload
      }
    }
    case FarmerActionTypes.FARMERS_LIST_ERROR: {
      return {
        ...state,
        farmersFetchError: action.payload
      }
    }
    case FarmerActionTypes.FARMERS_LIST_SUCCESS: {
      return {
        ...state,
        farmersFetchSuccess: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default farmerReducer
