// @ts-nocheck
import { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState }               from '../../../../../store'

const Partner: FC<PartnerProps> = ({
  selPartnerId,
  selPartnerData,
  propertyTitle,
  partnershipId,
  showPartnerModal,
  closePartnerModal
}) => {
  const [personalDetails, setPersonalDetails] = useState<{[key: string]: any}>({})
  const [agreedDetails, setAgreedDetails]     = useState<{[key: string]: any}>({})
  const [postedDetails, setPostedDetails]     = useState<{[key: string]: any}>({})

  useEffect(() => {
    setAgreedDetails(selPartnerData.agreed_details)
    setPostedDetails(selPartnerData.posted_details)
    setPersonalDetails(selPartnerData.personal_details)
  }, [selPartnerData])

  return (
    <>
      <div
        className={'modal fade' + (showPartnerModal ? ' show d-block' : '')}
        aria-hidden={showPartnerModal ? 'false' : 'true'}
        aria-labelledby='modal-go-show-partner-details'
        tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 id='modal-go-show-partner-details'>Partner Details</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closePartnerModal}>
                <i className='bi bi-x-circle-fill'></i>
              </div>
            </div>

            <div className='modal-body m-3 mx-xl-10'>
              <div className='row mb-5'>
                <div className='col-xl-12'>
                  <h5>{propertyTitle}</h5>
                </div>
              </div>

              <div className='row mb-5'>
                <div className='col-xl-12'>
                  <p>POSTED</p>

                  <div>Days : {postedDetails != null ? postedDetails.days : ''}</div>
                  <div>Percentage : {postedDetails != null ? postedDetails.percentage : ''}</div>
                  <div>Amount : {postedDetails != null ? postedDetails.amount : ''}</div>
                </div>
              </div>

              <div className='row mb-5'>
                <div className='col-xl-12'>
                  <p>AGREED</p>

                  <div>Days : {agreedDetails != null ? agreedDetails.days : ''}</div>
                  <div>Percentage : {agreedDetails != null ? agreedDetails.percentage : ''}</div>
                  <div>Amount : {agreedDetails != null ? agreedDetails.amount : ''}</div>
                </div>
              </div>

              <div className='row mb-5'>
                <div className='col-xl-12'>
                  <p>PERSONAL</p>

                  <div>{personalDetails != null ? personalDetails.name : ''}</div>
                  <div>{personalDetails != null ? personalDetails.phone : ''}</div>
                  <div>{personalDetails != null ? personalDetails.email : ''}</div>
                </div>
              </div>

              <div className='d-flex flex-stack'>
                <div>
                  <button
                    type='button'
                    onClick={closePartnerModal}
                    className='btn btn-sm btn-primary'>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        showPartnerModal &&
        <div className='modal-backdrop fade show'></div>
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    partnerId: ownProps.selPartnerId,
    partnerData: ownProps.selPartnerData,
    propertyTitle: ownProps.propertyTitle,
    partnershipId: ownProps.partnershipId,
    showPartnerModal: ownProps.showPartnerModal,
    closePartnerModal: ownProps.closePartnerModal
  }
}

const connector = connect(mapStateToProps, null)
type PartnerProps = ConnectedProps<typeof connector>

export default connector(Partner)
