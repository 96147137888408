import { FC }                      from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState }               from '../../../../../store'

const Document: FC<DocumentProps> = ({ link, title }) => {
  return (
    <div className='row'>
      <div className='col-xl-6'>{title}</div>
      <div className='col-xl-6'>
        <a href={link} target='_blank' rel='noopener noreferrer'>
          <i className='bi bi-download pe-0'></i>
        </a>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    link: ownProps.link,
    title: ownProps.title
  }
}

const connector = connect(mapStateToProps, null)
type DocumentProps = ConnectedProps<typeof connector>

export default connector(Document)
