// @ts-nocheck
import { FC, useState }            from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState }               from '../../../../../store'
import Document                    from './Document'
import DocumentUploadModal         from './DocumentUploadModal'

const PartnershipDocumentsDetails: FC<PartnershipDocumentsDetailsProps> = ({ documents, partnershipId }) => {
  const [showDocumentUploadModal, setShowDocumentUploadModal] = useState<boolean>(false)

  const toggleDocumentUploadModal = () => {
    setShowDocumentUploadModal(!showDocumentUploadModal)
  }

  const renderDocumentList = () => {
    return documents.map((item, index) => (
      <Document
        key={index}
        link={item.document_link}
        title={item.document_name} />
    ))
  }

  const NoDocumentsMsg = () => {
    return (
      <div>
        <h6>Documents not exist ....</h6>
      </div>
    )
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>PARTNERSHIP DOCUMENTS</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row'>
            <div className='col-xl-8'>
            {
              documents.length > 0
                ? renderDocumentList()
                : <NoDocumentsMsg />
            }
            </div>
            <div className='col-xl-2'></div>
            <div className='col-xl-2'>
              <button
                type='button'
                onClick={toggleDocumentUploadModal}
                className='btn btn-sm btn-secondary'>
                New Document
              </button>
            </div>
          </div>
        </div>
      </div>

      {
        showDocumentUploadModal &&
        <DocumentUploadModal
          partnershipId={partnershipId}
          showDocumentUploadModal={showDocumentUploadModal}
          toggleDocumentUploadModal={toggleDocumentUploadModal} />
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    documents: ownProps.documents,
    partnershipId: ownProps.partnershipId
  }
}

const connector = connect(mapStateToProps, null)
type PartnershipDocumentsDetailsProps = ConnectedProps<typeof connector>

export default connector(PartnershipDocumentsDetails)
