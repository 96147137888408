// @ts-nocheck
import { FC }           from 'react'
import { Row }          from 'react-table'
import clsx             from 'clsx'
import { CategoryType } from '../../core/_models'

type CustomRowProps = {
  row: Row<CategoryType>
}

const CustomRow: FC<CustomRowProps> = ({ row }) => (
  <tr {...row.getRowProps()}>
    {
      row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}>
            {cell.render('Cell')}
          </td>
        )
      })
    }
  </tr>
)

export { CustomRow }
