// @ts-nocheck
import { FC, useState, useEffect }  from 'react'
import { Dispatch }                 from 'redux'
import { connect, ConnectedProps }  from 'react-redux'
import { Link, useParams }          from 'react-router-dom'
import { PageLink, PageTitle }      from '../../../../../_metronic/layout/core'
import { showSnackbar }             from '../../../../helpers/SnackbarHelper'
import { RootState }                from '../../../../store'
import { useAppDispatch }           from '../../../../store/hooks'
import {
  GoAdsActions,
  fetchApprovedPersonsList,
  fetchApprovedPersonsListError,
  fetchApprovedPersonsListSuccess } from '../../../../store/actions/go/ads.actions'

const ApprovedPersonsConstants = {
  approvedPersonsListErrorMsg: 'Error in fetching approved persons. Please try again'
}

const breadcrumbs: Array<PageLink> = [{
  path: '/approved-interested-persons-go-ads-list',
  title: 'Interested Persons Ads',
  isActive: false,
  isSeparator: false
}, {
  path: '',
  title: '',
  isActive: false,
  isSeparator: true
}]

const ApprovedPersons: FC<ApprovedPersonsProps> = ({
  approvedPersonsList,
  approvedPersonsListError,
  approvedPersonsListSuccess,
  fetchApprovedPersonsList,
  fetchApprovedPersonsListError,
  fetchApprovedPersonsListSuccess
}) => {
  const params   = useParams()
  const dispatch = useAppDispatch()

  const [currentId, setCurrentId]       = useState<string>('')
  const [categoryType, setCategoryType] = useState<string>('')

  useEffect(() => {
    if (params.type) {
      setCategoryType(params.type)
    }
    if (params.id) {
      setCurrentId(params.id)
      dispatch(fetchApprovedPersonsList(params.id))
    }
  }, [])

  const closeApprovedPersonsListErrorMsg = () => {
    dispatch(fetchApprovedPersonsListError(false))
  }

  const populateApprovedUsersList = () => {
    return approvedPersonsList.users_list.map((row, index) => (
      <tr key={index}>
        <td>{row.name}</td>
        <td>{row.percentage}</td>
        <td>{row.amount}</td>
        <td>{row.days}</td>
      </tr>
    ))
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Approved Persons</PageTitle>

      {
        approvedPersonsList != null &&
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-5 pb-5'>
              <div className='d-flex flex-wrap flex-sm-nowrap'>
                <div className='flex-grow-1'>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <div className='d-flex'>
                      <span className='text-gray-800 fs-2 fw-bolder me-1'>{approvedPersonsList.property_name}</span>
                    </div>
                    <div className='d-flex'>
                      <Link
                        to={`/time-based-partnership-init/${currentId}/${categoryType}`}
                        title='Initiate Partnership'
                        className='btn btn-sm btn-primary'>
                        Initiate <i className='bi bi-chevron-right pe-0'></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card mb-5 mb-xl-10'>
            <div className='card-body py-4'>
              <div className='table-responsive'>
                <table
                  id='aanshik-go-approved-persons'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      <th>Name</th>
                      <th>Share</th>
                      <th>Amount</th>
                      <th>Days</th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600'>
                  {
                    approvedPersonsList.users_list.length > 0 ? populateApprovedUsersList() : (
                      <tr>
                        <td colSpan={4}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            Users not available
                          </div>
                        </td>
                      </tr>
                    )
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      }

      {
        approvedPersonsListError &&
        showSnackbar(ApprovedPersonsConstants.approvedPersonsListErrorMsg, closeApprovedPersonsListErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    approvedPersonsList: state.goAdsReducer.approvedPersonsList,
    approvedPersonsListError: state.goAdsReducer.approvedPersonsListError,
    approvedPersonsListSuccess: state.goAdsReducer.approvedPersonsListSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GoAdsActions>) => {
  return {
    fetchApprovedPersonsList,
    fetchApprovedPersonsListError,
    fetchApprovedPersonsListSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ApprovedPersonsProps = ConnectedProps<typeof connector>

export default connector(ApprovedPersons)
