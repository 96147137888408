import { FC, useEffect, useState } from 'react'
import { Dispatch }                from 'react'
import { ChangeEvent, MouseEvent } from 'react'
import { Link }                    from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import debounce                    from 'lodash/debounce'
import orderBy                     from 'lodash/orderBy'
import { KTCard, KTCardBody }      from '../../../../../_metronic/helpers'
import { useDebounce }             from '../../../../../_metronic/helpers'
import { PageTitle }               from '../../../../../_metronic/layout/core'
import { ListLoading }             from '../../../../components/ListLoading'
import ListPagination              from '../../../../components/ListPagination'
import { showSnackbar }            from '../../../../helpers/SnackbarHelper'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  NewAd,
  GoAdsActions,
  fetchGoAdsList,
  fetchGoAdsListError,
  fetchGoAdsListSuccess }          from '../../../../store/actions/go/ads.actions'

const NewAdsConstants = {
  goAdsListError: 'Error in fetching new ads. Please try again'
}

const NewAds: FC<NewAdsProps> = ({
  newAdsList,
  newAdsListError,
  newAdsListSuccess,
  fetchGoAdsList,
  fetchGoAdsListError,
  fetchGoAdsListSuccess
}) => {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading]         = useState<boolean>(false)
  const [columnToQuery, setColumnToQuery] = useState<string>('title')
  const [searchTerm, setSearchTerm]       = useState<string>('')
  const [adsList, setAdsList]             = useState<NewAd[]>([])
  const [page, setPage]                   = useState<number>(0)
  const [rowsPerPage, setRowsPerPage]     = useState<number>(5)

  useEffect(() => {
    dispatch(fetchGoAdsList())
    setAdsList(newAdsList)
  }, [])

  useEffect(() => {
    if (searchTerm != '') {
      filterData()
    } else if (searchTerm == '') {
      setAdsList(newAdsList)
    }
  }, [searchTerm, columnToQuery])

  const filterData = () => {
    var debouncedSearch = debounce(() => {
      let currentList = [...newAdsList]
      let orderedList = orderBy(
        currentList.filter((x) =>
          x[columnToQuery as keyof NewAd]
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ),
        columnToQuery, 'asc'
      )

      setAdsList(orderedList)
    }, 1000)

    debouncedSearch()
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const closeGoAdsListErrorMsg = () => {
    dispatch(fetchGoAdsListError(false))
  }

  const renderPropertyMode = (propertyMode: string) => {
    let modeTxt = ''
    switch (propertyMode) {
      case '0':
        modeTxt = 'New'
        break
      case '1':
        modeTxt = 'Used'
        break
      case '2':
        modeTxt = 'Running'
        break
      default:
    }

    return <div className='badge badge-light-success fw-bolder'>{modeTxt}</div>
  }

  const renderOfferMode = (offerMode: string) => {
    return (
      <div className='badge badge-light-success fw-bolder'>
        {offerMode == '1' ? 'Offered' : 'Not offer'}
      </div>
    )
  }

  const renderApprovalStatus = (status: string) => {
    return (
      <div className='badge badge-light-success fw-bolder'>
        {status == '1' ? 'Approved' : 'Not approved'}
      </div>
    )
  }

  const renderActions = (id: string) => {
    return (
      <Link
        to={`/go-view-ad/${id}`}
        title='View'
        className='btn btn-sm btn-light btn-active-light-primary'>
        <i className='bi bi-eye-fill pe-0'></i>
      </Link>
    )
  }

  const populateNewAdsList = () => {
    return (
      rowsPerPage > 0
        ? adsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : adsList
    ).map((row, index) => (
      <tr role='row' key={index}>
        <td role='cell'>
          <div>{(page + 1 - 1) * rowsPerPage + (index + 1)}</div>
        </td>
        <td role='cell'>{row.title}</td>
        <td role='cell'>{row.category_name}</td>
        <td role='cell'>{row.amount}</td>
        <td role='cell'>{row.location_name}</td>
        <td role='cell'>{renderPropertyMode(row.property_mode)}</td>
        <td role='cell'>{renderOfferMode(row.offer_mode)}</td>
        <td role='cell'>{renderApprovalStatus(row.approval_status)}</td>
        <td role='cell' className='text-end min-w-50px'>{renderActions(row._id)}</td>
      </tr>
    ))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>New Ads</PageTitle>

      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <select
              data-control='select2'
              data-hide-search='true'
              value={columnToQuery}
              onChange={(event) => setColumnToQuery(event.target.value)}
              className='form-select form-select-sm form-select-solid'>
              <option value="title">Title</option>
              <option value="category_name">Category</option>
              <option value="amount">Amount</option>
              <option value="location_name">Location</option>
            </select>
            <div className='d-flex align-items-center position-relative my-1 ms-3'>
              <i className='bi bi-search position-absolute ms-3'></i>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid w-250px ps-10'
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)} />
            </div>
          </div>
        </div>

        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='aanshik-go-new-ads'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th role='columnheader' className='min-w-50px'>#</th>
                  <th role='columnheader' className='min-w-125px'>Title</th>
                  <th role='columnheader'>Category Name</th>
                  <th role='columnheader'>Amount</th>
                  <th role='columnheader'>Location</th>
                  <th role='columnheader'>Mode</th>
                  <th role='columnheader'>Offer</th>
                  <th role='columnheader'>Status</th>
                  <th role='columnheader' className='min-w-50px'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600' role='rowgroup'>
              {
                adsList.length > 0 ? populateNewAdsList() : (
                  <tr>
                    <td colSpan={9}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )
              }
              </tbody>
              <tfoot>
                <tr>
                  <ListPagination
                    page={page}
                    count={newAdsList.length}
                    colSpan={9}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} />
                </tr>
              </tfoot>
            </table>
          </div>

          {isLoading && <ListLoading />}
        </KTCardBody>
      </KTCard>

      {
        newAdsListError &&
        showSnackbar(NewAdsConstants.goAdsListError, closeGoAdsListErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    newAdsList: state.goAdsReducer.newAdsList,
    newAdsListError: state.goAdsReducer.newAdsListError,
    newAdsListSuccess: state.goAdsReducer.newAdsListSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GoAdsActions>) => {
  return {
    fetchGoAdsList,
    fetchGoAdsListError,
    fetchGoAdsListSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type NewAdsProps = ConnectedProps<typeof connector>

export default connector(NewAds)
