// @ts-nocheck
import { FC, useEffect, useState }    from 'react'
import { Dispatch }                   from 'redux'
import { connect, ConnectedProps }    from 'react-redux'
import DatePicker, { DateObject }     from 'react-multi-date-picker'
import Icon                           from 'react-multi-date-picker/components/icon'
import DatePanel                      from 'react-multi-date-picker/plugins/date_panel'
import _                              from 'lodash'
import { RootState }                  from '../../../../../store'
import { useAppDispatch }             from '../../../../../store/hooks'
import {
  PartnershipActions,
  fetchPartnersRequestedDays,
  fetchPartnersRequestedDaysError,
  fetchPartnersRequestedDaysSuccess } from '../../../../../store/actions/go/partnership.actions'

const PartnersRequestedDays: FC<PartnersRequestedDaysProps> = ({
  propertyTitle,
  partnershipId,
  showRequestedDaysModal,
  toggleRequestedDaysModal,
  partnersRequestedDays,
  partnersRequestedDaysError,
  partnersRequestedDaysSuccess,
  fetchPartnersRequestedDays,
  fetchPartnersRequestedDaysError,
  fetchPartnersRequestedDaysSuccess
}) => {
  const dispatch = useAppDispatch()

  const [cycleStep, setCycleStep]                 = useState<number>(2)
  const [cycleSteps, setCycleSteps]               = useState<any[]>([])
  const [partners, setPartners]                   = useState<any[]>([])
  const [selectedDates, setSelectedDates]         = useState<DateObject[]>([])
  const [partnersDaysCount, setPartnersDaysCount] = useState<number[]>([])
  const [partnershipDates, setPartnershipDates]   = useState<string[]>([])
  const [selectedIndex, setSelectedIndex]         = useState<number>(0)

  useEffect(() => {
    dispatch(fetchPartnersRequestedDays(partnershipId))
  }, [])

  useEffect(() => {
    if (partnersRequestedDaysSuccess) {
      let partners = _.map(partnersRequestedDays, 'partner_name')
      partners = _.uniq(partners)

      var partnersCycle = {}

      _.forEach(partnersRequestedDays, function(value) {
        if (partnersCycle.hasOwnProperty(value.partner_id)) {
          partnersCycle[value.partner_id].push(value.cycle[0])
        } else {
          partnersCycle[value.partner_id] = value.cycle
          partnersCycle[value.partner_id]['name'] = value.partner_name
        }
      })

      _.forEach(partnersCycle, function(value, id) {
        let current_step = _.map(value, function(v, k) {
          if (v.cycle_step == cycleStep)
            return v
        })
      })
    }
  }, [partnersRequestedDays])

  const setPartnershipDatess = (dates: DateObject | DateObject[] | null) => {
    if (dates != null) {
      var filteredDates = _.map(dates, function(dateObject) {
        if (dateObject.isValid) {
          return dateObject.toString()
        }
      })

      var partnershipDatesArr = [...partnershipDates]
      partnershipDatesArr[selectedIndex] = filteredDates

      var partnerDayCountsArr = [...partnersDaysCount]    
      partnerDayCountsArr[selectedIndex] = filteredDates.length

      var partnersArr = [...partners]
      partnersArr[selectedIndex].days = partnershipDatesArr[selectedIndex]

      setPartners(partnersArr)
      setPartnershipDates(partnershipDatesArr)
      setPartnersDaysCount(partnerDayCountsArr)
    }
  }

  const handleOpenDatePicker = (index: number) => {
    setSelectedIndex(index)

    var partnershipDatesArr = [...partnershipDates]
    var dateObjectsStr = partnershipDatesArr[selectedIndex]

    if (typeof dateObjectsStr !== 'undefined') {
      var dateObjs = _.map(dateObjectsStr, function(dateObj) {
        let selectedDate = new Date(dateObj)
        return new DateObject(selectedDate)
      })

      setSelectedDates(dateObjs)
    }
  }

  const handleCloseDatePicker = () => {
    setSelectedDates([])
  }

  const handleDisabledDates = () => {
    let disabledDates = [...partnershipDates]
    var selectedIndexx = selectedIndex
    var activeDates = _.remove(disabledDates, function(n, i) {
      return i === selectedIndexx
    })

    let partnershipDatess = _.flatten(disabledDates)
    partnershipDatess = _.map(partnershipDatess, function(dateObj) {
      let selectedDate = new Date(dateObj)
      let actualDateobject = new DateObject(selectedDate)
      return actualDateobject.toString()
    })

    return partnershipDatess
  }

  const populateRequestedDays = () => {
    return (
      partners.map((row, index) => (
        <div className='row'>
          <div className='col-xl-4'>{row.user_name}</div>
          <div className='col-xl-4'>
            <DatePicker
              value={selectedDates}
              format='DD MMM YYYY'
              render={<Icon/>}
              multiple
              onChange={(dates) => setPartnershipDatess(dates)}
              onOpen={() => handleOpenDatePicker(index)}
              onClose={() => handleCloseDatePicker()}
              plugins={[<DatePanel />]}
              mapDays={({ date }) => {
                let dateProps: any = {}
                let disabledDates = handleDisabledDates()
                // let selectedDate = new Date(date)
                // let selectedDateObject = new DateObject(selectedDate)

                if (disabledDates.includes(date.toString())) {
                  dateProps.title     = 'Taken'
                  dateProps.disabled  = true
                  dateProps.className = 'highlight highlight-red'
                }

                return dateProps
              }} />
          </div>
          <div className='col-xl-4'>{row.days.length}</div>
        </div>
      ))
    )
  }

  return (
    <>
      <div
        className={'modal fade' + (showRequestedDaysModal ? ' show d-block' : '')}
        aria-hidden={showRequestedDaysModal ? 'false' : 'true'}
        aria-labelledby='modal-go-show-requested-days'
        tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 id='modal-go-show-requested-days'>Requested Days</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={toggleRequestedDaysModal}>
                <i className='bi bi-x-circle-fill'></i>
              </div>
            </div>

            <div className='modal-body m-3 mx-xl-10'>
              <div className='row'>
                <div className='col-xl-12'>
                  <h5>{propertyTitle}</h5>
                </div>
                <div className='col-xl-4'>
                  <form noValidate id='form-go-update-requested-days'>
                    {/*label_name='Cycle Steps'*/}
                    <select
                      name='cycleStep'
                      value={cycleStep}
                      data-control='select2'
                      data-hide-search='true'
                      onChange={(event) => setCycleStep(_.toNumber(event.target.value))}
                      className='form-select form-select-sm form-select-white'>
                      {
                        cycleSteps.length > 0 &&
                        cycleSteps.map(el =>
                          <option key={el} value={el}>{el}</option>
                        )
                      }
                    </select>
                  </form>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-4'>
                  <h5>Partner</h5>
                </div>
                <div className='col-xl-4'>
                  <h5>Requested Days</h5>
                </div>
                <div className='col-xl-4'>
                  <h5>Accepted Days</h5>
                </div>

                {partners.length > 0 && populateRequestedDays()}
              </div>

              <div className='d-flex flex-stack'>
                <div>
                  <button
                    type='button'
                    onClick={toggleRequestedDaysModal}
                    className='btn btn-sm btn-secondary'>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        showRequestedDaysModal &&
        <div className='modal-backdrop fade show'></div>
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    propertyTitle: ownProps.propertyTitle,
    partnershipId: ownProps.partnershipId,
    showRequestedDaysModal: ownProps.showRequestedDaysModal,
    toggleRequestedDaysModal: ownProps.toggleRequestedDaysModal,
    partnersRequestedDays: state.partnershipReducer.partnersRequestedDays,
    partnersRequestedDaysError: state.partnershipReducer.partnersRequestedDaysError,
    partnersRequestedDaysSuccess: state.partnershipReducer.partnersRequestedDaysSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<PartnershipActions>) => {
  return {
    fetchPartnersRequestedDays,
    fetchPartnersRequestedDaysError,
    fetchPartnersRequestedDaysSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PartnersRequestedDaysProps = ConnectedProps<typeof connector>

export default connector(PartnersRequestedDays)
