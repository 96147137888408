import { FC, useState, useEffect }   from 'react'
import { Dispatch }                  from 'redux'
import { connect, ConnectedProps }   from 'react-redux'
import { useParams }                 from 'react-router-dom'
import { PageLink, PageTitle }       from '../../../../../_metronic/layout/core'
import { showSnackbar }              from '../../../../helpers/SnackbarHelper'
import { RootState }                 from '../../../../store'
import { useAppDispatch }            from '../../../../store/hooks'
import {
  InterestActions,
  fetchInterestedPersonsList,
  fetchInterestedPersonsListError,
  approveInterestedPerson,
  approveInterestedPersonError,
  approveInterestedPersonSuccess }   from '../../../../store/actions/kissan/interest.actions'
import InterestedPerson              from './InterestedPerson'

const InterestedPersonsKissanConstants = {
  interestedPersonsListErrorMsg: 'Error in fetching interested persons. Please try again',
  interestedPersonStatusUpdateErrorMsg: 'Error occured in approving interest',
  interestedPersonStatusUpdateSuccessMsg: 'Approved person successfully'
}

const breadcrumbs: Array<PageLink> = [{
  path: '/kissan-intrest-lists',
  title: 'Interested List',
  isActive: false,
  isSeparator: false
}, {
  path: '',
  title: '',
  isActive: false,
  isSeparator: true
}]

const InterestedPersonsKissan: FC<InterestedPersonsKissanProps> = ({
  interestedPersonsList,
  interestedPersonsListError,
  interestedPersonApproveError,
  interestedPersonApproveSuccess,
  fetchInterestedPersonsList,
  fetchInterestedPersonsListError,
  approveInterestedPerson,
  approveInterestedPersonError,
  approveInterestedPersonSuccess
}) => {
  const params   = useParams()
  const dispatch = useAppDispatch()

  const [currentId, setCurrentId] = useState<string>('')

  useEffect(() => {
    if (params.id) {
      setCurrentId(params.id)
      dispatch(fetchInterestedPersonsList(params.id))
    }
  }, [])

  useEffect(() => {
    if (interestedPersonApproveSuccess) {
      dispatch(fetchInterestedPersonsList(currentId))
    }
  }, [interestedPersonApproveSuccess])

  const approvePerson = (interestId: string) => {
    let formData = new FormData()
    formData.append('approval_status', '1')

    dispatch(approveInterestedPerson(formData, interestId, currentId))
  }

  const closeInterestedPersonsListErrorMsg = () => {
    dispatch(fetchInterestedPersonsListError(false))
  }

  const closeApprovalSuccessMsg = () => {
    dispatch(approveInterestedPersonSuccess(false))
  }

  const closeApprovalErrorMsg = () => {
    dispatch(approveInterestedPersonError(false))
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Interested Persons</PageTitle>

      {
        interestedPersonsList.length > 0 &&
        interestedPersonsList.map((row, index) => (
          <InterestedPerson
            key={index}
            person={row}
            approvePerson={approvePerson} />
        ))
      }

      {
        interestedPersonsListError &&
        showSnackbar(InterestedPersonsKissanConstants.interestedPersonsListErrorMsg, closeInterestedPersonsListErrorMsg)
      }
      {
        interestedPersonApproveSuccess &&
        showSnackbar(InterestedPersonsKissanConstants.interestedPersonStatusUpdateSuccessMsg, closeApprovalSuccessMsg)
      }
      {
        interestedPersonApproveError &&
        showSnackbar(InterestedPersonsKissanConstants.interestedPersonStatusUpdateErrorMsg, closeApprovalErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    interestedPersonsList: state.interestReducer.interestedPersonsList,
    interestedPersonsListError: state.interestReducer.interestedPersonsListError,
    interestedPersonApproveError: state.interestReducer.interestedPersonApproveError,
    interestedPersonApproveSuccess: state.interestReducer.interestedPersonApproveSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<InterestActions>) => {
  return {
    fetchInterestedPersonsList,
    fetchInterestedPersonsListError,
    approveInterestedPerson,
    approveInterestedPersonError,
    approveInterestedPersonSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type InterestedPersonsKissanProps = ConnectedProps<typeof connector>

export default connector(InterestedPersonsKissan)
