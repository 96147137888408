// @ts-nocheck
import { FC, useEffect, useState } from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { Link }                    from 'react-router-dom'
import { AdapterDateFns }          from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker }              from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider }    from '@mui/x-date-pickers/LocalizationProvider'
import TextField                   from '@mui/material/TextField'
import { RootState }               from '../../../../../store'
import { useAppDispatch }          from '../../../../../store/hooks'
import {
  fetchApprovedPartners,
  fetchApprovedPartnersError,
  fetchApprovedPartnersSuccess,
  PartnershipTimeBasedActions }    from '../../../../../store/actions/go/partnershipTimeBased.actions'

const Start: FC<StartProps> = ({
  propertyId,
  categoryType,
  approvedPartners,
  approvedPartnersError,
  approvedPartnersSuccess,
  handleTextChange,
  nextStep,
  fetchApprovedPartners,
  fetchApprovedPartnersError,
  fetchApprovedPartnersSuccess
}) => {
  const dispatch = useAppDispatch()

  const [partnershipStartDate, setPartnershipStartDate] = useState<Date>(new Date())

  useEffect(() => {
    dispatch(fetchApprovedPartners(propertyId))
  }, [])

  return (
    <div>
      <div className='card'>
        <div className='card-body'>
          <div className='pb-10 pb-lg-10'>
            <h2 className='fw-bolder text-dark'>Partnership Start Details</h2>
          </div>

          <div className='row mb-5'>
            <div className='col-xl-4'>
              <label className='form-label mb-3'>Category Type</label>
              <input
                type='text'
                name='categoryType'
                value={categoryType.toUpperCase()}
                disabled
                className='form-control form-control-sm' />
            </div>
            <div className='col-xl-4'>
              <label className='form-label mb-3 required'>Initial Holder</label>
              <select
                name='initialHolder'
                data-control='select2'
                data-hide-search='true'
                onChange={(event) => handleTextChange('initialHolder', event.target.value)}
                className='form-select form-select-sm'
                defaultValue='Select'>
                {
                  approvedPartners.length > 0 &&
                  approvedPartners.map(el =>
                    <option key={el.user_id} value={el.user_id}>{el.name}</option>
                  )
                }
              </select>
              <div className='text-danger mt-2'></div>
            </div>
            <div className='col-xl-4'>
              <label className='form-label mb-3 required'>Partnership Start Date</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label='Partnership Start Date'
                  value={partnershipStartDate}
                  inputFormat='dd-MM-yyyy'
                  minDate={new Date()}
                  onChange={(event) => setPartnershipStartDate(event.target.value)}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <div className='d-flex align-items-center'>
                      <input ref={inputRef} className='form-control form-control-sm' {...inputProps} />
                      {InputProps?.endAdornment}
                    </div>
                  )} />
              </LocalizationProvider>
            </div>
          </div>
        </div>

        <div className='card-footer'>
          <div className='d-flex align-items-center justify-content-end'>
            <Link
              to={'/approved-interested-persons-go-ads-list'}
              className='btn btn-secondary'>
              Cancel
            </Link>
            <button
              type='button'
              onClick={nextStep}
              className='ms-2 btn btn-primary'>
              Save <i className="bi bi-save2-fill pe-0"></i>
            </button>
            <button
              type='button'
              onClick={nextStep}
              className='ms-2 btn btn-secondary'>
              Next <i className="bi bi-chevron-double-right pe-0"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    propertyId: ownProps.propertyId,
    categoryType: ownProps.categoryType,
    nextStep: ownProps.nextStep,
    handleTextChange: ownProps.handleTextChange,
    approvedPartners: state.partnershipTimeBasedReducer.approvedPartners,
    approvedPartnersError: state.partnershipTimeBasedReducer.approvedPartnersError,
    approvedPartnersSuccess: state.partnershipTimeBasedReducer.approvedPartnersSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<PartnershipTimeBasedActions>) => {
  return {
    fetchApprovedPartners,
    fetchApprovedPartnersError,
    fetchApprovedPartnersSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type StartProps = ConnectedProps<typeof connector>

export default connector(Start)
