import { FC, MouseEvent }          from 'react'
import { connect, ConnectedProps } from 'react-redux'
import TablePagination             from '@mui/material/TablePagination'
import { RootState }               from '../store'

function ListPaginationActions(props: any) {
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className='d-flex'>
      <button
        type='button'
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        className='btn btn-sm btn-icon'
        aria-label='first page'>
        <i className='bi bi-chevron-double-left pe-0'></i>
      </button>

      <button
        type='button'
        onClick={handleBackButtonClick}
        disabled={page === 0}
        className='btn btn-sm btn-icon'
        aria-label='previous page'>
        <i className='bi bi-chevron-left pe-0'></i>
      </button>

      <button
        type='button'
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        className='btn btn-sm btn-icon'
        aria-label='next page'>
        <i className='bi bi-chevron-right pe-0'></i>
      </button>

      <button
        type='button'
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        className='btn btn-sm btn-icon'
        aria-label='last page'>
        <i className='bi bi-chevron-double-right pe-0'></i>
      </button>
    </div>
  )
}

const ListPagination: FC<ListPaginationProps> = ({
  page,
  count,
  colSpan,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage
}) => {
  return (
    <TablePagination
      page={page}
      count={count}
      colSpan={colSpan}
      rowsPerPage={rowsPerPage}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
      SelectProps={{
        native: true,
        inputProps: { 'aria-label': 'rows per page' }
      }}
      ActionsComponent={ListPaginationActions} />
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    page: ownProps.page,
    count: ownProps.count,
    colSpan: ownProps.colSpan,
    rowsPerPage: ownProps.rowsPerPage,
    handleChangePage: ownProps.handleChangePage,
    handleChangeRowsPerPage: ownProps.handleChangeRowsPerPage
  }
}

const connector = connect(mapStateToProps, null)
type ListPaginationProps = ConnectedProps<typeof connector>

export default connector(ListPagination)
