import axios           from 'axios'
import AppConfig       from '../../../../appConfig'
import { AppDispatch } from '../../../store'

// *************** TYPES

export interface InterestedPost {
  _id: string
  date: string
  price: string
  location: string
  crop_name: string
  deal_status: string
  farmer_name: string
  interest_count: number
  thumbnailmyproperty_image: string
}

export interface InterestedPerson {
  date: string
  user_id: string
  location: string
  user_name: string
  user_email: string
  user_phone: string
  user_share: string
  user_yield: number
  interest_id: string
  user_amount: number
  approval_status: string
}

export interface ApprovedPost {
  title: string
  deal_status: string
  farmer_name: string
  investor_id: string
  investor_name: string
  investor_yield: number
  investor_amount: number
  property_details_id: string
  property_interest_id: string
  thumbnailmyproperty_image: string
}

// *************** STATE

export interface InterestState {
  interestedPostsList: InterestedPost[]
  interestedPostsListError: boolean
  interestedPostsListSuccess: boolean
  interestedPersonsList: InterestedPerson[]
  interestedPersonsListError: boolean
  interestedPersonsListSuccess: boolean
  interestedPersonApproveError: boolean
  interestedPersonApproveSuccess: boolean
  approvedPostsList: ApprovedPost[]
  approvedPostsListError: boolean
  approvedPostsListSuccess: boolean

  // approvedProperty: string
  // approvedPropertyFetchError: boolean
  // propertyProceedSuccess: boolean
  // propertyProceedError: boolean
}

// *************** ACTION TYPES

export const InterestActionTypes = {
  INTERESTED_POSTS_LIST             : 'INTERESTED_POSTS_LIST',
  INTERESTED_POSTS_LIST_ERROR       : 'INTERESTED_POSTS_LIST_ERROR',
  INTERESTED_POSTS_LIST_SUCCESS     : 'INTERESTED_POSTS_LIST_SUCCESS',
  INTERESTED_PERSONS_LIST           : 'INTERESTED_PERSONS_LIST',
  INTERESTED_PERSONS_LIST_ERROR     : 'INTERESTED_PERSONS_LIST_ERROR',
  INTERESTED_PERSONS_LIST_SUCCESS   : 'INTERESTED_PERSONS_LIST_SUCCESS',
  APPROVE_INTERESTED_PERSON_ERROR   : 'APPROVE_INTERESTED_PERSON_ERROR',
  APPROVE_INTERESTED_PERSON_SUCCESS : 'APPROVE_INTERESTED_PERSON_SUCCESS',
  APPROVED_POSTS_LIST               : 'APPROVED_POSTS_LIST',
  APPROVED_POSTS_LIST_ERROR         : 'APPROVED_POSTS_LIST_ERROR',
  APPROVED_POSTS_LIST_SUCCESS       : 'APPROVED_POSTS_LIST_SUCCESS',

  // FETCH_APPROVED_PROPERTY : 'FETCH_APPROVED_PROPERTY',
  // FETCH_APPROVED_PROPERTY_ERROR : 'FETCH_APPROVED_PROPERTY_ERROR',
  // PROPERTY_PROCEED_SUCCESS : 'PROPERTY_PROCEED_SUCCESS',
  // PROPERTY_PROCEED_ERROR : 'PROPERTY_PROCEED_ERROR',
} as const

// *************** ACTIONS

interface FetchInterestedPostsListAction {
  type: typeof InterestActionTypes.INTERESTED_POSTS_LIST;
  payload: InterestedPost[];
}
interface FetchInterestedPostsListSuccessAction {
  type: typeof InterestActionTypes.INTERESTED_POSTS_LIST_SUCCESS;
  payload: boolean;
}
interface FetchInterestedPostsListFailureAction {
  type: typeof InterestActionTypes.INTERESTED_POSTS_LIST_ERROR;
  payload: boolean;
}

interface FetchInterestedPersonsListAction {
  type: typeof InterestActionTypes.INTERESTED_PERSONS_LIST;
  payload: InterestedPerson[];
}
interface FetchInterestedPersonsListSuccessAction {
  type: typeof InterestActionTypes.INTERESTED_PERSONS_LIST_SUCCESS;
  payload: boolean;
}
interface FetchInterestedPersonsListFailureAction {
  type: typeof InterestActionTypes.INTERESTED_PERSONS_LIST_ERROR;
  payload: boolean;
}

interface ApproveInterestedPersonSuccessAction {
  type: typeof InterestActionTypes.APPROVE_INTERESTED_PERSON_SUCCESS;
  payload: boolean;
}
interface ApproveInterestedPersonFailureAction {
  type: typeof InterestActionTypes.APPROVE_INTERESTED_PERSON_ERROR;
  payload: boolean;
}

interface FetchApprovedPostsListAction {
  type: typeof InterestActionTypes.APPROVED_POSTS_LIST;
  payload: ApprovedPost[];
}
interface FetchApprovedPostsListSuccessAction {
  type: typeof InterestActionTypes.APPROVED_POSTS_LIST_SUCCESS;
  payload: boolean;
}
interface FetchApprovedPostsListFailureAction {
  type: typeof InterestActionTypes.APPROVED_POSTS_LIST_ERROR;
  payload: boolean;
}

export type InterestActions =
  | FetchInterestedPostsListAction
  | FetchInterestedPostsListSuccessAction
  | FetchInterestedPostsListFailureAction
  | FetchInterestedPersonsListAction
  | FetchInterestedPersonsListSuccessAction
  | FetchInterestedPersonsListFailureAction
  | ApproveInterestedPersonSuccessAction
  | ApproveInterestedPersonFailureAction
  | FetchApprovedPostsListAction
  | FetchApprovedPostsListSuccessAction
  | FetchApprovedPostsListFailureAction


// *************** API CALLS
/* FETCH INTERESTED POSTS */

export function fetchInterestedPostsList() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_INTEREST_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: InterestActionTypes.INTERESTED_POSTS_LIST,
        payload: response.data.interestdetails
      })
      dispatch(fetchInterestedPostsListSuccess(true))
    })
    .catch(error => {
      dispatch(fetchInterestedPostsListError(true))
    })
  }
}
export function fetchInterestedPostsListError(status: boolean) {
  return {
    type: InterestActionTypes.INTERESTED_POSTS_LIST_ERROR,
    payload: status
  }
}
export function fetchInterestedPostsListSuccess(status: boolean) {
  return {
    type: InterestActionTypes.INTERESTED_POSTS_LIST_SUCCESS,
    payload: status
  }
}

/* FETCH INTERESTED PERSONS LIST (FOR A SPECIFIC POST) */

export function fetchInterestedPersonsList(id: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_INTERESTED_PERSONS_LIST

    return axios.get(`${url}/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: InterestActionTypes.INTERESTED_PERSONS_LIST,
        payload: response.data.interestdetails
      })
      dispatch(fetchInterestedPersonsListSuccess(true))
    })
    .catch(error => {
      dispatch(fetchInterestedPersonsListError(true))
    })
  }
}
export function fetchInterestedPersonsListError(status: boolean) {
  return {
    type: InterestActionTypes.INTERESTED_PERSONS_LIST_ERROR,
    payload: status
  }
}
export function fetchInterestedPersonsListSuccess(status: boolean) {
  return {
    type: InterestActionTypes.INTERESTED_PERSONS_LIST_SUCCESS,
    payload: status
  }
}

/* APPROVE INTERESTED PERSON */

export function approveInterestedPerson(formData: any, interestId: string, currentId: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_INTERESTED_PERSONS_APPROVE

    return axios.post(`${url}/${interestId}/${currentId}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(approveInterestedPersonSuccess(true))
    })
    .catch(error => {
      dispatch(approveInterestedPersonError(true))
    })
  }
}
export function approveInterestedPersonError(status: boolean) {
  return {
    type: InterestActionTypes.APPROVE_INTERESTED_PERSON_ERROR,
    payload: status
  }
}
export function approveInterestedPersonSuccess(status: boolean) {
  return {
    type: InterestActionTypes.APPROVE_INTERESTED_PERSON_SUCCESS,
    payload: status
  }
}

/* FETCH APPROVED POSTS */

export function fetchApprovedPostsList() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_INTERESTED_PERSONS_APPROVED_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: InterestActionTypes.APPROVED_POSTS_LIST,
        payload: response.data.interestdetails
      })
    })
    .catch(error => {
      dispatch(fetchApprovedPostsListError(true))
    })
  }
}
export function fetchApprovedPostsListError(status: boolean) {
  return {
    type: InterestActionTypes.APPROVED_POSTS_LIST_ERROR,
    payload: status
  }
}
export function fetchApprovedPostsListSuccess(status: boolean) {
  return {
    type: InterestActionTypes.APPROVED_POSTS_LIST_SUCCESS,
    payload: status
  }
}

/*
export function fetchApprovedProperty(id, iid) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_FETCH_APPROVED_PROPERTY

    return axios.get(`${url}/${id}/${iid}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: InterestActionTypes.FETCH_APPROVED_PROPERTY,
        payload: response.data.dealdetails
      })
    })
    .catch(error => {
      dispatch(personApprovedListsError(true))
    })
  }
}
export function fetchApprovedPropertyError(status: boolean) {
  return {
    type: InterestActionTypes.FETCH_APPROVED_PROPERTY_ERROR,
    payload: status
  }
}

export function proceedApprovedDeal(formData: any) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_DEAL_APPROVED_PROCEED

    return axios.post(url, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(proceedApprovedDealSuccess(true))
    })
    .catch(error => {
      dispatch(proceedApprovedDealError(true))
    })
  }
}
export function proceedApprovedDealError(status: boolean) {
  return {
    type: InterestActionTypes.PROPERTY_PROCEED_ERROR,
    payload: status
  }
}
export function proceedApprovedDealSuccess(status: boolean) {
  return {
    type: InterestActionTypes.PROPERTY_PROCEED_SUCCESS,
    payload: status
  }
}
*/
