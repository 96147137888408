import { ListToolbar } from './ListToolbar'

const ListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-toolbar'>
        <ListToolbar />
      </div>
    </div>
  )
}

export { ListHeader }
