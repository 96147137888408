import { FC, useEffect, useState } from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { useParams }               from 'react-router-dom'
import { PageLink, PageTitle }     from '../../../../../_metronic/layout/core'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  Document,
  PartnerDetail,
  PartnershipActions,
  PartnershipDetails,
  fetchPartnershipDetails,
  fetchPartnershipDetailsError,
  fetchPartnershipDetailsSuccess } from '../../../../store/actions/go/partnership.actions'
import TimeBased                   from './time-based/TimeBased'
import ProfitBased                 from './profit-based/ProfitBased'

const breadcrumbs: Array<PageLink> = [{
  path: '/go-partnership-list',
  title: 'Partnership List',
  isActive: false,
  isSeparator: false
}, {
  path: '',
  title: '',
  isActive: false,
  isSeparator: true
}]

const ViewPartnership: FC<ViewPartnershipProps> = ({
  partnershipDetails,
  partnershipDetailsError,
  partnershipDetailsSuccess,
  fetchPartnershipDetails,
  fetchPartnershipDetailsError,
  fetchPartnershipDetailsSuccess
}) => {
  const params   = useParams()
  const dispatch = useAppDispatch()

  const [selCategoryType, setSelCategoryType]   = useState<string>('')
  const [selPropertyId, setSelPropertyId]       = useState<string>('')
  const [selPartnershipId, setSelPartnershipId] = useState<string>('')
  const [propertyDetails, setPropertyDetails]   = useState<{[key: string]: any}>({})
  const [partners, setPartners]                 = useState<PartnerDetail[]>([])
  const [documents, setDocuments]               = useState<Document[]>([])
  const [partnershipId, setPartnershipId]       = useState<string>('')

  useEffect(() => {
    if (params.type && params.id && params.pid) {
      setSelCategoryType(params.type)
      setSelPropertyId(params.id)
      setSelPartnershipId(params.pid)

      dispatch(fetchPartnershipDetails(params.id, params.pid, params.type))
    }

    return () => {
      dispatch(fetchPartnershipDetailsError(false))
      dispatch(fetchPartnershipDetailsSuccess(false))
    }
  }, [])

  useEffect(() => {
    if (partnershipDetailsSuccess && partnershipDetails != null) {
      setPartnershipId(partnershipDetails.partnership_id)
      setPropertyDetails(partnershipDetails.property_details)
      setPartners(partnershipDetails.partners)
      setDocuments(partnershipDetails.documents)
    }
  }, [partnershipDetailsSuccess])

  const renderPartnershipDetails = () => {
    if (propertyDetails != null) {
      if (selCategoryType == 'timebased') {
        return (
          <TimeBased
            propertyTitle={propertyDetails.title}
            categoryType={propertyDetails.category_type}
            images={propertyDetails.image}
            videoUrl={propertyDetails.video_url}
            partners={partners}
            documents={documents}
            partnershipId={partnershipId} />
        )
      } else if (selCategoryType == 'profitbased' || selCategoryType == 'others') {
        return (
          <ProfitBased
            propertyTitle={propertyDetails.title}
            categoryType={propertyDetails.category_type}
            images={propertyDetails.image}
            videoUrl={propertyDetails.video_url}
            partners={partners}
            documents={documents}
            partnershipId={partnershipId} />
        )
      }
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Partnership Details</PageTitle>

      {renderPartnershipDetails()}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    partnershipDetails: state.partnershipReducer.partnershipDetails,
    partnershipDetailsError: state.partnershipReducer.partnershipDetailsError,
    partnershipDetailsSuccess: state.partnershipReducer.partnershipDetailsSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<PartnershipActions>) => {
  return {
    fetchPartnershipDetails,
    fetchPartnershipDetailsError,
    fetchPartnershipDetailsSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ViewPartnershipProps = ConnectedProps<typeof connector>

export default connector(ViewPartnership)
