import { FC, PropsWithChildren } from 'react'
import { HeaderProps }           from 'react-table'
import clsx                      from 'clsx'
import { CategoryType }          from '../../core/_models'

type CustomHeaderProps = {
  title?: string
  className?: string
  tableProps: PropsWithChildren<HeaderProps<CategoryType>>
}

const CustomHeader: FC<CustomHeaderProps> = ({ className, tableProps, title }) => {
  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}>
      {title}
    </th>
  )
}

export { CustomHeader }
