import axios           from 'axios'
import AppConfig       from '../../../../appConfig'
import { AppDispatch } from '../../../store'

/*********************/
/* Types */

interface Partner {
  user_id: string
  name: string
}

/*********************/
/* State */

export interface PartnershipTimeBasedState {
  approvedPartners: Partner[]
  approvedPartnersError: boolean
  approvedPartnersSuccess: boolean
}

/*********************/
/* Action Types */

export const PartnershipTimeBasedActionTypes = {
  APPROVED_PARTNERS         : 'APPROVED_PARTNERS',
  APPROVED_PARTNERS_ERROR   : 'APPROVED_PARTNERS_ERROR',
  APPROVED_PARTNERS_SUCCESS : 'APPROVED_PARTNERS_SUCCESS'
} as const

/*********************/
/* Action Interfaces */

interface FetchApprovedPartnersAction {
  type: typeof PartnershipTimeBasedActionTypes.APPROVED_PARTNERS
  payload: Partner[]
}
interface FetchApprovedPartnersFailureAction {
  type: typeof PartnershipTimeBasedActionTypes.APPROVED_PARTNERS_ERROR
  payload: boolean
}
interface FetchApprovedPartnersSuccessAction {
  type: typeof PartnershipTimeBasedActionTypes.APPROVED_PARTNERS_SUCCESS
  payload: boolean
}

/*********************/
/* Actions */

export type PartnershipTimeBasedActions =
  | FetchApprovedPartnersAction
  | FetchApprovedPartnersFailureAction
  | FetchApprovedPartnersSuccessAction


/*********************/
/* API Calls */

export function fetchApprovedPartners(propertyId: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.PROFIT_BASED_APPROVED_PARTNERS

    return axios.get(`${url}/${propertyId}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: PartnershipTimeBasedActionTypes.APPROVED_PARTNERS,
        payload: response.data.interestusers
      })
    })
    .catch(error => {
      dispatch(fetchApprovedPartnersError(true))
    })
  }
}
export function fetchApprovedPartnersError(status: boolean) {
  return {
    type: PartnershipTimeBasedActionTypes.APPROVED_PARTNERS_ERROR,
    payload: status
  }
}
export function fetchApprovedPartnersSuccess(status: boolean) {
  return {
    type: PartnershipTimeBasedActionTypes.APPROVED_PARTNERS_SUCCESS,
    payload: status
  }
}
