// @ts-nocheck
import { ChangeEvent, FC }         from 'react'
import { useEffect, useState }     from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { RootState }               from '../../../../../store'
import { useAppDispatch }          from '../../../../../store/hooks'
import {
  AdminSettingsActions,
  fetchCategoryTypes,
  fetchCategoryTypesError,
  fetchCategoryTypesSuccess,
  submitNewCategory,
  submitNewCategoryError,
  submitNewCategorySuccess }       from '../../../../../store/actions/admin/settings.action'
import DropzoneWrapper             from '../../../../../components/DropzoneWrapper'
import { showSnackbar }            from '../../../../../helpers/SnackbarHelper'

const AddCategoryModalConstants = {
  fileRequiredMsg: 'Image required',
  categorySubmitErrorMsg: 'Failed to add Category. Please try again',
  categorySubmitSuccessMsg: 'Category added successfully'
}

const AddCategoryModal: FC<AddCategoryModalProps> = ({
  showCategoryModal,
  toggleCategoryModal,
  categoryTypes,
  categoryTypesFetchError,
  categoryTypesFetchSuccess,
  categorySubmitError,
  categorySubmitSuccess,
  fetchCategoryTypes,
  fetchCategoryTypesError,
  fetchCategoryTypesSuccess,
  submitNewCategory,
  submitNewCategoryError,
  submitNewCategorySuccess
}) => {
  const dispatch = useAppDispatch()

  const [category, setCategory]         = useState<string>('')
  const [categoryType, setCategoryType] = useState<string>('')
  const [fileRequired, setFileRequired] = useState<boolean>(false)
  const [fileObjects, setFileObjects]   = useState<File[]>([])
  const [isFormValid, setIsFormValid]   = useState<boolean>(false)

  useEffect(() => {
    dispatch(fetchCategoryTypes())
  }, [])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let fieldValue = event.target.value
    setCategory(fieldValue)

    if (fieldValue === '') {
      setIsFormValid(false)
    } else {
      if (fieldValue.length < 3) {
        setIsFormValid(false)
      } else {
        setIsFormValid(true)
      }
    }
  }

  const selectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setCategoryType(event.target.value)
  }

  const fileRequiredHandler = (status: boolean) => {
    setFileRequired(status)
  }

  const closeFileRequiredMsg = () => {
    fileRequiredHandler(false)
  }

  const handleOnAdd = (fileObjects: File[]) => {
    setFileObjects(fileObjects)
  }

  const handleFormValid = () => {
    setIsFormValid(true)
  }

  const handleFormInvalid = () => {
    setIsFormValid(false)
  }

  const formSubmitHandler = () => {
    let fileCount = fileObjects.length
    if (fileCount == 0) {
      fileRequiredHandler(true)
      return false
    }

    let formData = new FormData()
    formData.append('type_id', categoryType)
    formData.append('category', category)
    formData.append('status', '1')
    fileObjects.forEach((item, index) => {
      formData.append('image', item)
    })

    dispatch(submitNewCategory(formData))
    closeCategoryModal()
  }

  const closeCategoryModal = () => {
    setCategory('')
    setCategoryType('')
    setFileObjects([])
    toggleCategoryModal()
  }

  const closeCategorySubmitSuccessMsg = () => {
    dispatch(submitNewCategorySuccess(false))
  }

  const closeCategorySubmitErrorMsg = () => {
    dispatch(submitNewCategoryError(false))
  }

  return (
    <>
      <div
        className={'modal fade' + (showCategoryModal ? ' show d-block' : '')}
        aria-hidden={showCategoryModal ? 'false' : 'true'}
        aria-labelledby='modal-admin-add-category'
        tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 id='modal-admin-add-category'>Add Category</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeCategoryModal}>
                <i className="bi bi-x-circle-fill"></i>
              </div>
            </div>

            <div className='modal-body m-3 mx-xl-10'>
              <form noValidate id='form-admin-add-category'>
                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>New Category</span>
                  </label>
                  <input
                    type='text'
                    name='category'
                    value={category}
                    className='form-control form-control-lg form-control-solid'
                    autoComplete='off'
                    onChange={(e) => handleChange(e)} />

                  {
                    category && category.length < 3 &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>Minimum 3 letters required</div>
                    </div>
                  }
                </div>

                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Category Type</span>
                  </label>
                  <select
                    name='categoryType'
                    data-control='select2'
                    data-hide-search='true'
                    onChange={selectChange}
                    className='form-select form-select-lg form-select-solid'
                    defaultValue='Select'>
                    {
                      categoryTypes.length > 0 &&
                      categoryTypes.map(el =>
                        <option key={el._id} value={el._id}>{el.type}</option>
                      )
                    }
                  </select>
                </div>

                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Image Icon</span>
                  </label>
                  <DropzoneWrapper
                    filesLimit={1}
                    handleOnAdd={handleOnAdd}
                    dropzoneText='Upload Icon Image'
                    acceptedFileTypes='images' />
                </div>

                <div className='d-flex flex-stack'>
                  <div className='me-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary'
                      onClick={closeCategoryModal}>Cancel</button>
                  </div>
                  <div>
                    <button
                      type='button'
                      disabled={!isFormValid}
                      className='btn btn-sm btn-primary'
                      onClick={formSubmitHandler}>Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {
        showCategoryModal &&
        <div className="modal-backdrop fade show"></div>
      }

      {
        fileRequired &&
        showSnackbar(AddCategoryModalConstants.fileRequiredMsg, closeFileRequiredMsg)
      }
      {
        categorySubmitSuccess &&
        showSnackbar(AddCategoryModalConstants.categorySubmitSuccessMsg, closeCategorySubmitSuccessMsg)
      }
      {
        categorySubmitError &&
        showSnackbar(AddCategoryModalConstants.categorySubmitErrorMsg, closeCategorySubmitErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    showCategoryModal: ownProps.showCategoryModal,
    toggleCategoryModal: ownProps.toggleCategoryModal,
    categoryTypes: state.settingsReducer.categoryTypes,
    categoryTypesFetchError: state.settingsReducer.categoryTypesFetchError,
    categoryTypesFetchSuccess: state.settingsReducer.categoryTypesFetchSuccess,
    categorySubmitError: state.settingsReducer.categorySubmitError,
    categorySubmitSuccess: state.settingsReducer.categorySubmitSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AdminSettingsActions>) => {
  return {
    fetchCategoryTypes,
    fetchCategoryTypesError,
    fetchCategoryTypesSuccess,
    submitNewCategory,
    submitNewCategoryError,
    submitNewCategorySuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type AddCategoryModalProps = ConnectedProps<typeof connector>

export default connector(AddCategoryModal)
