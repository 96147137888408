import { Reducer }   from 'react'
import {
  KissanPostsActions,
  KissanPostsActionTypes,
  KissanPostsState } from '../../actions/kissan/posts.actions'

const initialState: KissanPostsState = {
  newPostsList: [],
  newPostsFetchError: false,
  newPostsFetchSuccess: false,
  kissanPost: null,
  kissanPostFetchError: false,
  kissanPostFetchSuccess: false,

  // error: false,
  // saveStatus: false,
  // updateStatus: false,
  // statusUpdateStatus: false,
  // postUpdateResponseSucess: false,
  // postUpdateResponseFail: false,
  // updateNewPostFail: false
}

const kissanPostsReducer: Reducer<KissanPostsState, KissanPostsActions> = function(state = initialState, action) {
  switch (action.type) {
    case KissanPostsActionTypes.FETCH_KISSAN_POSTS: {
      return {
        ...state,
        newPostsList: action.payload
      }
    }
    case KissanPostsActionTypes.FETCH_KISSAN_POSTS_ERROR: {
      return {
        ...state,
        newPostsFetchError: action.payload
      }
    }
    case KissanPostsActionTypes.FETCH_KISSAN_POSTS_SUCCESS: {
      return {
        ...state,
        newPostsFetchSuccess: action.payload
      }
    }
    case KissanPostsActionTypes.FETCH_KISSAN_POST: {
      return {
        ...state,
        kissanPost: action.payload
      }
    }
    case KissanPostsActionTypes.FETCH_KISSAN_POST_ERROR: {
      return {
        ...state,
        kissanPostFetchError: action.payload
      }
    }
    case KissanPostsActionTypes.FETCH_KISSAN_POST_SUCCESS: {
      return {
        ...state,
        kissanPostFetchSuccess: action.payload
      }
    }

    // case SAVE_NEW_POST: {
    //   return {
    //     ...state,
    //     saveStatus: action.payload
    //   }
    // }
    // case KISSAN_POST_EDIT: {
    //   return {
    //     ...state,
    //     data: action.payload
    //   }
    // }
    // case SET_ERROR: {
    //   return {
    //     ...state,
    //     error: action.payload
    //   }
    // }
    // case SET_SAVE_STATUS: {
    //   return {
    //     ...state,
    //     saveStatus: action.payload
    //   }
    // }
    // case KISSAN_POST_STATUS_UPDATE: {
    //   return {
    //     ...state,
    //     statusUpdateStatus: action.payload
    //   }
    // }
    // case KISSAN_POST_UPDATE_RESPONSE_SUCESS: {
    //   return {
    //     ...state,
    //     postUpdateResponseSucess: action.payload
    //   }
    // }
    // case KISSAN_POST_UPDATE_RESPONSE_FAIL: {
    //   return {
    //     ...state,
    //     postUpdateResponseFail: action.payload
    //   }
    // }
    // case UPDATE_NEW_POST: {
    //   return {
    //     ...state,
    //     updateStatus: action.payload
    //   }
    // }
    // case UPDATE_NEW_POST_FAIL: {
    //   return {
    //     ...state,
    //     updateNewPostFail: action.payload
    //   }
    // }
    default: {
      return state
    }
  }
}

export default kissanPostsReducer
