import { FC, useMemo, useState }         from 'react'
import { connect, ConnectedProps }       from 'react-redux'
import { ColumnInstance, Row, useTable } from 'react-table'
import { KTCardBody }                    from '../../../../../../../_metronic/helpers'
import { ListLoading }                   from '../../../../../../components/ListLoading'
import { RootState }                     from '../../../../../../store'
import { Location }                      from '../core/_models'
import { CustomHeaderColumn }            from '../table/columns/CustomHeaderColumn'
import { CustomRow }                     from '../table/columns/CustomRow'
import { locationColumns }               from './columns/_columns'

const LocationsTable: FC<LocationsTableProps> = ({ locations }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const columns = useMemo(() => locationColumns, [])
  const data    = useMemo(() => locations, [locations])

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({ columns, data })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='aanshik-admin-locations'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}>
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {
                headers.map((column: ColumnInstance<Location>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))
              }
            </tr>
          </thead>

          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {
              rows.length > 0 ? (
                rows.map((row: Row<Location>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={3}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>

      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    locations: state.settingsReducer.locations
  }
}

const connector = connect(mapStateToProps, null)
type LocationsTableProps = ConnectedProps<typeof connector>

export default connector(LocationsTable)
