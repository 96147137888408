// @ts-nocheck
import { FC }                      from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState }               from '../../../../../store'

const Partners: FC<PartnersProps> = ({
  propertyName,
  propertyAmount,
  partnerList,
  partnerListError,
  partnerListSuccess,
  partnerInterestIds,
  partnerShareAmounts,
  partnerSharePercentages,
  totalDealPartners,
  totalDealPercentage,
  handleArrayChange,
  prevStep,
  nextStep
}) => {
  const populatePartnerDetails = () => {
    return (
      <div className='table-responsive'>
        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th>Name</th>
              <th>Share Percentage</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
          {
            partnerList !== null &&
            partnerList.map((row, index) => (
              <tr key={index} tabIndex={-1}>
                <td className='text-dark fw-bold text-hover-primary fs-6'>
                  {row.name}
                  <input
                    type='hidden'
                    name={`partnerInterestIds[${index}]`}
                    value={partnerInterestIds.length > 0 ? partnerInterestIds[index] : ''} />
                </td>
                <td>
                  <input
                    type='text'
                    name={`partnerSharePercentages[${index}]`}
                    value={partnerSharePercentages[index]}
                    required
                    className='form-control form-control-sm'
                    onChange={(event) => handleArrayChange('partnerSharePercentages', index, event.target.value)} />
                </td>
                <td>
                  <input
                    type='text'
                    name={`partnerShareAmounts[${index}]`}
                    value={partnerShareAmounts[index]}
                    required
                    className='form-control form-control-sm'
                    onChange={(event) => handleArrayChange('partnerShareAmounts', index, event.target.value)} />
                </td>
              </tr>
            ))
          }
          </tbody>
          <tfoot>
            <tr className='fw-bold text-muted'>
              <td>Total Partners : {totalDealPartners}</td>
              <td>Total Percentage : {totalDealPercentage}</td>
              <td>Total Share Amount : {propertyAmount}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }

  return (
    <div>
      <div className='card'>
        <div className='card-body'>
          <div className='pb-10 pb-lg-10'>
            <h2 className='fw-bolder text-dark'>Partnership Details</h2>
          </div>

          <div className='row mb-10'>
            <div className='col-xl-4'>
              <label className='form-label mb-3'>Property Name</label>
              <input
                type='text'
                name='propertyName'
                value={propertyName.toUpperCase()}
                disabled
                className='form-control form-control-sm' />
            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-xl-12'>
            {
              partnerList.length > 0
                ? populatePartnerDetails()
                : <div>Partners not available</div>
            }
            </div>
          </div>
        </div>

        <div className='card-footer'>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              onClick={prevStep}
              className='btn btn-secondary'>
              <i className="bi bi-chevron-double-left pe-0"></i> Prev
            </button>
            <button
              type='button'
              onClick={nextStep}
              className='ms-2 btn btn-primary'>
              Save <i className="bi bi-save2-fill pe-0"></i>
            </button>
            <button
              type='button'
              onClick={nextStep}
              className='ms-2 btn btn-secondary'>
              Next <i className="bi bi-chevron-double-right pe-0"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    propertyName: ownProps.propertyName,
    propertyAmount: ownProps.propertyAmount,
    partnerList: ownProps.partnerList,
    partnerListError: ownProps.partnerListError,
    partnerListSuccess: ownProps.partnerListSuccess,
    partnerInterestIds: ownProps.partnerInterestIds,
    partnerShareAmounts: ownProps.partnerShareAmounts,
    partnerSharePercentages: ownProps.partnerSharePercentages,
    totalDealPartners: ownProps.totalDealPartners,
    totalDealPercentage: ownProps.totalDealPercentage,
    handleArrayChange: ownProps.handleArrayChange,
    prevStep: ownProps.prevStep,
    nextStep: ownProps.nextStep
  }
}

const connector = connect(mapStateToProps, null)
type PartnersProps = ConnectedProps<typeof connector>

export default connector(Partners)
