import { ChangeEvent, FC, useState } from 'react'
import { Dispatch }                  from 'redux'
import { connect, ConnectedProps }   from 'react-redux'
import { RootState }                 from '../../../../../store'
import { useAppDispatch }            from '../../../../../store/hooks'
import {
  AdminSettingsActions,
  submitNewLocation,
  submitNewLocationError,
  submitNewLocationSuccess }         from '../../../../../store/actions/admin/settings.action'
import { showSnackbar }              from '../../../../../helpers/SnackbarHelper'

const AddLocationModalConstants = {
  locationSubmitErrorMsg: 'Failed to add Location. Please try again',
  locationSubmitSuccessMsg: 'Location added successfully'
}

const AddLocationModal: FC<AddLocationModalProps> = ({
  showLocationModal,
  toggleLocationModal,
  locationSubmitError,
  locationSubmitSuccess,
  submitNewLocation,
  submitNewLocationError,
  submitNewLocationSuccess
}) => {
  const dispatch = useAppDispatch()

  const [location, setLocation]       = useState<string>('')
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let fieldValue = event.target.value
    setLocation(fieldValue)

    if (fieldValue === '') {
      setIsFormValid(false)
    } else {
      if (fieldValue.length < 3) {
        setIsFormValid(false)
      } else {
        setIsFormValid(true)
      }
    }
  }

  const handleFormValid = () => {
    setIsFormValid(true)
  }

  const handleFormInvalid = () => {
    setIsFormValid(false)
  }

  const handleFormSubmit = () => {
    let formData = new FormData()
    formData.append('location', location)
    formData.append('status', '1')

    dispatch(submitNewLocation(formData))
    closeLocationModal()
  }

  const closeLocationModal = () => {
    setLocation('')
    toggleLocationModal()
  }

  const closeLocationSubmitSuccessMsg = () => {
    dispatch(submitNewLocationSuccess(false))
  }

  const closeLocationSubmitErrorMsg = () => {
    dispatch(submitNewLocationError(false))
  }

  return (
    <>
      <div
        className={'modal fade' + (showLocationModal ? ' show d-block' : '')}
        aria-hidden={showLocationModal ? 'false' : 'true'}
        aria-labelledby='modal-admin-add-location'
        tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 id='modal-admin-add-location'>Add Location</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeLocationModal}>
                <i className="bi bi-x-circle-fill"></i>
              </div>
            </div>

            <div className='modal-body m-3 mx-xl-10'>
              <form noValidate id='form-admin-add-location'>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>New Location</span>
                  </label>
                  <input
                    type='text'
                    name='location'
                    value={location}
                    className='form-control form-control-lg form-control-solid'
                    autoComplete='off'
                    onChange={(e) => handleChange(e)} />

                  {
                    location && location.length < 3 &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>Minimum 3 letters required</div>
                    </div>
                  }
                </div>

                <div className='d-flex flex-stack'>
                  <div className='me-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary'
                      onClick={closeLocationModal}>Cancel</button>
                  </div>
                  <div>
                    <button
                      type='button'
                      disabled={!isFormValid}
                      className='btn btn-sm btn-primary'
                      onClick={handleFormSubmit}>Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {
        showLocationModal &&
        <div className="modal-backdrop fade show"></div>
      }

      {
        locationSubmitSuccess &&
        showSnackbar(AddLocationModalConstants.locationSubmitSuccessMsg, closeLocationSubmitSuccessMsg)
      }
      {
        locationSubmitError &&
        showSnackbar(AddLocationModalConstants.locationSubmitErrorMsg, closeLocationSubmitErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    showLocationModal: ownProps.showLocationModal,
    toggleLocationModal: ownProps.toggleLocationModal,
    locationSubmitError: state.settingsReducer.locationSubmitError,
    locationSubmitSuccess: state.settingsReducer.locationSubmitSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AdminSettingsActions>) => {
  return {
    submitNewLocation,
    submitNewLocationError,
    submitNewLocationSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type AddLocationModalProps = ConnectedProps<typeof connector>

export default connector(AddLocationModal)
