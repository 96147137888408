// @ts-nocheck
import { FC, useState }            from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState }               from '../../../../../store'
import Partner                     from './Partner'

const PartnerDetails: FC<PartnerDetailsProps> = ({
  partners,
  propertyTitle,
  partnershipId
}) => {
  const [selPartnerId, setSelPartnerId]         = useState<string>('')
  const [selPartnerData, setSelPartnerData]     = useState<{[key: string]: any}>({})
  const [showPartnerModal, setShowPartnerModal] = useState<boolean>(false)

  const viewPartnerDetails = (partnerData: any) => {
    setSelPartnerData(partnerData)
    togglePartnerModal()
  }

  const closePartnerModal = () => {
    setSelPartnerId('')
    togglePartnerModal()
  }

  const togglePartnerModal = () => {
    setShowPartnerModal(!showPartnerModal)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>PARTNERS</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row'>
            <div className='col-xl-12'>
            {
              partners.length > 0 &&
              partners.map((row, index) => (
                <div className='d-flex align-items-center' key={index}>
                  <div>{row.user_name}</div>
                  <div>
                    <button
                      onClick={() => viewPartnerDetails(row)}
                      className='btn btn-primary'>
                      Details
                    </button>
                  </div>
                </div>
              ))
            }
            </div>
          </div>
        </div>
      </div>

      {
        showPartnerModal &&
        <Partner
          partnerId={selPartnerId}
          partnerData={selPartnerData}
          propertyTitle={propertyTitle}
          partnershipId={partnershipId}
          showPartnerModal={showPartnerModal}
          closePartnerModal={closePartnerModal} />
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    partners: ownProps.partners,
    propertyTitle: ownProps.propertyTitle,
    partnershipId: ownProps.partnershipId
  }
}

const connector = connect(mapStateToProps, null)
type PartnerDetailsProps = ConnectedProps<typeof connector>

export default connector(PartnerDetails)
