import { FC, useState }                                  from 'react'
import ReactSimpleImageVideoLightbox, { ResourcersType } from '@santigp258/react-simple-lightbox-video-image'
import { getYoutubeEmbedURL }                            from '../../../../helpers/StringUtil'
import AdGallery                                         from '../../../../components/ad-gallery/AdGallery'

type PropertyDetailsProps = {
  data: {
    title: string
    image: string[]
    amount: string
    video_url: string
    description: string
    property_mode: string
  }
}

const PropertyDetails: FC<PropertyDetailsProps> = ({ data }) => {
  const [showImageVideoModal, setShowImageVideoModal] = useState<boolean>(false)

  const getPropertyMode = (mode: string) => {
    let modeTxt = ''

    switch (mode) {
      case '0':
        modeTxt = 'New'
        break
      case '1':
        modeTxt = 'Used'
        break
      case '2':
        modeTxt = 'Running'
        break
      default:
    }

    return modeTxt
  }

  const prepareLightboxData = () => {
    let lightboxData: ResourcersType[] = []

    lightboxData.push({
      url: getYoutubeEmbedURL(data.video_url),
      type: 'video',
      title: 'Ad Video',
      altTag: ''
    })

    data.image.forEach((image) => {
      lightboxData.push({
        url: image,
        type: 'photo',
        title: 'Ad Image',
        altTag: 'ad-image'
      })
    })

    return lightboxData
  }

  const openImageVideoModal = () => {
    setShowImageVideoModal(true)
  }

  const closeImageVideoModal = () => {
    setShowImageVideoModal(false)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>PROPERTY DETAILS</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Title</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-dark'>{data.title}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Amount</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-dark'>{data.amount}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Mode</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-dark'>{getPropertyMode(data.property_mode)}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Description</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-dark'>{data.description}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>AD GALLERY</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row'>
            <div className='col-xl-12'>
            {
              data.image &&
              <AdGallery
                images={data.image}
                videoUrl={data.video_url}
                openImageVideoModal={openImageVideoModal} />
            }
            </div>
          </div>
        </div>

        {
          data.image.length > 0 && showImageVideoModal &&
          <ReactSimpleImageVideoLightbox
            data={prepareLightboxData()}
            startIndex={0}
            showResourceCount={true}
            onCloseCallback={closeImageVideoModal} />
        }
      </div>
    </>
  )
}

export default PropertyDetails
