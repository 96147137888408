import { FC }                      from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState }               from '../../../../../store'
import AllottedDays                from './AllottedDays'
import PartnerDetails              from './PartnerDetails'
import PartnershipDetails          from './PartnershipDetails'
import PartnershipDocumentsDetails from './PartnershipDocumentsDetails'

const TimeBased: FC<TimeBasedProps> = ({
  images,
  videoUrl,
  partners,
  documents,
  categoryType,
  propertyTitle,
  partnershipId
}) => {
  return (
    <>
      <PartnershipDetails
        images={images}
        videoUrl={videoUrl}
        categoryType={categoryType}
        propertyTitle={propertyTitle} />
      <PartnerDetails
        partners={partners}
        propertyTitle={propertyTitle}
        partnershipId={partnershipId} />
      <PartnershipDocumentsDetails
        documents={documents}
        partnershipId={partnershipId} />
      <AllottedDays
        categoryType={categoryType}
        propertyTitle={propertyTitle}
        partnershipId={partnershipId} />
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    images: ownProps.images,
    videoUrl: ownProps.videoUrl,
    partners: ownProps.partners,
    documents: ownProps.documents,
    categoryType: ownProps.categoryType,
    propertyTitle: ownProps.propertyTitle,
    partnershipId: ownProps.partnershipId
  }
}

const connector = connect(mapStateToProps, null)
type TimeBasedProps = ConnectedProps<typeof connector>

export default connector(TimeBased)
