import { FC, useEffect, useState } from 'react'
import { Dispatch }                from 'react'
import { ChangeEvent, MouseEvent } from 'react'
import { Link, useNavigate }       from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import debounce                    from 'lodash/debounce'
import orderBy                     from 'lodash/orderBy'
import { KTCard, KTCardBody }      from '../../../../../_metronic/helpers'
import { PageTitle }               from '../../../../../_metronic/layout/core'
import { ListLoading }             from '../../../../components/ListLoading'
import ListPagination              from '../../../../components/ListPagination'
import { showSnackbar }            from '../../../../helpers/SnackbarHelper'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  ApprovedPost,
  InterestActions,
  fetchApprovedPostsList,
  fetchApprovedPostsListError }    from '../../../../store/actions/kissan/interest.actions'

const ApprovedPostsKissanConstants = {
  approvedPostsListErrorMsg: 'Error in fetching approved posts. Please try again'
}

const ApprovedPostsKissan: FC<ApprovedPostsKissanProps> = ({
  approvedPostsList,
  approvedPostsListError,
  fetchApprovedPostsList,
  fetchApprovedPostsListError
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading]         = useState<boolean>(false)
  const [columnToQuery, setColumnToQuery] = useState<string>('title')
  const [searchTerm, setSearchTerm]       = useState<string>('')
  const [adsList, setAdsList]             = useState<ApprovedPost[]>([])
  const [page, setPage]                   = useState<number>(0)
  const [rowsPerPage, setRowsPerPage]     = useState<number>(5)

  useEffect(() => {
    dispatch(fetchApprovedPostsList())
    setAdsList(approvedPostsList)
  }, [])

  useEffect(() => {
    if (searchTerm != '') {
      filterData()
    } else if (searchTerm == '') {
      setAdsList(approvedPostsList)
    }
  }, [searchTerm, columnToQuery])

  const filterData = () => {
    var debouncedSearch = debounce(() => {
      let currentList = [...approvedPostsList]
      let orderedList = orderBy(
        currentList.filter((x) =>
          x[columnToQuery as keyof ApprovedPost]
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ),
        columnToQuery, 'asc'
      )

      setAdsList(orderedList)
    }, 1000)

    debouncedSearch()
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const closeApprovedPostsListErrorMsg = () => {
    dispatch(fetchApprovedPostsListError(false))
  }

  const populateApprovedPostsList = () => {
    return (
      rowsPerPage > 0
        ? adsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : adsList
    ).map((row, index) => (
      <tr role='row' key={index}>
        <td role='cell'>
          <div>{(page + 1 - 1) * rowsPerPage + (index + 1)}</div>
        </td>
        <td role='cell'>{row.title}</td>
        <td role='cell'>{renderInvestorsCell(row.property_details_id)}</td>
        <td role='cell'>{row.farmer_name}</td>
        <td role='cell' className='text-end min-w-50px'>{renderActions(row.property_details_id)}</td>
      </tr>
    ))
  }

  const renderInvestorsCell = (propertyDetailsId: string) => {
    return (
      <Link
        to={`/kissan-approved-investors-list/${propertyDetailsId}`}
        title='Approved Users'
        className='btn btn-light btn-active-light-primary btn-sm'>
        <i className="bi bi-people-fill pe-0 fs-2"></i>
      </Link>
    )
  }

  const renderActions = (propertyDetailsId: string) => {
    return (
      <>
        <Link
          to={`/kissan-adds-initiate-to-deal/${propertyDetailsId}`}
          title='Initiate'
          className='btn btn-light btn-active-light-primary btn-sm'>
          <i className='bi bi-box-arrow-in-right pe-0 fs-2'></i>
        </Link>
        <Link
          to={`/kissan-approved-investors-list/${propertyDetailsId}`}
          title='Remove Investor'
          className='ms-2 btn btn-light btn-active-light-primary btn-sm'>
          <i className='bi bi-person-fill-x pe-0 fs-2'></i>
        </Link>
      </>
    )
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Approved List</PageTitle>

      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <select
              data-control='select2'
              data-hide-search='true'
              value={columnToQuery}
              onChange={(event) => setColumnToQuery(event.target.value)}
              className='form-select form-select-sm form-select-solid'>
              <option value="title">Crop Name</option>
              <option value="farmer_name">Farmer</option>
            </select>
            <div className='d-flex align-items-center position-relative my-1 ms-3'>
              <i className='bi bi-search position-absolute ms-3'></i>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid w-250px ps-10'
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)} />
            </div>
          </div>
        </div>

        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='aanshik-kissan-approved-list'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th role='columnheader' className='min-w-50px'>#</th>
                  <th role='columnheader' className='min-w-125px'>Crop Name</th>
                  <th role='columnheader'>Investors</th>
                  <th role='columnheader'>Farmer</th>
                  <th role='columnheader' className='min-w-50px'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600' role='rowgroup'>
              {
                adsList.length > 0 ? populateApprovedPostsList() : (
                  <tr>
                    <td colSpan={5}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )
              }
              </tbody>
              <tfoot>
                <tr>
                  <ListPagination
                    page={page}
                    count={approvedPostsList.length}
                    colSpan={5}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} />
                </tr>
              </tfoot>
            </table>
          </div>

          {isLoading && <ListLoading />}
        </KTCardBody>
      </KTCard>

      {
        approvedPostsListError &&
        showSnackbar(ApprovedPostsKissanConstants.approvedPostsListErrorMsg, closeApprovedPostsListErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    approvedPostsList: state.interestReducer.approvedPostsList,
    approvedPostsListError: state.interestReducer.approvedPostsListError
  }
}

const mapDispatchToProps = (dispatch: Dispatch<InterestActions>) => {
  return {
    fetchApprovedPostsList,
    fetchApprovedPostsListError
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ApprovedPostsKissanProps = ConnectedProps<typeof connector>

export default connector(ApprovedPostsKissan)
