// @ts-nocheck
import { FC, useState }            from 'react'
import { connect, ConnectedProps } from 'react-redux'
import
  ReactSimpleImageVideoLightbox,
  { ResourcersType }               from '@santigp258/react-simple-lightbox-video-image'
import { RootState }               from '../../../../../store'
import { getYoutubeEmbedURL }      from '../../../../../helpers/StringUtil'
import AdGallery                   from '../../../../../components/ad-gallery/AdGallery'

const PartnershipDetails: FC<PartnershipDetailsProps> = ({
  images,
  videoUrl,
  categoryType,
  propertyTitle
}) => {
  const [showImageVideoModal, setShowImageVideoModal] = useState<boolean>(false)

  const prepareLightboxData = () => {
    let lightboxData: ResourcersType[] = []

    lightboxData.push({
      url: getYoutubeEmbedURL(videoUrl),
      type: 'video',
      title: 'Ad Video',
      altTag: ''
    })

    images.forEach((img) => {
      lightboxData.push({
        url: img,
        type: 'photo',
        title: 'Ad Image',
        altTag: 'ad-image'
      })
    })

    return lightboxData
  }

  const openImageVideoModal = () => {
    setShowImageVideoModal(true)
  }

  const closeImageVideoModal = () => {
    setShowImageVideoModal(false)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>PARTNERSHIP DETAILS</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row'>
            <div className='col-xl-4'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Title</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-dark'>{propertyTitle}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Category</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-dark'>{categoryType}</span>
                </div>
              </div>
            </div>

            <div className='col-xl-8'>
            {
              images &&
              <AdGallery
                images={images}
                videoUrl={videoUrl}
                openImageVideoModal={openImageVideoModal} />
            }
            </div>
          </div>
        </div>
      </div>

      {
        images && showImageVideoModal &&
        <ReactSimpleImageVideoLightbox
          data={prepareLightboxData()}
          startIndex={0}
          showResourceCount={true}
          onCloseCallback={closeImageVideoModal} />
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    images: ownProps.images,
    videoUrl: ownProps.videoUrl,
    categoryType: ownProps.categoryType,
    propertyTitle: ownProps.propertyTitle
  }
}

const connector = connect(mapStateToProps, null)
type PartnershipDetailsProps = ConnectedProps<typeof connector>

export default connector(PartnershipDetails)
