import { FC, useState, useEffect }        from 'react'
import { Dispatch }                       from 'redux'
import { connect, ConnectedProps }        from 'react-redux'
import { useParams }                      from 'react-router-dom'
import { PageLink, PageTitle }            from '../../../../../_metronic/layout/core'
import { RootState }                      from '../../../../store'
import { useAppDispatch }                 from '../../../../store/hooks'
import {
  GoAdsActions, 
  fetchInterestedPersonsList,
  fetchInterestedPersonsListError,
  fetchInterestedPersonsListSuccess,
  submitGoInterestedPersonStatus,
  submitGoInterestedPersonStatusError,
  submitGoInterestedPersonStatusSuccess } from '../../../../store/actions/go/ads.actions'
import { showSnackbar }                   from '../../../../helpers/SnackbarHelper'
import InterestedPerson                   from './InterestedPerson'

const InterestedPersonsConstants = {
  interestedPersonsListErrorMsg: 'Error in fetching interested persons. Please try again',
  interestedPersonStatusUpdateErrorMsg: 'Error occured in approving interest',
  interestedPersonStatusUpdateSuccessMsg: 'Approved person successfully'
}

const breadcrumbs: Array<PageLink> = [{
  path: '/go-intrested-post-list',
  title: 'Interested Ads',
  isActive: false,
  isSeparator: false
}, {
  path: '',
  title: '',
  isActive: false,
  isSeparator: true
}]

const InterestedPersons: FC<InterestedPersonsProps> = ({
  interestedPersonsList,
  interestedPersonsListError,
  interestedPersonsListSuccess,
  interestedPersonsApproveStatusError,
  interestedPersonsApproveStatusSuccess,
  fetchInterestedPersonsList,
  fetchInterestedPersonsListError,
  fetchInterestedPersonsListSuccess,
  submitGoInterestedPersonStatus,
  submitGoInterestedPersonStatusError,
  submitGoInterestedPersonStatusSuccess
}) => {
  const params   = useParams()
  const dispatch = useAppDispatch()

  const [propertyDetailsId, setPropertyDetailsId] = useState<string>('')

  useEffect(() => {
    if (params.id) {
      setPropertyDetailsId(params.id)
      dispatch(fetchInterestedPersonsList(params.id))
    }
  }, [])

  useEffect(() => {
    if (interestedPersonsApproveStatusSuccess) {
      dispatch(fetchInterestedPersonsList(propertyDetailsId))
    }
  }, [interestedPersonsApproveStatusSuccess])

  const approvePerson = (interestId: string) => {
    let formData = new FormData()
    formData.append('approval_status', '1')

    dispatch(submitGoInterestedPersonStatus(formData, interestId))
  }

  const closeInterestedPersonsListErrorMsg = () => {
    dispatch(fetchInterestedPersonsListError(false))
  }

  const closeApprovalStatusSuccessMsg = () => {
    dispatch(submitGoInterestedPersonStatusSuccess(false))
  }

  const closeApprovalStatusErrorMsg = () => {
    dispatch(submitGoInterestedPersonStatusError(false))
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Interested Persons</PageTitle>

      {
        interestedPersonsList.length > 0 &&
        interestedPersonsList.map((row, index) => (
          <InterestedPerson
            key={index}
            person={row}
            approvePerson={approvePerson} />
        ))
      }

      {
        interestedPersonsListError &&
        showSnackbar(InterestedPersonsConstants.interestedPersonsListErrorMsg, closeInterestedPersonsListErrorMsg)
      }
      {
        interestedPersonsApproveStatusSuccess &&
        showSnackbar(InterestedPersonsConstants.interestedPersonStatusUpdateSuccessMsg, closeApprovalStatusSuccessMsg)
      }
      {
        interestedPersonsApproveStatusError &&
        showSnackbar(InterestedPersonsConstants.interestedPersonStatusUpdateErrorMsg, closeApprovalStatusErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    interestedPersonsList: state.goAdsReducer.interestedPersonsList,
    interestedPersonsListError: state.goAdsReducer.interestedPersonsListError,
    interestedPersonsListSuccess: state.goAdsReducer.interestedPersonsListSuccess,
    interestedPersonsApproveStatusError: state.goAdsReducer.interestedPersonsApproveStatusError,
    interestedPersonsApproveStatusSuccess: state.goAdsReducer.interestedPersonsApproveStatusSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GoAdsActions>) => {
  return {
    fetchInterestedPersonsList,
    fetchInterestedPersonsListError,
    fetchInterestedPersonsListSuccess,
    submitGoInterestedPersonStatus,
    submitGoInterestedPersonStatusError,
    submitGoInterestedPersonStatusSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type InterestedPersonsProps = ConnectedProps<typeof connector>

export default connector(InterestedPersons)
