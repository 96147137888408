import { FC, useState }            from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { showSnackbar }            from '../../../../../helpers/SnackbarHelper'
import { RootState }               from '../../../../../store'
import { useAppDispatch }          from '../../../../../store/hooks'
import {
  GoAdsActions,
  submitApplyOffer,
  submitApplyOfferError,
  submitApplyOfferSuccess }        from '../../../../../store/actions/go/ads.actions'

const OfferActionModalConstants = {
  applyOfferErrorMsg: 'Failed to apply offer. Please try again',
  applyOfferSuccessMsg: 'Offer applied successfully'
}

const OfferActionModal: FC<OfferActionModalProps> = ({
  adId,
  adTitle,
  showOfferActionModal,
  toggleOfferActionModal,
  applyOfferError,
  applyOfferSuccess,
  submitApplyOffer,
  submitApplyOfferError,
  submitApplyOfferSuccess
}) => {
  const dispatch = useAppDispatch()

  const [offerMode, setOfferMode] = useState<string>('0')

  const handleFormSubmit = () => {
    let formData = new FormData()
    formData.append('offer_mode', offerMode)

    dispatch(submitApplyOffer(formData, adId))
    closeOfferActionModal()
  }

  const closeOfferActionModal = () => {
    setOfferMode('0')
    toggleOfferActionModal()
  }

  const closeApplyOfferErrorMsg = () => {
    dispatch(submitApplyOfferError(false))
  }

  const closeApplyOfferSuccessMsg = () => {
    dispatch(submitApplyOfferSuccess(false))
  }

  return (
    <>
      <div
        className={'modal fade' + (showOfferActionModal ? ' show d-block' : '')}
        aria-hidden={showOfferActionModal ? 'false' : 'true'}
        aria-labelledby='modal-go-offer-apply-or-revoke'
        tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 id='modal-go-offer-apply-or-revoke'>Apply/Revoke Offer</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={toggleOfferActionModal}>
                <i className='bi bi-x-circle-fill'></i>
              </div>
            </div>

            <div className='modal-body m-3 mx-xl-10'>
              <form noValidate id='form-go-offer-apply-or-revoke'>
                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    Ad Title
                  </label>
                  <input
                    type='text'
                    name='title'
                    value={adTitle}
                    disabled
                    className='form-control form-control-solid' />
                </div>

                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Offer Mode</span>
                  </label>
                  <select
                    name='offerMode'
                    data-control='select2'
                    data-hide-search='true'
                    onChange={(event) => setOfferMode(event.target.value)}
                    className='form-select form-select-solid'
                    defaultValue='Status'>
                    <option value='0'>Revoke Offer</option>
                    <option value='1'>Apply Offer</option>
                  </select>
                </div>

                <div className='d-flex flex-stack'>
                  <div className='me-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary'
                      onClick={toggleOfferActionModal}>Cancel</button>
                  </div>
                  <div>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary'
                      onClick={handleFormSubmit}>Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {
        showOfferActionModal &&
        <div className='modal-backdrop fade show'></div>
      }

      {
        applyOfferSuccess &&
        showSnackbar(OfferActionModalConstants.applyOfferSuccessMsg, closeApplyOfferSuccessMsg)
      }
      {
        applyOfferError &&
        showSnackbar(OfferActionModalConstants.applyOfferErrorMsg, closeApplyOfferErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    adId: ownProps.adId,
    adTitle: ownProps.adTitle,
    showOfferActionModal: ownProps.showOfferActionModal,
    toggleOfferActionModal: ownProps.toggleOfferActionModal,
    applyOfferError: state.goAdsReducer.applyOfferError,
    applyOfferSuccess: state.goAdsReducer.applyOfferSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GoAdsActions>) => {
  return {
    submitApplyOffer,
    submitApplyOfferError,
    submitApplyOfferSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type OfferActionModalProps = ConnectedProps<typeof connector>

export default connector(OfferActionModal)
