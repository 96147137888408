// @ts-nocheck
import { FC }                      from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { toAbsoluteUrl }           from '../../../../../_metronic/helpers'
import { RootState }               from '../../../../store'

const PartnerRelieveRequest: FC<PartnerRelieveRequestProps> = ({ requestData, approveRelieveRequest }) => {
  const renderStatusButton = (partnershipId: string, partnerId: string, relievingStatus: string) => {
    if (relievingStatus == '0') {
      return (
        <button
          type='button'
          className='btn btn-primary' 
          onClick={() => approveRelieveRequest(partnershipId, partnerId)}>
          APPROVE
        </button>
      )
    } else if (relievingStatus == '1') {
      return (
        <button
          type='button'
          disabled
          className='btn btn-light-primary'>
          APPROVED
        </button>
      )
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-50px me-5'>
              <img src={toAbsoluteUrl('/media/avatars/300-6.jpg')} className='rounded-circle' alt='user-avatar' />
            </div>
            <div className='flex-grow-1'>
              <h4 className='fw-bolder m-0 pb-1'>{requestData.user_name}</h4>
              <h6 className='fw-semibold text-muted'>PARTNER</h6>
            </div>
          </div>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='row mb-5'>
              <label className='col-lg-3 fw-bold text-muted'>Share</label>
              <div className='col-lg-9'>
                <span className='fw-bold fs-6 text-dark'>{requestData.user_share_percentage}</span>
              </div>
            </div>
            <div className='row mb-5'>
              <label className='col-lg-3 fw-bold text-muted'>Amount</label>
              <div className='col-lg-9'>
                <span className='fw-bold fs-6 text-dark'>{requestData.user_share_amount}</span>
              </div>
            </div>
            <div className='row mb-8'>
              <label className='col-lg-3 fw-bold text-muted'>Days</label>
              <div className='col-lg-9'>
                <span className='fw-bold fs-6 text-dark'>{requestData.user_share_days}</span>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                {renderStatusButton(requestData.partnership_id, requestData.partner_id, requestData.relieving_status)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {     
    requestData: ownProps.requestData,
    approveRelieveRequest: ownProps.approveRelieveRequest
  }
}

const connector = connect(mapStateToProps, null)
type PartnerRelieveRequestProps = ConnectedProps<typeof connector>

export default connector(PartnerRelieveRequest)
