import axios           from 'axios'
import AppConfig       from '../../../../../appConfig'
import { AppDispatch } from '../../../../store'

/***** Action Interfaces *****/

interface FetchDealsListAction {
  type: typeof DealActionTypes.DEALS_LIST;
  payload: Deal[];
}
interface FetchDealsListFailureAction {
  type: typeof DealActionTypes.DEALS_LIST_ERROR;
  payload: boolean;
}
interface FetchDealsListSuccessAction {
  type: typeof DealActionTypes.DEALS_LIST_SUCCESS;
  payload: boolean;
}

interface UploadDealDocumentsFailureAction {
  type: typeof DealActionTypes.DEAL_DOCUMENTS_UPLOAD_ERROR;
  payload: boolean;
}
interface UploadDealDocumentsSuccessAction {
  type: typeof DealActionTypes.DEAL_DOCUMENTS_UPLOAD_SUCCESS;
  payload: boolean;
}

interface DropDealRequestFailureAction {
  type: typeof DealActionTypes.DROP_DEAL_REQUEST_ERROR;
  payload: boolean;
}
interface DropDealRequestSuccessAction {
  type: typeof DealActionTypes.DROP_DEAL_REQUEST_SUCCESS;
  payload: boolean;
}

/***** State, ActionTypes & Actions *****/

export interface Deal {
  property_id: number;
  kissan_deal_id: number;
  farmer_name: string;
  crop_name: string;
  cultivation_period: string;
  total_amount: number;
}

export interface DealState {
  dealsList: Deal[];
  dealsListError: boolean;
  dealsListSuccess: boolean;
  dealDocumentsUploadError: boolean;
  dealDocumentsUploadSuccess: boolean;
  dropDealRequestError: boolean;
  dropDealRequestSuccess: boolean;

  // investorRelievingRequests: [];
  // investorRelievingRequestsError: boolean;
  // investorRelievingRequestsApproveSuccess: boolean;
  // investorRelievingRequestsApproveError: boolean;
  // farmerRelievingRequestsApproveSuccess: boolean;
  // farmerRelievingRequestsApproveError: boolean;
  // farmerRelievingRequests: [];
  // farmerRelievingRequestsError: boolean;
  // farmerRelievingRequestsSuccess: boolean;
  // dealDetail: null | Deal;
  // dealDetailFetchError: boolean;
  // dealDetailFetchSuccess: boolean;
  // dealStopActionAdminSuccess: boolean;
  // dealStopActionAdminError: boolean;
  // dealTimelineAddError: boolean;
  // dealTimelineAddSuccess: boolean;
}

export const DealActionTypes = {
  DEALS_LIST                    : 'DEALS_LIST',
  DEALS_LIST_ERROR              : 'DEALS_LIST_ERROR',
  DEALS_LIST_SUCCESS            : 'DEALS_LIST_SUCCESS',
  DEAL_DOCUMENTS_UPLOAD_ERROR   : 'DEAL_DOCUMENTS_UPLOAD_ERROR',
  DEAL_DOCUMENTS_UPLOAD_SUCCESS : 'DEAL_DOCUMENTS_UPLOAD_SUCCESS',
  DROP_DEAL_REQUEST_ERROR       : 'DROP_DEAL_REQUEST_ERROR',
  DROP_DEAL_REQUEST_SUCCESS     : 'DROP_DEAL_REQUEST_SUCCESS',

  // INVESTOR_RELIEVING_REQUESTS: 'INVESTOR_RELIEVING_REQUESTS',
  // INVESTOR_RELIEVING_REQUESTS_ERROR: 'INVESTOR_RELIEVING_REQUESTS_ERROR',
  // INVESTOR_RELIEVING_REQUESTS_APPROVE: 'INVESTOR_RELIEVING_REQUESTS_APPROVE',
  // INVESTOR_RELIEVING_REQUESTS_APPROVE_SUCCESS: 'INVESTOR_RELIEVING_REQUESTS_APPROVE_SUCCESS',
  // INVESTOR_RELIEVING_REQUESTS_APPROVE_ERROR: 'INVESTOR_RELIEVING_REQUESTS_APPROVE_ERROR',
  // FARMER_RELIEVING_REQUESTS_APPROVE_SUCCESS: 'FARMER_RELIEVING_REQUESTS_APPROVE_SUCCESS',
  // FARMER_RELIEVING_REQUESTS_APPROVE_ERROR: 'FARMER_RELIEVING_REQUESTS_APPROVE_ERROR',
  // FARMER_RELIEVING_REQUESTS: 'FARMER_RELIEVING_REQUESTS',
  // FARMER_RELIEVING_REQUESTS_ERROR: 'FARMER_RELIEVING_REQUESTS_ERROR',
  // FARMER_RELIEVING_REQUESTS_SUCCESS: 'FARMER_RELIEVING_REQUESTS_SUCCESS',
  // DETAILS_VIEW: 'DETAILS_VIEW',
  // DETAILS_VIEW_SUCCESS: 'DETAILS_VIEW_SUCCESS',
  // DETAILS_VIEW_ERROR: 'DETAILS_VIEW_ERROR',
  // DEAL_STOP_ACTION_SUBMIT_SUCCESS: 'DEAL_STOP_ACTION_SUBMIT_SUCCESS',
  // DEAL_STOP_ACTION_SUBMIT_ERROR: 'DEAL_STOP_ACTION_SUBMIT_ERROR',
  // TIMELINE_ADD_SUCCESS: 'TIMELINE_ADD_SUCCESS',
  // TIMELINE_ADD_ERROR: 'TIMELINE_ADD_ERROR',
} as const

export type DealActions =
  | FetchDealsListAction
  | FetchDealsListFailureAction
  | FetchDealsListSuccessAction
  | UploadDealDocumentsFailureAction
  | UploadDealDocumentsSuccessAction
  | DropDealRequestFailureAction
  | DropDealRequestSuccessAction

/********** API Calls **********/

/* GET DEALS LIST */

export function fetchDealsList() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_DEAL_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: DealActionTypes.DEALS_LIST,
        payload: response.data.kissandeal
      })
    })
    .catch((error) => {
      dispatch(fetchDealsListError(true))
    })
  }
}
export function fetchDealsListError(status: boolean) {
  return {
    type: DealActionTypes.DEALS_LIST_ERROR,
    payload: status
  }
}
export function fetchDealsListSuccess(status: boolean) {
  return {
    type: DealActionTypes.DEALS_LIST_SUCCESS,
    payload: status
  }
}

/* UPLOAD KISSAN DEAL DOCUMENTS */

export function uploadKissanDealDocuments(formData: any) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_DEAL_DOCUMENT_SUBMIT

    return axios.post(url, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(uploadKissanDealDocumentsSuccess(true))
    })
    .catch((error) => {
      dispatch(uploadKissanDealDocumentsError(true))
    })
  }
}
export function uploadKissanDealDocumentsError(status: boolean) {
  return {
    type: DealActionTypes.DEAL_DOCUMENTS_UPLOAD_ERROR,
    payload: status
  }
}
export function uploadKissanDealDocumentsSuccess(status: boolean) {
  return {
    type: DealActionTypes.DEAL_DOCUMENTS_UPLOAD_SUCCESS,
    payload: status
  }
}

/* SUBMIT DROP DEAL REQUEST */

export function submitDropDealRequest(formData: any, id: number) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_DEAL_DROPP_REQ_INIT

    return axios.post(`${url}/${id}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => { 
      return dispatch(submitDropDealRequestSuccess(true))
    })
    .catch((error) => {
      return dispatch(submitDropDealRequestError(true))
    })
  }
}
export function submitDropDealRequestError(status: boolean) {
  return {
    type: DealActionTypes.DROP_DEAL_REQUEST_ERROR,
    payload: status
  }
}
export function submitDropDealRequestSuccess(status: boolean) {
  return {
    type: DealActionTypes.DROP_DEAL_REQUEST_SUCCESS,
    payload: status
  }
}

// export function fetchInvestoReleivingRequestsList(id: number) {
//   return (dispatch: AppDispatch) => {
//     var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_INVESTOR_RELIEVING_REQUESTS

//     return axios.get(`${url}/${id}`, {
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
//       }
//     })
//     .then((response) => {
//       return dispatch({
//         type: DealActionTypes.INVESTOR_RELIEVING_REQUESTS,
//         payload: response.data.investorrequest
//       })
//     })
//     .catch((error) => {
//       return dispatch(fetchInvestoReleivingRequestsListError(true))
//     })
//   }
// }

// export function fetchInvestoReleivingRequestsListError(status: boolean) {
//   return {
//     type: DealActionTypes.INVESTOR_RELIEVING_REQUESTS_ERROR,
//     payload: status
//   }
// }

// export function submitInvestoReleivingRequests(formData: any, id: number) {
//   return (dispatch: AppDispatch) => {
//     var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_INVESTOR_RELIEVING_REQUESTS_APPROVE

//     return axios.post(`${url}/${id}`, formData, {
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'multipart/form-data',
//         'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
//       }
//     })
//     .then((response) => {
//       return dispatch(submitInvestoReleivingRequestsSuccess(true))
//     })
//     .catch((error) => {
//       return dispatch(submitInvestoReleivingRequestsError(true))
//     })
//   }
// }

// export function submitInvestoReleivingRequestsError(status: boolean) {
//   return {
//     type: DealActionTypes.INVESTOR_RELIEVING_REQUESTS_APPROVE_ERROR,
//     payload: status
//   }
// }

// export function submitInvestoReleivingRequestsSuccess(status: boolean) {
//   return {
//     type: DealActionTypes.INVESTOR_RELIEVING_REQUESTS_APPROVE_SUCCESS,
//     payload: status
//   }
// }

// export function submitFarmerReleivingRequests(formData: any, id: number) {
//   return (dispatch: AppDispatch) => {
//     var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_FARMER_RELIEVING_REQUESTS_APPROVE

//     return axios.post(`${url}/${id}`, formData, {
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'multipart/form-data',
//         'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
//       }
//     })
//     .then((response) => {
//       return dispatch(submitFarmerReleivingRequestsSuccess(true))
//     })
//     .catch((error) => {
//       return dispatch(submitFarmerReleivingRequestsError(true))
//     })
//   }
// }

// export function submitFarmerReleivingRequestsError(status: boolean) {
//   return {
//     type: DealActionTypes.FARMER_RELIEVING_REQUESTS_APPROVE_ERROR,
//     payload: status
//   }
// }

// export function submitFarmerReleivingRequestsSuccess(status: boolean) {
//   return {
//     type: DealActionTypes.FARMER_RELIEVING_REQUESTS_APPROVE_SUCCESS,
//     payload: status
//   }
// }

// export function fetchFarmerReleivingRequestsList(id: number) {
//   return (dispatch: AppDispatch) => {
//     var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_FARMER_RELIEVING_REQUESTS

//     return axios.get(`${url}/${id}`, {
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
//       }
//     })
//     .then((response) => {
//       dispatch({
//         type: DealActionTypes.FARMER_RELIEVING_REQUESTS,
//         payload: response.data.farmerrequest
//       })
//       dispatch(fetchFarmerReleivingRequestsListSuccess(true))
//     })
//     .catch((error) => {
//       return dispatch(fetchFarmerReleivingRequestsListError(true))
//     })
//   }
// }

// export function fetchFarmerReleivingRequestsListError(status: boolean) {
//   return {
//     type: DealActionTypes.FARMER_RELIEVING_REQUESTS_ERROR,
//     payload: status
//   }
// }

// export function fetchFarmerReleivingRequestsListSuccess(status: boolean) {
//   return {
//     type: DealActionTypes.FARMER_RELIEVING_REQUESTS_SUCCESS,
//     payload: status
//   }
// }

/* FETCH DEAL DETAILS */

// export function fetchDetails(id: number) {
//   return (dispatch: AppDispatch) => {
//     var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_DEAL_DETAILS_VIEW

//     return axios.get(`${url}/${id}`, {
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
//       }
//     })
//     .then((response) => {
//       dispatch({
//         type: DealActionTypes.DETAILS_VIEW,
//         payload: response.data.kissandeal
//       })
//       dispatch(fetchDetailsSuccess(true))
//     })
//     .catch((error) => {
//       return dispatch(fetchDetailsError(true))
//     })
//   }
// }

// export function fetchDetailsError(status: boolean) {
//   return {
//     type: DealActionTypes.DETAILS_VIEW_ERROR,
//     payload: status
//   }
// }

// export function fetchDetailsSuccess(status: boolean) {
//   return {
//     type: DealActionTypes.DETAILS_VIEW_SUCCESS,
//     payload: status
//   }
// }

/* UPDATE DEAL */

// export function submitAdminActionDealStop(formData: any, id: number) {
//   return (dispatch: AppDispatch) => {
//     var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_DEAL_ADMIN_STOP_ACTION

//     return axios.post(`${url}/${id}`, formData, {
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'multipart/form-data',
//         'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
//       }
//     })
//     .then((response) => {
//       dispatch({
//         type: DealActionTypes.DEAL_STOP_ACTION_SUBMIT_SUCCESS,
//         payload: true
//       })
//       dispatch(submitAdminActionDealStopSuccess(true))
//     })
//     .catch((error) => {
//       return dispatch(submitAdminActionDealStopError(true))
//     })
//   }
// }

// export function submitAdminActionDealStopError(status: boolean) {
//   return {
//     type: DealActionTypes.DEAL_STOP_ACTION_SUBMIT_ERROR,
//     payload: status
//   }
// }

// export function submitAdminActionDealStopSuccess(status: boolean) {
//   return {
//     type: DealActionTypes.DEAL_STOP_ACTION_SUBMIT_SUCCESS,
//     payload: status
//   }
// }

/* TIMELINE */

// export function submitTimelineEntry(formData: any) {
//   return (dispatch: AppDispatch) => {
//     var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_DEAL_TIMELINE_ADD

//     return axios.post(url, formData, {
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'multipart/form-data',
//         'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
//       }
//     })
//     .then((response) => {
//       dispatch({
//         type: DealActionTypes.TIMELINE_ADD_SUCCESS,
//         payload: true
//       })
//       dispatch(submitTimelineEntrySuccess(true))
//     })
//     .catch((error) => {
//       return dispatch(submitTimelineEntryError(true))
//     })
//   }
// }

// export function submitTimelineEntryError(status: boolean) {
//   return {
//     type: DealActionTypes.TIMELINE_ADD_ERROR,
//     payload: status
//   }
// }

// export function submitTimelineEntrySuccess(status: boolean) {
//   return {
//     type: DealActionTypes.TIMELINE_ADD_SUCCESS,
//     payload: status
//   }
// }
