// @ts-nocheck
import { FC, useEffect }           from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { KTCard }                  from '../../../../../_metronic/helpers'
import { PageTitle }               from '../../../../../_metronic/layout/core'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  AdminSettingsActions,
  fetchCategories,
  fetchCategoriesError,
  fetchCategoriesSuccess }         from '../../../../store/actions/admin/settings.action'
import { showSnackbar }            from '../../../../helpers/SnackbarHelper'
import { ListHeader }              from './list/components/header/ListHeader'
import CategoriesTable             from './list/table/CategoriesTable'

const CategoriesConstants = {
  fetchCategoriesErrorMsg: 'Error in fetching categories. Please try again'
}

const Categories: FC<CategoriesProps> = ({
  categoriesFetchError,
  categoriesFetchSuccess,
  categorySubmitSuccess,
  fetchCategories,
  fetchCategoriesError,
  fetchCategoriesSuccess
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchCategories())
  }, [])

  useEffect(() => {
    if (categorySubmitSuccess) {
      dispatch(fetchCategories())
    }
  }, [categorySubmitSuccess])

  const closeFetchCategoriesErrorMsg = () => {
    dispatch(fetchCategoriesError(false))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Categories</PageTitle>

      <KTCard>
        <ListHeader />
        <CategoriesTable />
      </KTCard>

      {
        categoriesFetchError &&
        showSnackbar(CategoriesConstants.fetchCategoriesErrorMsg, closeFetchCategoriesErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    categoriesFetchError: state.settingsReducer.categoriesFetchError,
    categoriesFetchSuccess: state.settingsReducer.categoriesFetchSuccess,
    categorySubmitSuccess: state.settingsReducer.categorySubmitSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AdminSettingsActions>) => {
  return {
    fetchCategories,
    fetchCategoriesError,
    fetchCategoriesSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type CategoriesProps = ConnectedProps<typeof connector>

export default connector(Categories)
