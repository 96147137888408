import { FC } from 'react'

type CustomIndexCellProps = {
  rowNo: number
}

const CustomIndexCell: FC<CustomIndexCellProps> = ({ rowNo }) => (
  <div>{rowNo + 1}</div>
)

export { CustomIndexCell }
