import { FC, useEffect, useState } from 'react'
import { Dispatch }                from 'react'
import { ChangeEvent, MouseEvent } from 'react'
import { Link, useNavigate }       from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import debounce                    from 'lodash/debounce'
import orderBy                     from 'lodash/orderBy'
import { KTCard, KTCardBody }      from '../../../../../_metronic/helpers'
import { PageTitle }               from '../../../../../_metronic/layout/core'
import { ListLoading }             from '../../../../components/ListLoading'
import ListPagination              from '../../../../components/ListPagination'
import { showSnackbar }            from '../../../../helpers/SnackbarHelper'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  InterestedPost,
  InterestActions,
  fetchInterestedPostsList,
  fetchInterestedPostsListError }  from '../../../../store/actions/kissan/interest.actions'

const InterestedPostsKissanConstants = {
  kissanPostsListError: 'Error in fetching interested posts. Please try again'
}

const InterestedPostsKissan: FC<InterestedPostsKissanProps> = ({
  interestedPostsList,
  interestedPostsListError,
  fetchInterestedPostsList,
  fetchInterestedPostsListError
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading]         = useState<boolean>(false)
  const [columnToQuery, setColumnToQuery] = useState<string>('crop_name')
  const [searchTerm, setSearchTerm]       = useState<string>('')
  const [adsList, setAdsList]             = useState<InterestedPost[]>([])
  const [page, setPage]                   = useState<number>(0)
  const [rowsPerPage, setRowsPerPage]     = useState<number>(5)

  useEffect(() => {
    dispatch(fetchInterestedPostsList())
    setAdsList(interestedPostsList)
  }, [])

  useEffect(() => {
    if (searchTerm != '') {
      filterData()
    } else if (searchTerm == '') {
      setAdsList(interestedPostsList)
    }
  }, [searchTerm, columnToQuery])

  const filterData = () => {
    var debouncedSearch = debounce(() => {
      let currentList = [...interestedPostsList]
      let orderedList = orderBy(
        currentList.filter((x) =>
          x[columnToQuery as keyof InterestedPost]
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ),
        columnToQuery, 'asc'
      )

      setAdsList(orderedList)
    }, 1000)

    debouncedSearch()
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const closeInterestedPostsListErrorMsg = () => {
    dispatch(fetchInterestedPostsListError(false))
  }

  const populateInterestedPostsList = () => {
    return (
      rowsPerPage > 0
        ? adsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : adsList
    ).map((row, index) => (
      <tr role='row' key={index}>
        <td role='cell'>
          <div>{(page + 1 - 1) * rowsPerPage + (index + 1)}</div>
        </td>
        <td role='cell'>{row.crop_name}</td>
        <td role='cell'>{row.farmer_name}</td>
        <td role='cell'>{row.location}</td>
        <td role='cell'>{row.price}</td>
        <td role='cell' className='text-center min-w-50px'>{renderActions(row._id, row.interest_count)}</td>
      </tr>
    ))
  }

  const renderActions = (propertyDetailsId: string, interestCount: number) => {
    return (
      <button
        type='button'
        title='View Interested Persons'
        onClick={() => viewInterestedPersons(propertyDetailsId, interestCount)}
        className='btn btn-sm btn-light btn-active-light-primary position-relative'>
        <i className="bi bi-people-fill pe-0"></i>
        <span className="position-absolute top-0 start-100 translate-middle badge badge-circle bg-danger">
          {interestCount}
        </span>
      </button>
    )
  }

  const viewInterestedPersons = (propertyDetailsId: string, interestCount: number) => {
    if (interestCount > 0) {
      navigate(`/kissan-interested-person-lists/${propertyDetailsId}`)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Interested List</PageTitle>

      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <select
              data-control='select2'
              data-hide-search='true'
              value={columnToQuery}
              onChange={(event) => setColumnToQuery(event.target.value)}
              className='form-select form-select-sm form-select-solid'>
              <option value="crop_name">Crop Name</option>
              <option value="farmer_name">Farmer Name</option>
              <option value="location">Location</option>
              <option value="price">Amount</option>
            </select>
            <div className='d-flex align-items-center position-relative my-1 ms-3'>
              <i className='bi bi-search position-absolute ms-3'></i>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid w-250px ps-10'
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)} />
            </div>
          </div>
        </div>

        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='aanshik-kissan-interested-list'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th role='columnheader' className='min-w-50px'>#</th>
                  <th role='columnheader' className='min-w-125px'>Crop Name</th>
                  <th role='columnheader'>Farmer Name</th>
                  <th role='columnheader'>Location</th>
                  <th role='columnheader'>Amount</th>
                  <th role='columnheader' className='min-w-50px'>Interested</th>
                </tr>
              </thead>
              <tbody className='text-gray-600' role='rowgroup'>
              {
                adsList.length > 0 ? populateInterestedPostsList() : (
                  <tr>
                    <td colSpan={6}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )
              }
              </tbody>
              <tfoot>
                <tr>
                  <ListPagination
                    page={page}
                    count={interestedPostsList.length}
                    colSpan={6}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} />
                </tr>
              </tfoot>
            </table>
          </div>

          {isLoading && <ListLoading />}
        </KTCardBody>
      </KTCard>

      {
        interestedPostsListError &&
        showSnackbar(InterestedPostsKissanConstants.kissanPostsListError, closeInterestedPostsListErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    interestedPostsList: state.interestReducer.interestedPostsList,
    interestedPostsListError: state.interestReducer.interestedPostsListError
  }
}

const mapDispatchToProps = (dispatch: Dispatch<InterestActions>) => {
  return {
    fetchInterestedPostsList,
    fetchInterestedPostsListError
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type InterestedPostsKissanProps = ConnectedProps<typeof connector>

export default connector(InterestedPostsKissan)
