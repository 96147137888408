import { FC } from 'react'
import             './AdGallery.css'

type AdGalleryProps = {
  images: string[]
  videoUrl: string
  openImageVideoModal: () => void
}

const AdGallery: FC<AdGalleryProps> = ({images, videoUrl, openImageVideoModal}) => {
  const renderVideoThumbnail = () => {
    return (
      <div className='pg-6ef4-ad-video-wrapper' onClick={openImageVideoModal}>
        <img src={images[0]} alt={images[0]} />
        <div className='pg-6ef4-video-play-icon'></div>
      </div>
    )
  }

  const renderImageThumbnails = () => {
    return (
      images.map((item, index) => (
        <div key={index} className='pg-6ef4-ad-video-wrapper' onClick={openImageVideoModal}>
          <img src={item} alt={item} />
        </div>
      ))
    )
  }

  return (
    <div className='pg-6ef4-ad-gallery-wrapper'>
      {videoUrl && renderVideoThumbnail()}
      {renderImageThumbnails()}
    </div>
  )
}

export default AdGallery
