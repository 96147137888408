import { FC, FormEvent }                     from 'react'
import { useEffect, useRef, useState }       from 'react'
import { Dispatch }                          from 'redux'
import { connect, ConnectedProps }           from 'react-redux'
import { useNavigate }                       from 'react-router-dom'
import { RootState }                         from '../../../../../store'
import { useAppDispatch }                    from '../../../../../store/hooks'
import {
  PartnershipActions,
  submitPartnershipDocumentsDetails,
  submitPartnershipDocumentsDetailsError,
  submitPartnershipDocumentsDetailsSuccess } from '../../../../../store/actions/go/partnership.actions'
import DropzoneWrapper                       from '../../../../../components/DropzoneWrapper'
import { showSnackbar1 }                     from '../../../../../helpers/SnackbarHelper1'

const DocumentsConstants = {
  noDocumentAlertMessage: 'Atleast one document is required',
  documentSaveErrorMessage: 'Error in saving partnership documents. Please try again',
  documentSaveSuccessMessage: 'Saved partnership documents successfully'
}

const Documents: FC<DocumentsProps> = ({
  propertyId,
  propertyName,
  partnershipId,
  prevStep,
  partnershipDocumentsDetails,
  partnershipDocumentsDetailsError,
  partnershipDocumentsDetailsSuccess,
  submitPartnershipDocumentsDetails,
  submitPartnershipDocumentsDetailsError,
  submitPartnershipDocumentsDetailsSuccess
}) => {
  const formRef  = useRef<HTMLFormElement | null>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isFormValid, setIsFormValid]                   = useState<boolean>(false)
  const [showNoDocumentAlert, setShowNoDocumentAlert]   = useState<boolean>(false)
  const [documentTitle, setDocumentTitle]               = useState<string>('')
  const [documentDescription, setDocumentDescription]   = useState<string>('')
  const [partnershipDocuments, setPartnershipDocuments] = useState<File[]>([])

  useEffect(() => {
    if (partnershipDocumentsDetailsSuccess) {
      showSnackbar1(DocumentsConstants.documentSaveSuccessMessage)
      hideDocumentSaveSuccessMsg()
      setTimeout(() => {
        navigate('/go-partnership-list')
      }, 2000)
    }
  }, [partnershipDocumentsDetailsSuccess])

  useEffect(() => {
    if (partnershipDocumentsDetailsError) {
      showSnackbar1(DocumentsConstants.documentSaveErrorMessage)
      hideDocumentSaveErrorMsg()
    }
  }, [partnershipDocumentsDetailsError])

  useEffect(() => {
    if (showNoDocumentAlert) {
      showSnackbar1(DocumentsConstants.noDocumentAlertMessage)
      hideNoDocumentAlert()
    }
  }, [showNoDocumentAlert])

  const handleOnAddDocuments = (documents: File[]) => {
    if (documents.length > 0) {
      setPartnershipDocuments(documents)
      setIsFormValid(true)
    } else {
      setIsFormValid(false)
    }
  }

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    savePartnershipDocumentDetails()
  }

  const savePartnershipDocumentDetails = () => {
    if (partnershipDocuments.length == 0) {
      setShowNoDocumentAlert(true)
      return false
    }

    var formData = new FormData()
    formData.append('document_name', documentTitle)
    formData.append('description', documentDescription)
    partnershipDocuments.forEach((item, index) => {
      formData.append('document', item)
    })

    dispatch(submitPartnershipDocumentsDetails(formData, propertyId, partnershipId))
  }

  const hideDocumentSaveErrorMsg = () => {
    dispatch(submitPartnershipDocumentsDetailsError(false))
  }

  const hideDocumentSaveSuccessMsg = () => {
    dispatch(submitPartnershipDocumentsDetailsSuccess(false))
  }

  const hideNoDocumentAlert = () => {
    setShowNoDocumentAlert(false)
  }

  return (
    <div className='card'>
      <form
        ref={formRef}
        onSubmit={handleFormSubmit}>
        <div className='card-body'>
          <div className='pb-10 pb-lg-10'>
            <h2 className='fw-bolder text-dark'>Partnership Documents</h2>
          </div>

          <div className='row mb-5'>
            <div className='col-xl-4'>
              <label className='form-label mb-3'>Property Name</label>
              <input
                type='text'
                name='propertyName'
                value={propertyName.toUpperCase()}
                disabled
                className='form-control form-control-sm' />
            </div>
            <div className='col-xl-8'>
              <label className='form-label mb-3'>
                <span className='required'>Document Title</span>
              </label>
              <input
                type='text'
                name='documentTitle'
                value={documentTitle}
                required
                onChange={(event) => setDocumentTitle(event.target.value)}
                className='form-control form-control-sm' />
            </div>
          </div>

          <div className='row mb-7'>
            <div className='col-xl-12'>
              <label className='form-label mb-3'>
                <span className='required'>Document Description</span>
              </label>
              <textarea
                rows={4}
                name='documentDescription'
                required
                onChange={(event) => setDocumentDescription(event.target.value)}
                className='form-control form-control-sm'>
              </textarea>
            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-xl-12'>
              <DropzoneWrapper
                filesLimit={5}
                handleOnAdd={handleOnAddDocuments}
                dropzoneText='Upload Documents'
                acceptedFileTypes='documents' />
            </div>
          </div>
        </div>

        <div className='card-footer'>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              onClick={prevStep}
              className='btn btn-secondary'>
              <i className='bi bi-chevron-double-left pe-0'></i> Prev
            </button>
            <button
              type='submit'
              disabled={!isFormValid}
              className='ms-2 btn btn-primary'>
              Finish
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    propertyId: ownProps.propertyId,
    propertyName: ownProps.propertyName,
    partnershipId: ownProps.partnershipId,
    prevStep: ownProps.prevStep,
    partnershipDocumentsDetails: state.partnershipReducer.partnershipDocumentsDetails,
    partnershipDocumentsDetailsError: state.partnershipReducer.partnershipDocumentsDetailsError,
    partnershipDocumentsDetailsSuccess: state.partnershipReducer.partnershipDocumentsDetailsSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<PartnershipActions>) => {
  return {
    submitPartnershipDocumentsDetails,
    submitPartnershipDocumentsDetailsError,
    submitPartnershipDocumentsDetailsSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type DocumentsProps = ConnectedProps<typeof connector>

export default connector(Documents)
