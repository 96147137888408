import Snackbar from 'awesome-snackbar'

function showSnackbar(message: string, afterHide: () => void) {
  new Snackbar(message, {
    position: 'bottom-center',
    afterHide: afterHide
  })
}

export { showSnackbar }
