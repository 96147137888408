import { Reducer }            from 'react'
import {
  PartnershipTimeBasedActions,
  PartnershipTimeBasedActionTypes,
  PartnershipTimeBasedState } from '../../actions/go/partnershipTimeBased.actions'

const initialState: PartnershipTimeBasedState = {
  approvedPartners: [],
  approvedPartnersError: false,
  approvedPartnersSuccess: false
}

const partnershipTimeBasedReducer: Reducer<PartnershipTimeBasedState, PartnershipTimeBasedActions> = (state = initialState, action) => {
  switch (action.type) {
    case PartnershipTimeBasedActionTypes.APPROVED_PARTNERS: {
      return {
        ...state,
        approvedPartners: action.payload,
        approvedPartnersSuccess: true
      }
    }
    case PartnershipTimeBasedActionTypes.APPROVED_PARTNERS_ERROR: {
      return {
        ...state,
        approvedPartnersError: action.payload
      }
    }
    case PartnershipTimeBasedActionTypes.APPROVED_PARTNERS_SUCCESS: {
      return {
        ...state,
        approvedPartnersSuccess: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default partnershipTimeBasedReducer
