import axios           from 'axios'
import { ID }          from '../../../../_metronic/helpers'
import AppConfig       from '../../../../appConfig'
import { AppDispatch } from '../../../store'

// *************** TYPES

export interface Location {
  _id: ID
  status: string
  location: string
}
export interface Category {
  _id: ID
  type: string
  image: string
  status: string
  category: string
}
export interface Subcategory {
  _id: ID
  subcategory: string
}
export interface CategoryType {
  _id: ID
  type: string
  status: string
}

// *************** STATE

export interface AdminSettingsActionsState {
  locations: Location[]
  locationsFetchError: boolean
  locationsFetchSuccess: boolean
  locationSubmitError: boolean
  locationSubmitSuccess: boolean
  categories: Category[]
  categoriesFetchError: boolean
  categoriesFetchSuccess: boolean
  categorySubmitError: boolean
  categorySubmitSuccess: boolean
  subcategories: Subcategory[]
  subcategoriesFetchError: boolean
  subcategoriesFetchSuccess: boolean
  subcategorySubmitError: boolean
  subcategorySubmitSuccess: boolean
  categoryTypes: CategoryType[]
  categoryTypesFetchError: boolean
  categoryTypesFetchSuccess: boolean
  categoryTypeSubmitError: boolean
  categoryTypeSubmitSuccess: boolean
  categoryName: string
}

// *************** ACTION TYPES

export const AdminSettingsActionTypes = {
  FETCH_LOCATIONS                  : 'FETCH_LOCATIONS',
  FETCH_LOCATIONS_ERROR            : 'FETCH_LOCATIONS_ERROR',
  FETCH_LOCATIONS_SUCCESS          : 'FETCH_LOCATIONS_SUCCESS',
  SUBMIT_NEW_LOCATION              : 'SUBMIT_NEW_LOCATION',
  SUBMIT_NEW_LOCATION_ERROR        : 'SUBMIT_NEW_LOCATION_ERROR',
  SUBMIT_NEW_LOCATION_SUCCESS      : 'SUBMIT_NEW_LOCATION_SUCCESS',
  FETCH_CATEGORIES                 : 'FETCH_CATEGORIES',
  FETCH_CATEGORIES_ERROR           : 'FETCH_CATEGORIES_ERROR',
  FETCH_CATEGORIES_SUCCESS         : 'FETCH_CATEGORIES_SUCCESS',
  SUBMIT_NEW_CATEGORY              : 'SUBMIT_NEW_CATEGORY',
  SUBMIT_NEW_CATEGORY_ERROR        : 'SUBMIT_NEW_CATEGORY_ERROR',
  SUBMIT_NEW_CATEGORY_SUCCESS      : 'SUBMIT_NEW_CATEGORY_SUCCESS',
  FETCH_SUBCATEGORIES              : 'FETCH_SUBCATEGORIES',
  FETCH_SUBCATEGORIES_ERROR        : 'FETCH_SUBCATEGORIES_ERROR',
  FETCH_SUBCATEGORIES_SUCCESS      : 'FETCH_SUBCATEGORIES_SUCCESS',
  SUBMIT_NEW_SUBCATEGORY           : 'SUBMIT_NEW_SUBCATEGORY',
  SUBMIT_NEW_SUBCATEGORY_ERROR     : 'SUBMIT_NEW_SUBCATEGORY_ERROR',
  SUBMIT_NEW_SUBCATEGORY_SUCCESS   : 'SUBMIT_NEW_SUBCATEGORY_SUCCESS',
  FETCH_CATEGORY_TYPES             : 'FETCH_CATEGORY_TYPES',
  FETCH_CATEGORY_TYPES_ERROR       : 'FETCH_CATEGORY_TYPES_ERROR',
  FETCH_CATEGORY_TYPES_SUCCESS     : 'FETCH_CATEGORY_TYPES_SUCCESS',
  SUBMIT_NEW_CATEGORY_TYPE         : 'SUBMIT_NEW_CATEGORY_TYPE',
  SUBMIT_NEW_CATEGORY_TYPE_ERROR   : 'SUBMIT_NEW_CATEGORY_TYPE_ERROR',
  SUBMIT_NEW_CATEGORY_TYPE_SUCCESS : 'SUBMIT_NEW_CATEGORY_TYPE_SUCCESS',
  SET_CATEGORY_NAME                : 'SET_CATEGORY_NAME'
} as const

// *************** ACTIONS

interface FetchLocationsAction {
  type: typeof AdminSettingsActionTypes.FETCH_LOCATIONS;
  payload: Location[];
}
interface FetchLocationsFailureAction {
  type: typeof AdminSettingsActionTypes.FETCH_LOCATIONS_ERROR;
  payload: boolean;
}
interface FetchLocationsSuccessAction {
  type: typeof AdminSettingsActionTypes.FETCH_LOCATIONS_SUCCESS;
  payload: boolean;
}
interface SubmitNewLocationAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_LOCATION;
  payload: Location[];
}
interface SubmitNewLocationFailureAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_LOCATION_ERROR;
  payload: boolean;
}
interface SubmitNewLocationSuccessAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_LOCATION_SUCCESS;
  payload: boolean;
}

interface FetchCategoriesAction {
  type: typeof AdminSettingsActionTypes.FETCH_CATEGORIES;
  payload: Category[];
}
interface FetchCategoriesFailureAction {
  type: typeof AdminSettingsActionTypes.FETCH_CATEGORIES_ERROR;
  payload: boolean;
}
interface FetchCategoriesSuccessAction {
  type: typeof AdminSettingsActionTypes.FETCH_CATEGORIES_SUCCESS;
  payload: boolean;
}
interface SubmitNewCategoryAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY;
  payload: Category[];
}
interface SubmitNewCategoryFailureAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_ERROR;
  payload: boolean;
}
interface SubmitNewCategorySuccessAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_SUCCESS;
  payload: boolean;
}

interface FetchSubcategoriesAction {
  type: typeof AdminSettingsActionTypes.FETCH_SUBCATEGORIES;
  payload: Subcategory[];
}
interface FetchSubcategoriesFailureAction {
  type: typeof AdminSettingsActionTypes.FETCH_SUBCATEGORIES_ERROR;
  payload: boolean;
}
interface FetchSubcategoriesSuccessAction {
  type: typeof AdminSettingsActionTypes.FETCH_SUBCATEGORIES_SUCCESS;
  payload: boolean;
}
interface SubmitNewSubcategoryAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_SUBCATEGORY;
  payload: Subcategory[];
}
interface SubmitNewSubcategoryFailureAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_SUBCATEGORY_ERROR;
  payload: boolean;
}
interface SubmitNewSubcategorySuccessAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_SUBCATEGORY_SUCCESS;
  payload: boolean;
}

interface FetchCategoryTypesAction {
  type: typeof AdminSettingsActionTypes.FETCH_CATEGORY_TYPES;
  payload: CategoryType[];
}
interface FetchCategoryTypesFailureAction {
  type: typeof AdminSettingsActionTypes.FETCH_CATEGORY_TYPES_ERROR;
  payload: boolean;
}
interface FetchCategoryTypesSuccessAction {
  type: typeof AdminSettingsActionTypes.FETCH_CATEGORY_TYPES_SUCCESS;
  payload: boolean;
}
interface SubmitNewCategoryTypeAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_TYPE;
  payload: CategoryType[];
}
interface SubmitNewCategoryTypeFailureAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_TYPE_ERROR;
  payload: boolean;
}
interface SubmitNewCategoryTypeSuccessAction {
  type: typeof AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_TYPE_SUCCESS;
  payload: boolean;
}

interface SetCategoryNameAction {
  type: typeof AdminSettingsActionTypes.SET_CATEGORY_NAME
  payload: string;
}

export type AdminSettingsActions =
  | FetchLocationsAction
  | FetchLocationsFailureAction
  | FetchLocationsSuccessAction
  | SubmitNewLocationAction
  | SubmitNewLocationFailureAction
  | SubmitNewLocationSuccessAction
  | FetchCategoriesAction
  | FetchCategoriesFailureAction
  | FetchCategoriesSuccessAction
  | SubmitNewCategoryAction
  | SubmitNewCategoryFailureAction
  | SubmitNewCategorySuccessAction
  | FetchSubcategoriesAction
  | FetchSubcategoriesFailureAction
  | FetchSubcategoriesSuccessAction
  | SubmitNewSubcategoryAction
  | SubmitNewSubcategoryFailureAction
  | SubmitNewSubcategorySuccessAction
  | FetchCategoryTypesAction
  | FetchCategoryTypesFailureAction
  | FetchCategoryTypesSuccessAction
  | SubmitNewCategoryTypeAction
  | SubmitNewCategoryTypeFailureAction
  | SubmitNewCategoryTypeSuccessAction
  | SetCategoryNameAction


// *************** API CALLS
/* FETCH LOCATIONS */

export function fetchLocations() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.ADMIN_SETTINGS_LOCATION_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: AdminSettingsActionTypes.FETCH_LOCATIONS,
        payload: response.data.location
      })
      dispatch(fetchLocationsSuccess(true))
    })
    .catch((error) => {
      dispatch(fetchLocationsError(true))
    })
  }
}
export function fetchLocationsError(status: boolean) {
  return {
    type: AdminSettingsActionTypes.FETCH_LOCATIONS_ERROR,
    payload: status
  }
}
export function fetchLocationsSuccess(status: boolean) {
  return {
    type: AdminSettingsActionTypes.FETCH_LOCATIONS_SUCCESS,
    payload: status
  }
}

/* ADD NEW LOCATION */

export function submitNewLocation(formData: any) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.ADMIN_SETTINGS_SUBMIT_LOCATION

    return axios.post(url, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(submitNewLocationSuccess(true))
    })
    .catch((error) => {
      dispatch(submitNewLocationError(true))
    })
  }
}
export function submitNewLocationError(status: boolean) {
  return {
    type: AdminSettingsActionTypes.SUBMIT_NEW_LOCATION_ERROR,
    payload: status
  }
}
export function submitNewLocationSuccess(status: boolean) {
  return {
    type: AdminSettingsActionTypes.SUBMIT_NEW_LOCATION_SUCCESS,
    payload: status
  }
}

/* FETCH CATEGORIES */

export function fetchCategories() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.ADMIN_SETTINGS_CATEGORY_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: AdminSettingsActionTypes.FETCH_CATEGORIES,
        payload: response.data.category
      });
      dispatch(fetchCategoriesSuccess(true))
    })
    .catch((error) => {
      dispatch(fetchCategoriesError(true))
    })
  }
}
export function fetchCategoriesError(status: boolean) {
  return {
    type: AdminSettingsActionTypes.FETCH_CATEGORIES_ERROR,
    payload: status
  }
}
export function fetchCategoriesSuccess(status: boolean) {
  return {
    type: AdminSettingsActionTypes.FETCH_CATEGORIES_SUCCESS,
    payload: status
  }
}

/* ADD NEW CATEGORY */

export function submitNewCategory(formData: any) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.ADMIN_SETTINGS_SUBMIT_CATEGORY

    return axios.post(url, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(submitNewCategorySuccess(true))
    })
    .catch((error) => {
      dispatch(submitNewCategoryError(true))
    })
  }
}
export function submitNewCategoryError(status: boolean) {
  return {
    type: AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_ERROR,
    payload: status
  }
}
export function submitNewCategorySuccess(status: boolean) {
  return {
    type: AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_SUCCESS,
    payload: status
  }
}

/* FETCH SUBCATEGORIES */

export function fetchSubcategories(catId: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.ADMIN_SETTINGS_SUB_CATEGORY_LIST

    return axios.get(`${url}/${catId}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: AdminSettingsActionTypes.FETCH_SUBCATEGORIES,
        payload: response.data.subcategory
      })
      dispatch(fetchSubcategoriesSuccess(true))
    })
    .catch((error) => {
      dispatch(fetchSubcategoriesError(true))
    })
  }
}
export function fetchSubcategoriesError(status: boolean) {
  return {
    type: AdminSettingsActionTypes.FETCH_SUBCATEGORIES_ERROR,
    payload: status
  }
}
export function fetchSubcategoriesSuccess(status: boolean) {
  return {
    type: AdminSettingsActionTypes.FETCH_SUBCATEGORIES_SUCCESS,
    payload: status
  }
}

/* ADD NEW SUBCATEGORY */

export function submitNewSubcategory(formData: any) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.ADMIN_SETTINGS_SUBMIT_SUB_CATEGORY

    return axios.post(url, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(submitNewSubcategorySuccess(true))
    })
    .catch((error) => {
      dispatch(submitNewSubcategoryError(true))
    })
  }
}
export function submitNewSubcategoryError(status: boolean) {
  return {
    type: AdminSettingsActionTypes.SUBMIT_NEW_SUBCATEGORY_ERROR,
    payload: status
  }
}
export function submitNewSubcategorySuccess(status: boolean) {
  return {
    type: AdminSettingsActionTypes.SUBMIT_NEW_SUBCATEGORY_SUCCESS,
    payload: status
  }
}

/* FETCH CATEGORY TYPES */

export function fetchCategoryTypes() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.ADMIN_SETTINGS_CATEGORY_TYPES_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: AdminSettingsActionTypes.FETCH_CATEGORY_TYPES,
        payload: response.data.categorytype
      })
      dispatch(fetchCategoryTypesSuccess(true))
    })
    .catch((error) => {
      dispatch(fetchCategoryTypesError(true))
    })
  }
}
export function fetchCategoryTypesError(status: boolean) {
  return {
    type: AdminSettingsActionTypes.FETCH_CATEGORY_TYPES_ERROR,
    payload: status
  }
}
export function fetchCategoryTypesSuccess(status: boolean) {
  return {
    type: AdminSettingsActionTypes.FETCH_CATEGORY_TYPES_SUCCESS,
    payload: status
  }
}

/* ADD NEW CATEGORY TYPE */

export function submitNewCategoryType(formData: any) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.ADMIN_SETTINGS_SUBMIT_CATEGORY_TYPE

    return axios.post(url, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(submitNewCategoryTypeSuccess(true))
    }).catch((error) => {
      dispatch(submitNewCategoryTypeError(true))
    })
  }
}
export function submitNewCategoryTypeError(status: boolean) {
  return {
    type: AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_TYPE_ERROR,
    payload: status
  }
}
export function submitNewCategoryTypeSuccess(status: boolean) {
  return {
    type: AdminSettingsActionTypes.SUBMIT_NEW_CATEGORY_TYPE_SUCCESS,
    payload: status
  }
}

/* SAVE CATEGORY NAME (FOR DISPLAYING IN SUBCATEGORIES SCREEN) */

export function setCategoryName(categoryName: string) {
  return {
    type: AdminSettingsActionTypes.SET_CATEGORY_NAME,
    payload: categoryName
  }
}
