import { FC, useEffect, useState }     from 'react'
import { ChangeEvent, MouseEvent }     from 'react'
import { Dispatch }                    from 'redux'
import { connect, ConnectedProps }     from 'react-redux'
import { Link, useNavigate }           from 'react-router-dom'
import debounce                        from 'lodash/debounce'
import orderBy                         from 'lodash/orderBy'
import { KTCard, KTCardBody }          from '../../../../../_metronic/helpers'
import { PageTitle }                   from '../../../../../_metronic/layout/core'
import { ListLoading }                 from '../../../../components/ListLoading'
import ListPagination                  from '../../../../components/ListPagination'
import { showSnackbar }                from '../../../../helpers/SnackbarHelper'
import { RootState }                   from '../../../../store'
import { useAppDispatch }              from '../../../../store/hooks'
import {
  ApprovedPersonsAd,
  GoAdsActions,
  fetchApprovedPersonsAdsList,
  fetchApprovedPersonsAdsListError,
  fetchApprovedPersonsAdsListSuccess } from '../../../../store/actions/go/ads.actions'

const InterestedPersonsAdsConstants = {
  approvedPersonsAdsListErrorMsg: 'Error in fetching approved persons ads. Please try again'
}

const InterestedPersonsAds: FC<InterestedPersonsAdsProps> = ({
  approvedPersonsAdsList,
  approvedPersonsAdsListError,
  approvedPersonsAdsListSuccess,
  fetchApprovedPersonsAdsList,
  fetchApprovedPersonsAdsListError,
  fetchApprovedPersonsAdsListSuccess
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading]         = useState<boolean>(false)
  const [columnToQuery, setColumnToQuery] = useState<string>('title')
  const [adsList, setAdsList]             = useState<ApprovedPersonsAd[]>([])
  const [page, setPage]                   = useState<number>(0)
  const [rowsPerPage, setRowsPerPage]     = useState<number>(5)
  const [searchTerm, setSearchTerm]       = useState<string>('')

  useEffect(() => {
    dispatch(fetchApprovedPersonsAdsList())
    setAdsList(approvedPersonsAdsList)
  }, [])

  useEffect(() => {
    if (searchTerm != '') {
      filterData()
    }
  }, [searchTerm, columnToQuery])

  useEffect(() => {
    if (searchTerm == '') {
      setAdsList(approvedPersonsAdsList)
    }
  }, [searchTerm, approvedPersonsAdsList])

  const filterData = () => {
    var debouncedSearch = debounce(() => {
      let currentList = [...adsList]
      let orderedList = orderBy(
        currentList.filter((x) =>
          x[columnToQuery as keyof ApprovedPersonsAd]
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ),
        columnToQuery, 'asc'
      )

      setAdsList(orderedList)
    }, 1000)

    debouncedSearch()
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const closeApprovedPersonsAdsListErrorMsg = () => {
    dispatch(fetchApprovedPersonsAdsListError(false))
  }

  const renderActions = (id: string, categoryType: string) => {
    return (
      <>
        <Link
          to={`/approved-persons-go-list/${id}/${categoryType}`}
          title='View'
          className='btn btn-sm btn-light btn-active-light-primary'>
          <i className='bi bi-eye-fill pe-0 fs-2'></i>
        </Link>
        <Link
          to={`/time-based-partnership-init/${id}/${categoryType}`}
          title='Initiate'
          className='ms-2 btn btn-sm btn-light btn-active-light-primary'>
          <i className="bi bi-box-arrow-in-right pe-0 fs-2"></i>
        </Link>
      </>
    )
  }

  const populateApprovedPersonsAdsList = () => {
    return (
      rowsPerPage > 0
        ? adsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : adsList
    ).map((row, index) => (
      <tr role='row' key={index}>
        <td role='cell'>
          <div>{(page + 1 - 1) * rowsPerPage + (index + 1)}</div>
        </td>
        <td role='cell'>{row.title}</td>
        <td role='cell'>{row.category_name}</td>
        <td role='cell'>{row.category_type}</td>
        <td role='cell' className='text-center min-w-50px'>{renderActions(row._id, row.category_type)}</td>
      </tr>
    ))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Interested Persons Ads</PageTitle>

      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <select
              data-control='select2'
              data-hide-search='true'
              value={columnToQuery}
              onChange={(event) => setColumnToQuery(event.target.value)}
              className='form-select form-select-sm form-select-solid'>
              <option value='title'>Title</option>
              <option value='category-name'>Category Name</option>
              <option value='category-type'>Category Type</option>
            </select>
            <div className='d-flex align-items-center position-relative my-1 ms-3'>
              <i className='bi bi-search position-absolute ms-3'></i>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid w-250px ps-10'
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)} />
            </div>
          </div>
        </div>

        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='aanshik-go-interested-persons-ads'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th role='columnheader' className='min-w-50px'>#</th>
                  <th role='columnheader' className='min-w-125px'>Title</th>
                  <th role='columnheader' className='min-w-125px'>Category</th>
                  <th role='columnheader' className='min-w-125px'>Type</th>
                  <th role='columnheader' className='min-w-50px'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600' role='rowgroup'>
              {
                adsList.length > 0 ? populateApprovedPersonsAdsList() : (
                  <tr>
                    <td colSpan={5}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )
              }
              </tbody>
              <tfoot>
                <tr>
                  <ListPagination
                    page={page}
                    count={approvedPersonsAdsList.length}
                    colSpan={5}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} />
                </tr>
              </tfoot>
            </table>
          </div>

          {isLoading && <ListLoading />}
        </KTCardBody>
      </KTCard>

      {
        approvedPersonsAdsListError &&
        showSnackbar(InterestedPersonsAdsConstants.approvedPersonsAdsListErrorMsg, closeApprovedPersonsAdsListErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    approvedPersonsAdsList: state.goAdsReducer.approvedPersonsAdsList,
    approvedPersonsAdsListError: state.goAdsReducer.approvedPersonsAdsListError,
    approvedPersonsAdsListSuccess: state.goAdsReducer.approvedPersonsAdsListSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GoAdsActions>) => {
  return {
    fetchApprovedPersonsAdsList,
    fetchApprovedPersonsAdsListError,
    fetchApprovedPersonsAdsListSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type InterestedPersonsAdsProps = ConnectedProps<typeof connector>

export default connector(InterestedPersonsAds)
