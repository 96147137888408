import { FC, useState } from 'react'
import Dropzone         from 'react-dropzone'

const fileTypes = {
  'images'    : { 'image/*': ['.png', '.jpeg', '.jpg']},
  'documents' : { 'application/*': ['.pdf', '.doc', '.docx']}
}

type DropzoneWrapperProps = {
  filesLimit: number
  dropzoneText: string
  acceptedFileTypes: keyof typeof fileTypes
  handleOnAdd: (fileObjects: File[]) => void
}

const DropzoneWrapper: FC<DropzoneWrapperProps> = ({
  filesLimit,
  dropzoneText,
  acceptedFileTypes,
  handleOnAdd
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])

  const handleOnDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setSelectedFiles(acceptedFiles)
      handleOnAdd(acceptedFiles)
    }
  }

  return (
    <Dropzone
      onDrop={handleOnDrop}
      accept={fileTypes[acceptedFileTypes]}
      maxFiles={filesLimit}>
    {
      ({getRootProps, getInputProps}) => (
        <section>
          <div {...getRootProps({className: 'dropzone mb-7'})}>
            <input {...getInputProps()} />
            <p>{dropzoneText}</p>
          </div>

          {
            selectedFiles.length > 0 &&
            <div className='row'>
              <div className='col-xl-12'>
                <label className='form-label mb-3'>Selected Files</label>
                <ul>
                {
                  selectedFiles.map(selectedFile => (
                    <li key={selectedFile.name}>{selectedFile.name} - {selectedFile.size} bytes</li>
                  ))
                }
                </ul>
              </div>
            </div>
          }
        </section>
      )
    }
    </Dropzone>
  )
}

export default DropzoneWrapper
