import { FC } from 'react'

const ActionsCell: FC = () => {
  return (
    <button
      type='button'
      title='Block'
      className='btn btn-sm btn-light btn-active-light-primary'>
      <i className='bi bi-slash-circle pe-0'></i>
    </button>
  )
}

export { ActionsCell }
