import axios           from 'axios'
import AppConfig       from '../../../../appConfig'
import { AppDispatch } from '../../../store'

// *************** TYPES

export interface NewAd {
  _id: string
  title: string
  amount: string
  offer_mode: string
  category_name: string
  category_type: string
  location_name: string
  property_mode: string
  approval_status: string
  thumbnailmyproperty_image: string
}

export interface GoAd {
  ad_details: {
    category: string
    subcategory: string
    location: string
  }
  property_details: {
    title: string
    amount: string
    property_mode: string
    description: string
    video_url: string
    image: string[]
  }
  share_details: {
    user_share: number
    user_amount: number
    user_days: number
    balance_amount: number
  }
  user_selected_days: string[]
  thumbnailmyproperty_image: string
}

export interface ApprovedAd {
  _id: string
  title: string
  amount: string
  offer_mode: string
  category_name: string
  category_type: string
  location_name: string
  property_mode: string
}

export interface InterestedAd {
  title: string
  amount: number
  interest_id: string
  location_name: string
  interest_count: number
  property_details_id: string
  thumbnailmyproperty_image: string
}

export interface InterestedPerson {
  date: string
  user_id: string
  location: string
  user_name: string
  user_days: number
  user_email: string
  user_phone: string
  user_share: number
  user_amount: number
  interest_id: string
  approval_status: string
}

export interface ApprovedPersonsAd {
  _id: string
  title: string
  category_name: string
  category_type: string
}

interface ApprovedPerson {
  amount: number
  days: number
  interest_id: string
  name: string
  percentage: number
  property_id: string
  user_id: string
}

interface ApprovedPersons {
  category_type: string
  property_amount: string
  property_id: string
  property_name: string
  users_list: ApprovedPerson[]
}

// *************** STATE

export interface GoAdsState {
  newAdsList: NewAd[]
  newAdsListError: boolean
  newAdsListSuccess: boolean
  adDetails: null | GoAd
  adDetailsError: boolean
  adDetailsSuccess: boolean
  goAdStatusError: boolean
  goAdStatusSuccess: boolean
  approvedAdsList: ApprovedAd[]
  approvedAdsListError: boolean
  approvedAdsListSuccess: boolean
  applyOfferError: boolean
  applyOfferSuccess: boolean
  interestedAdsList: InterestedAd[]
  interestedAdsListError: boolean
  interestedAdsListSuccess: boolean
  interestedPersonsList: InterestedPerson[]
  interestedPersonsListError: boolean
  interestedPersonsListSuccess: boolean
  interestedPersonsApproveStatusError: boolean
  interestedPersonsApproveStatusSuccess: boolean
  approvedPersonsAdsList: ApprovedPersonsAd[]
  approvedPersonsAdsListError: boolean
  approvedPersonsAdsListSuccess: boolean
  approvedPersonsList: ApprovedPersons | null
  approvedPersonsListError: boolean
  approvedPersonsListSuccess: boolean
}

// *************** ACTION TYPES

export const GoAdsActionTypes = {
  FETCH_NEW_ADS_LIST                      : 'FETCH_NEW_ADS_LIST',
  FETCH_NEW_ADS_LIST_ERROR                : 'FETCH_NEW_ADS_LIST_ERROR',
  FETCH_NEW_ADS_LIST_SUCCESS              : 'FETCH_NEW_ADS_LIST_SUCCESS',
  FETCH_AD_DETAILS                        : 'FETCH_AD_DETAILS',
  FETCH_AD_DETAILS_ERROR                  : 'FETCH_AD_DETAILS_ERROR',
  FETCH_AD_DETAILS_SUCCESS                : 'FETCH_AD_DETAILS_SUCCESS',
  UPDATE_GO_AD_STATUS                     : 'UPDATE_GO_AD_STATUS',
  UPDATE_GO_AD_STATUS_ERROR               : 'UPDATE_GO_AD_STATUS_ERROR',
  UPDATE_GO_AD_STATUS_SUCCESS             : 'UPDATE_GO_AD_STATUS_SUCCESS',
  FETCH_APPROVED_ADS_LIST                 : 'FETCH_APPROVED_ADS_LIST',
  FETCH_APPROVED_ADS_LIST_ERROR           : 'FETCH_APPROVED_ADS_LIST_ERROR',
  FETCH_APPROVED_ADS_LIST_SUCCESS         : 'FETCH_APPROVED_ADS_LIST_SUCCESS',
  SUBMIT_APPLY_OFFER_ERROR                : 'SUBMIT_APPLY_OFFER_ERROR',
  SUBMIT_APPLY_OFFER_SUCCESS              : 'SUBMIT_APPLY_OFFER_SUCCESS',
  FETCH_INTERESTED_ADS_LIST               : 'FETCH_INTERESTED_ADS_LIST',
  FETCH_INTERESTED_ADS_LIST_ERROR         : 'FETCH_INTERESTED_ADS_LIST_ERROR',
  FETCH_INTERESTED_ADS_LIST_SUCCESS       : 'FETCH_INTERESTED_ADS_LIST_SUCCESS',
  FETCH_INTERESTED_PERSONS_LIST           : 'FETCH_INTERESTED_PERSONS_LIST',
  FETCH_INTERESTED_PERSONS_LIST_ERROR     : 'FETCH_INTERESTED_PERSONS_LIST_ERROR',
  FETCH_INTERESTED_PERSONS_LIST_SUCCESS   : 'FETCH_INTERESTED_PERSONS_ADS_LIST_SUCCESS',
  APPROVE_INTERESTED_PERSON_ERROR         : 'APPROVE_INTERESTED_PERSON_ERROR',
  APPROVE_INTERESTED_PERSON_SUCCESS       : 'APPROVE_INTERESTED_PERSON_SUCCESS',
  FETCH_APPROVED_PERSONS_ADS_LIST         : 'FETCH_APPROVED_PERSONS_ADS_LIST',
  FETCH_APPROVED_PERSONS_ADS_LIST_ERROR   : 'FETCH_APPROVED_PERSONS_ADS_LIST_ERROR',
  FETCH_APPROVED_PERSONS_ADS_LIST_SUCCESS : 'FETCH_APPROVED_PERSONS_ADS_LIST_SUCCESS',
  FETCH_APPROVED_PERSONS_LIST             : 'FETCH_APPROVED_PERSONS_LIST',
  FETCH_APPROVED_PERSONS_LIST_ERROR       : 'FETCH_APPROVED_PERSONS_LIST_ERROR',
  FETCH_APPROVED_PERSONS_LIST_SUCCESS     : 'FETCH_APPROVED_PERSONS_LIST_SUCCESS'
} as const

// *************** ACTIONS

interface FetchNewAdsAction {
  type: typeof GoAdsActionTypes.FETCH_NEW_ADS_LIST;
  payload: NewAd[];
}
interface FetchNewAdsFailureAction {
  type: typeof GoAdsActionTypes.FETCH_NEW_ADS_LIST_ERROR;
  payload: boolean;
}
interface FetchNewAdsSuccessAction {
  type: typeof GoAdsActionTypes.FETCH_NEW_ADS_LIST_SUCCESS;
  payload: boolean;
}

interface FetchAdDetailsAction {
  type: typeof GoAdsActionTypes.FETCH_AD_DETAILS;
  payload: GoAd;
}
interface FetchAdDetailsFailureAction {
  type: typeof GoAdsActionTypes.FETCH_AD_DETAILS_ERROR;
  payload: boolean;
}
interface FetchAdDetailsSuccessAction {
  type: typeof GoAdsActionTypes.FETCH_AD_DETAILS_SUCCESS;
  payload: boolean;
}

interface UpdateGoAdStatusAction {
  type: typeof GoAdsActionTypes.UPDATE_GO_AD_STATUS;
  payload: GoAd;
}
interface UpdateGoAdStatusFailureAction {
  type: typeof GoAdsActionTypes.UPDATE_GO_AD_STATUS_ERROR;
  payload: boolean;
}
interface UpdateGoAdStatusSuccessAction {
  type: typeof GoAdsActionTypes.UPDATE_GO_AD_STATUS_SUCCESS;
  payload: boolean;
}

interface FetchApprovedAdsAction {
  type: typeof GoAdsActionTypes.FETCH_APPROVED_ADS_LIST;
  payload: ApprovedAd[];
}
interface FetchApprovedAdsFailureAction {
  type: typeof GoAdsActionTypes.FETCH_APPROVED_ADS_LIST_ERROR;
  payload: boolean;
}
interface FetchApprovedAdsSuccessAction {
  type: typeof GoAdsActionTypes.FETCH_APPROVED_ADS_LIST_SUCCESS;
  payload: boolean;
}

interface SubmitApplyOfferFailureAction {
  type: typeof GoAdsActionTypes.SUBMIT_APPLY_OFFER_ERROR;
  payload: boolean;
}
interface SubmitApplyOfferSuccessAction {
  type: typeof GoAdsActionTypes.SUBMIT_APPLY_OFFER_SUCCESS;
  payload: boolean;
}

interface FetchInterestedAdsAction {
  type: typeof GoAdsActionTypes.FETCH_INTERESTED_ADS_LIST;
  payload: InterestedAd[];
}
interface FetchInterestedAdsFailureAction {
  type: typeof GoAdsActionTypes.FETCH_INTERESTED_ADS_LIST_ERROR;
  payload: boolean;
}
interface FetchInterestedAdsSuccessAction {
  type: typeof GoAdsActionTypes.FETCH_INTERESTED_ADS_LIST_SUCCESS;
  payload: boolean;
}

interface FetchInterestedPersonsListAction {
  type: typeof GoAdsActionTypes.FETCH_INTERESTED_PERSONS_LIST;
  payload: InterestedPerson[];
}
interface FetchInterestedPersonsListFailureAction {
  type: typeof GoAdsActionTypes.FETCH_INTERESTED_PERSONS_LIST_ERROR;
  payload: boolean;
}
interface FetchInterestedPersonsListSuccessAction {
  type: typeof GoAdsActionTypes.FETCH_INTERESTED_PERSONS_LIST_SUCCESS;
  payload: boolean;
}

interface ApproveInterestedUserFailureAction {
  type: typeof GoAdsActionTypes.APPROVE_INTERESTED_PERSON_ERROR;
  payload: boolean;
}
interface ApproveInterestedUserSuccessAction {
  type: typeof GoAdsActionTypes.APPROVE_INTERESTED_PERSON_SUCCESS;
  payload: boolean;
}

interface FetchApprovedPersonsAdsListAction {
  type: typeof GoAdsActionTypes.FETCH_APPROVED_PERSONS_ADS_LIST;
  payload: ApprovedPersonsAd[];
}
interface FetchApprovedPersonsAdsListFailureAction {
  type: typeof GoAdsActionTypes.FETCH_APPROVED_PERSONS_ADS_LIST_ERROR;
  payload: boolean;
}
interface FetchApprovedPersonsAdsListSuccessAction {
  type: typeof GoAdsActionTypes.FETCH_APPROVED_PERSONS_ADS_LIST_SUCCESS;
  payload: boolean;
}

interface FetchApprovedPersonsListAction {
  type: typeof GoAdsActionTypes.FETCH_APPROVED_PERSONS_LIST;
  payload: ApprovedPersons;
}
interface FetchApprovedPersonsListFailureAction {
  type: typeof GoAdsActionTypes.FETCH_APPROVED_PERSONS_LIST_ERROR;
  payload: boolean;
}
interface FetchApprovedPersonsListSuccessAction {
  type: typeof GoAdsActionTypes.FETCH_APPROVED_PERSONS_LIST_SUCCESS;
  payload: boolean;
}

export type GoAdsActions =
  | FetchNewAdsAction
  | FetchNewAdsFailureAction
  | FetchNewAdsSuccessAction
  | FetchAdDetailsAction
  | FetchAdDetailsFailureAction
  | FetchAdDetailsSuccessAction
  | UpdateGoAdStatusAction
  | UpdateGoAdStatusFailureAction
  | UpdateGoAdStatusSuccessAction
  | FetchApprovedAdsAction
  | FetchApprovedAdsFailureAction
  | FetchApprovedAdsSuccessAction
  | SubmitApplyOfferFailureAction
  | SubmitApplyOfferSuccessAction
  | FetchInterestedAdsAction
  | FetchInterestedAdsFailureAction
  | FetchInterestedAdsSuccessAction
  | FetchInterestedPersonsListAction
  | FetchInterestedPersonsListFailureAction
  | FetchInterestedPersonsListSuccessAction
  | ApproveInterestedUserFailureAction
  | ApproveInterestedUserSuccessAction
  | FetchApprovedPersonsAdsListAction
  | FetchApprovedPersonsAdsListFailureAction
  | FetchApprovedPersonsAdsListSuccessAction
  | FetchApprovedPersonsListAction
  | FetchApprovedPersonsListFailureAction
  | FetchApprovedPersonsListSuccessAction


// *************** API CALLS
/* FETCH GO ADS LIST */

export function fetchGoAdsList() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.GO_ADS_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: GoAdsActionTypes.FETCH_NEW_ADS_LIST,
        payload: response.data.propertydetails
      })
      dispatch(fetchGoAdsListSuccess(true))
    })
    .catch((error) => {
      dispatch(fetchGoAdsListError(true))
    })
  }
}
export function fetchGoAdsListError(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_NEW_ADS_LIST_ERROR,
    payload: status
  }
}
export function fetchGoAdsListSuccess(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_NEW_ADS_LIST_SUCCESS,
    payload: status
  }
}

/* FETCH AD DETAILS */

export function fetchAdDetails(id: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.GO_ADS_DETAIL

    return axios.get(`${url}/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: GoAdsActionTypes.FETCH_AD_DETAILS,
        payload: response.data.postdetails
      })
      dispatch(fetchAdDetailsSuccess(true))
    })
    .catch((error) => {
      dispatch(fetchAdDetailsError(true))
    })
  }
}
export function fetchAdDetailsError(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_AD_DETAILS_ERROR,
    payload: status
  }
}
export function fetchAdDetailsSuccess(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_AD_DETAILS_SUCCESS,
    payload: status
  }
}

/* GO AD STATUS UPDATE */

export function updateGoAdStatus(formData: any, id: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.GO_STATUS_UPDATE_NEW_AD

    return axios.post(`${url}/${id}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(updateGoAdStatusSuccess(true))
    })
    .catch((error) => {
      dispatch(updateGoAdStatusError(true))
    })
  }
}
export function updateGoAdStatusError(status: boolean) {
  return {
    type: GoAdsActionTypes.UPDATE_GO_AD_STATUS_ERROR,
    payload: status
  }
}
export function updateGoAdStatusSuccess(status: boolean) {
  return {
    type: GoAdsActionTypes.UPDATE_GO_AD_STATUS_SUCCESS,
    payload: status
  }
}

/* FETCH APPROVED ADS */

export function fetchApprovedAdsList() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.APPROVED_GO_ADS_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: GoAdsActionTypes.FETCH_APPROVED_ADS_LIST,
        payload: response.data.offerdetails
      })
      dispatch(fetchApprovedAdsListSuccess(true))
    })
    .catch((error) => {
      dispatch(fetchApprovedAdsListError(true))
    })
  }
}
export function fetchApprovedAdsListError(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_APPROVED_ADS_LIST_ERROR,
    payload: status
  }
}
export function fetchApprovedAdsListSuccess(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_APPROVED_ADS_LIST_SUCCESS,
    payload: status
  }
}

/* APPLY OFFER FOR APPROVED ADS */

export function submitApplyOffer(formData: any, id: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.APPLY_OFFER_GO_ADS

    return axios.post(`${url}/${id}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(submitApplyOfferSuccess(true))
    })
    .catch((error) => {
      dispatch(submitApplyOfferError(true))
    })
  }
}
export function submitApplyOfferError(status: boolean) {
  return {
    type: GoAdsActionTypes.SUBMIT_APPLY_OFFER_ERROR,
    payload: status
  }
}
export function submitApplyOfferSuccess(status: boolean) {
  return {
    type: GoAdsActionTypes.SUBMIT_APPLY_OFFER_SUCCESS,
    payload: status
  }
}

/* FETCH INTERESTED ADS */

export function fetchInterestedAdsList() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.INTERESTED_GO_ADS_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: GoAdsActionTypes.FETCH_INTERESTED_ADS_LIST,
        payload: response.data.interestdetails
      })
      dispatch(fetchInterestedAdsListSuccess(true))
    })
    .catch((error) => {
      dispatch(fetchInterestedAdsListError(true))
    })
  }
}
export function fetchInterestedAdsListError(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_INTERESTED_ADS_LIST_ERROR,
    payload: status
  }
}
export function fetchInterestedAdsListSuccess(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_INTERESTED_ADS_LIST_SUCCESS,
    payload: status
  }
}

/* FETCH INTERESTED USERS */

export function fetchInterestedPersonsList(id: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.INTERESTED_PERSONS_GO_ADS_LIST

    return axios.get(`${url}/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: GoAdsActionTypes.FETCH_INTERESTED_PERSONS_LIST,
        payload: response.data.interestdetails
      })
      dispatch(fetchInterestedPersonsListSuccess(true))
    })
    .catch((error) => {
      dispatch(fetchInterestedPersonsListError(true))
    })
  }
}
export function fetchInterestedPersonsListError(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_INTERESTED_PERSONS_LIST_ERROR,
    payload: status
  }
}
export function fetchInterestedPersonsListSuccess(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_INTERESTED_PERSONS_LIST_SUCCESS,
    payload: status
  }
}

/* INTERESTED USER STATUS UPDATE */

export function submitGoInterestedPersonStatus(formData: any, id: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.INTERESTED_PERSONS_GO_STATUS_UPDATE

    return axios.post(`${url}/${id}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch(submitGoInterestedPersonStatusSuccess(true))
    })
    .catch((error) => {
      dispatch(submitGoInterestedPersonStatusError(true))
    })
  }
}
export function submitGoInterestedPersonStatusError(status: boolean) {
  return {
    type: GoAdsActionTypes.APPROVE_INTERESTED_PERSON_ERROR,
    payload: status
  }
}
export function submitGoInterestedPersonStatusSuccess(status: boolean) {
  return {
    type: GoAdsActionTypes.APPROVE_INTERESTED_PERSON_SUCCESS,
    payload: status
  }
}

/* FETCH APPROVED USERS ADS */

export function fetchApprovedPersonsAdsList() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.APPROVED_INTERESTED_PERSONS_GO_ADS_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: GoAdsActionTypes.FETCH_APPROVED_PERSONS_ADS_LIST,
        payload: response.data.interestdetails
      })
      dispatch(fetchApprovedPersonsAdsListSuccess(true))
    })
    .catch((error) => {
      dispatch(fetchApprovedPersonsAdsListError(true))
    })
  }
}
export function fetchApprovedPersonsAdsListError(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_APPROVED_PERSONS_ADS_LIST_ERROR,
    payload: status
  }
}
export function fetchApprovedPersonsAdsListSuccess(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_APPROVED_PERSONS_ADS_LIST_SUCCESS,
    payload: status
  }
}

/* FETCH APPROVED USERS (NO NEED FOR 'APPROVED-INTERESTED') */

export function fetchApprovedPersonsList(id: string) {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.APPROVED_INTERESTED_PERSONS_GO_LIST

    return axios.get(`${url}/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: GoAdsActionTypes.FETCH_APPROVED_PERSONS_LIST,
        payload: response.data.interestdetails
      })
      dispatch(fetchApprovedPersonsListSuccess(true))
    })
    .catch((error) => {
      dispatch(fetchApprovedPersonsListError(true))
    })
  }
}
export function fetchApprovedPersonsListError(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_APPROVED_PERSONS_LIST_ERROR,
    payload: status
  }
}
export function fetchApprovedPersonsListSuccess(status: boolean) {
  return {
    type: GoAdsActionTypes.FETCH_APPROVED_PERSONS_LIST_SUCCESS,
    payload: status
  }
}
