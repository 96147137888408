// @ts-nocheck
import { FC, useEffect }           from 'react'
import { Dispatch }                from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { KTCard }                  from '../../../../../_metronic/helpers'
import { PageTitle }               from '../../../../../_metronic/layout/core'
import { RootState }               from '../../../../store'
import { useAppDispatch }          from '../../../../store/hooks'
import {
  AdminSettingsActions,
  fetchCategoryTypes,
  fetchCategoryTypesError,
  fetchCategoryTypesSuccess }      from '../../../../store/actions/admin/settings.action'
import { showSnackbar }            from '../../../../helpers/SnackbarHelper'
import { ListHeader }              from './list/components/header/ListHeader'
import CategoryTypesTable          from './list/table/CategoryTypesTable'

const CategoryTypesConstants = {
  fetchCategoryTypesErrorMsg: 'Error in fetching category types. Please try again'
}

const CategoryTypes: FC<CategoryTypesProps> = ({
  categoryTypesFetchError,
  categoryTypesFetchSuccess,
  categoryTypeSubmitSuccess,
  fetchCategoryTypes,
  fetchCategoryTypesError,
  fetchCategoryTypesSuccess
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchCategoryTypes())
  }, [])

  useEffect(() => {
    if (categoryTypeSubmitSuccess) {
      dispatch(fetchCategoryTypes())
    }
  }, [categoryTypeSubmitSuccess])

  const closeFetchCategoryTypesErrorMsg = () => {
    dispatch(fetchCategoryTypesError(false))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Category Types</PageTitle>

      <KTCard>
        <ListHeader />
        <CategoryTypesTable />
      </KTCard>

      {
        categoryTypesFetchError &&
        showSnackbar(CategoryTypesConstants.fetchCategoryTypesErrorMsg, closeFetchCategoryTypesErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    categoryTypesFetchError: state.settingsReducer.categoryTypesFetchError,
    categoryTypesFetchSuccess: state.settingsReducer.categoryTypesFetchSuccess,
    categoryTypeSubmitSuccess: state.settingsReducer.categoryTypeSubmitSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AdminSettingsActions>) => {
  return {
    fetchCategoryTypes,
    fetchCategoryTypesError,
    fetchCategoryTypesSuccess
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type CategoryTypesProps = ConnectedProps<typeof connector>

export default connector(CategoryTypes)
