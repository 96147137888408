// @ts-nocheck
import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit'
import settingsReducer                         from './reducers/admin/settings.reducer'
import goAdsReducer                            from './reducers/go/ads.reducer'
import partnershipReducer                      from './reducers/go/partnership.reducer'
import partnershipTimeBasedReducer             from './reducers/go/partnershipTimeBased.reducer'
import kissanPostsReducer                      from './reducers/kissan/posts.reducer'
import interestReducer                         from './reducers/kissan/interest.reducer'
import dealReducer                             from './reducers/kissan/deals/deal.reducer'
import farmerReducer                           from './reducers/kissan/farmer.reducer'
import investorReducer                         from './reducers/kissan/investor.reducer'

export const store = configureStore({
  reducer: {
    settingsReducer             : settingsReducer,
    goAdsReducer                : goAdsReducer,
    partnershipReducer          : partnershipReducer,
    partnershipTimeBasedReducer : partnershipTimeBasedReducer,
    kissanPostsReducer          : kissanPostsReducer,
    interestReducer             : interestReducer,
    dealReducer                 : dealReducer,
    farmerReducer               : farmerReducer,
    investorReducer             : investorReducer
  }
})

export type RootState                 = ReturnType<typeof store.getState>
export type AppDispatch               = typeof store.dispatch
export type AppThunk<ReturnType=void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
