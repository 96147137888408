import axios           from 'axios'
import AppConfig       from '../../../../appConfig'
import { AppDispatch } from '../../../store'

// *************** TYPES

export interface Investor {
  name: string
  phone: string
  methodology: string
}

// *************** STATE

export interface InvestorState {
  investorsList: Investor[]
  investorsFetchError: boolean
  investorsFetchSuccess: boolean
}

// *************** ACTION TYPES

export const InvestorActionTypes = {
  INVESTORS_LIST         : 'INVESTORS_LIST',
  INVESTORS_LIST_ERROR   : 'INVESTORS_LIST_ERROR',
  INVESTORS_LIST_SUCCESS : 'INVESTORS_LIST_SUCCESS'
} as const

// *************** ACTIONS

interface FetchInvestorsListAction {
  type: typeof InvestorActionTypes.INVESTORS_LIST;
  payload: Investor[];
}

interface FetchInvestorsListSuccessAction {
  type: typeof InvestorActionTypes.INVESTORS_LIST_SUCCESS;
  payload: boolean;
}

interface FetchInvestorsListFailureAction {
  type: typeof InvestorActionTypes.INVESTORS_LIST_ERROR;
  payload: boolean;
}

export type InvestorActions =
  | FetchInvestorsListAction
  | FetchInvestorsListSuccessAction
  | FetchInvestorsListFailureAction


// *************** API CALLS
/* FETCH INVESTORS LIST */

export function fetchInvestorsList() {
  return (dispatch: AppDispatch) => {
    var url = AppConfig.API_BASE_URL + AppConfig.END_POINTS.KISSAN_FARMERS_LIST

    return axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aanshik_token')
      }
    })
    .then((response) => {
      dispatch({
        type: InvestorActionTypes.INVESTORS_LIST,
        payload: response.data.farmerdetails
      })
      dispatch(fetchInvestorsListSuccess(true))
    })
    .catch(error => {
      dispatch(fetchInvestorsListError(true))
    })
  }
}
export function fetchInvestorsListError(status: boolean) {
  return {
    type: InvestorActionTypes.INVESTORS_LIST_ERROR,
    payload: status
  }
}
export function fetchInvestorsListSuccess(status: boolean) {
  return {
    type: InvestorActionTypes.INVESTORS_LIST_SUCCESS,
    payload: status
  }
}
