// @ts-nocheck
import { Column }          from 'react-table'
import { Location }        from '../../core/_models'
import { ActionsCell }     from './ActionsCell'
import { CustomHeader }    from './CustomHeader'
import { CustomIndexCell } from './CustomIndexCell'
 
const locationColumns: ReadonlyArray<Column<Location>> = [{
  id       : 'row',
  Header   : (props)      => <CustomHeader tableProps={props} title='#' className='min-w-50px' />,
  Cell     : ({...props}) => <CustomIndexCell rowNo={props.row.index} />
}, {
  accessor : 'location',
  Header   : (props)      => <CustomHeader tableProps={props} title='Location' className='min-w-125px' />
}, {
  id       : 'actions',
  Header   : (props)      => <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />,
  Cell     : ({...props}) => <ActionsCell />
}]

export { locationColumns }
