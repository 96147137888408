import { FC, useEffect, useState } from 'react'
import { ChangeEvent, MouseEvent } from 'react'
import { Dispatch }                from 'react'
import { connect, ConnectedProps } from 'react-redux'
import debounce                    from 'lodash/debounce'
import orderBy                     from 'lodash/orderBy'
import { KTCard, KTCardBody }      from '../../../../_metronic/helpers'
import { useDebounce }             from '../../../../_metronic/helpers'
import { PageTitle }               from '../../../../_metronic/layout/core'
import { ListLoading }             from '../../../components/ListLoading'
import ListPagination              from '../../../components/ListPagination'
import { showSnackbar }            from '../../../helpers/SnackbarHelper'
import { RootState }               from '../../../store'
import { useAppDispatch }          from '../../../store/hooks'
import {
  Investor,
  InvestorActions,
  fetchInvestorsList,
  fetchInvestorsListError }        from '../../../store/actions/kissan/investor.actions'

const InvestorsConstants = {
  investorsListError: 'Error in fetching investors. Please try again'
}

const Investors: FC<InvestorsProps> = ({
  investorsList,
  investorsFetchError,
  fetchInvestorsList,
  fetchInvestorsListError
}) => {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading]         = useState<boolean>(false)
  const [columnToQuery, setColumnToQuery] = useState<string>('crop_name')
  const [searchTerm, setSearchTerm]       = useState<string>('')
  const [adsList, setAdsList]             = useState<Investor[]>([])
  const [page, setPage]                   = useState<number>(0)
  const [rowsPerPage, setRowsPerPage]     = useState<number>(5)

  useEffect(() => {
    dispatch(fetchInvestorsList())
    setAdsList(investorsList)
  }, [])

  useEffect(() => {
    if (searchTerm != '') {
      filterData()
    } else if (searchTerm == '') {
      setAdsList(investorsList)
    }
  }, [searchTerm, columnToQuery])

  const filterData = () => {
    var debouncedSearch = debounce(() => {
      let currentList = [...investorsList]
      let orderedList = orderBy(
        currentList.filter((x) =>
          x[columnToQuery as keyof Investor]
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ),
        columnToQuery, 'asc'
      )

      setAdsList(orderedList)
    }, 1000)

    debouncedSearch()
  }

  const populateInvestorsList = () => {
    return (
      rowsPerPage > 0
        ? adsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : adsList
    ).map((row, index) => (
      <tr role='row' key={index}>
        <td role='cell'>
          <div>{(page + 1 - 1) * rowsPerPage + (index + 1)}</div>
        </td>
        <td role='cell'>{row.name}</td>
        <td role='cell'>{row.methodology}</td>
        <td role='cell'>{row.methodology}</td>
        <td role='cell'>{row.phone}</td>
      </tr>
    ))
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const closeInvestorsListErrorMsg = () => {
    dispatch(fetchInvestorsListError(false))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Investors</PageTitle>

      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <select
              data-control='select2'
              data-hide-search='true'
              value={columnToQuery}
              onChange={(event) => setColumnToQuery(event.target.value)}
              className='form-select form-select-sm form-select-solid'>
              <option value="investor_name">Investor</option>
              <option value="crop_name">Crop</option>
              <option value="methodology">Methdology</option>
              <option value="investor_phone">Phone</option>
            </select>
            <div className='d-flex align-items-center position-relative my-1 ms-3'>
              <i className='bi bi-search position-absolute ms-3'></i>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid w-250px ps-10'
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)} />
            </div>
          </div>
        </div>

        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='aanshik-kissan-investors'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th role='columnheader' className='min-w-50px'>#</th>
                  <th role='columnheader' className='min-w-125px'>Investor</th>
                  <th role='columnheader'>Crop</th>
                  <th role='columnheader'>Methdology</th>
                  <th role='columnheader'>Phone</th>
                </tr>
              </thead>
              <tbody className='text-gray-600' role='rowgroup'>
              {
                adsList.length > 0 ? populateInvestorsList() : (
                  <tr>
                    <td colSpan={5}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )
              }
              </tbody>
              <tfoot>
                <tr>
                  <ListPagination
                    page={page}
                    count={investorsList.length}
                    colSpan={5}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} />
                </tr>
              </tfoot>
            </table>
          </div>

          {isLoading && <ListLoading />}
        </KTCardBody>
      </KTCard>

      {
        investorsFetchError &&
        showSnackbar(InvestorsConstants.investorsListError, closeInvestorsListErrorMsg)
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    investorsList: state.investorReducer.investorsList,
    investorsFetchError: state.investorReducer.investorsFetchError
  }
}

const mapDispatchToProps = (dispatch: Dispatch<InvestorActions>) => {
  return {
    fetchInvestorsList,
    fetchInvestorsListError
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type InvestorsProps = ConnectedProps<typeof connector>

export default connector(Investors)
