import { Reducer }  from 'react'
import {
  InvestorActions,
  InvestorActionTypes,
  InvestorState }   from '../../actions/kissan/investor.actions'

const initialState: InvestorState = {
  investorsList: [],
  investorsFetchError: false,
  investorsFetchSuccess: false
}

const investorReducer: Reducer<InvestorState, InvestorActions> = (state = initialState, action) => {
  switch (action.type) {
    case InvestorActionTypes.INVESTORS_LIST: {
      return {
        ...state,
        investorsList: action.payload
      }
    }
    case InvestorActionTypes.INVESTORS_LIST_ERROR: {
      return {
        ...state,
        investorsFetchError: action.payload
      }
    }
    case InvestorActionTypes.INVESTORS_LIST_SUCCESS: {
      return {
        ...state,
        investorsFetchSuccess: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default investorReducer
