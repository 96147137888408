import { useState }     from 'react'
import AddLocationModal from '../../../modal/AddLocationModal'

const ListToolbar = () => {
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false)

  const toggleLocationModal = () => {
    setShowLocationModal(!showLocationModal)
  }

  return (
    <>
      <div className='d-flex justify-content-end'>
        <button type='button' className='btn btn-sm btn-primary' onClick={toggleLocationModal}>
          <i className="bi bi-plus-circle-fill"></i> New Location
        </button>
      </div>

      <AddLocationModal
        showLocationModal={showLocationModal}
        toggleLocationModal={toggleLocationModal} />
    </>
  )
}

export { ListToolbar }
